@import 'src/assets/css/common/variables';
@import 'src/assets/css/common/mixins';

.addons-page {
    background-color: #f2f2f2;
    &::before {
        content: '';
        position: fixed;
        background: url(#{$cdnPathImages}bg_addons_4.webp) top center / cover no-repeat;
        width: 100%;
        height: 50%;
        left: 0;
        bottom: 0;
        opacity: 0.4;
    }
    &:not(.active) {
        .container-chatbot,
        .container-user-list,
        .addons-wrap__documents,
        .addons-wrap__preset {
            padding-left: 16px;
        }
        .addons-chatbot::before {
            left: 16px;
        }
    }
    .header-v2 {
        z-index: 10001;
    }
    .addons-wrap {
        display: flex;
        overflow-y: hidden !important;
        position: relative;
        &:has(.modal-tutorials) {
            z-index: 1;
        }
        &:has(.modal:not(.modal-tutorials)) {
            z-index: unset;
        }
        &__container {
            width: 100%;
            @include flexcolumns;
            @include font-color(20px, 14px, $black-2);
            position: relative;
            .btn-tutorials {
                position: absolute;
                right: 16px;
                top: 16px;
            }
            .boxs {
                &.active:not(.boxs--no-border):not(.--material):not(.box-plans) {
                    border-color: $orange-default;
                }
                &__header {
                    .icon {
                        @include flexcenter;
                        flex: 1;
                    }
                }
                .switch {
                    .toggle {
                        display: none;
                    }
                }
            }
            .scrolls {
                &:not(.v2-dropdown__menu):not(.body-modal):not(.step) {
                    overflow-y: overlay;
                }
                &.--overlay {
                    z-index: 1;
                }
            }
            .scrolls.--sms {
                z-index: 1;
            }
            .boxs-wrapper,
            .header {
                width: 560px;
                margin: 0 auto;
            }
            .header {
                margin: 16px auto;
            }
            .scrolls > .alert,
            > .alert {
                max-width: 560px;
            }
            // Branch plan setting
            &.branch-plan {
                .header,
                .boxs-wrapper {
                    width: 900px;
                }
                .page-plans {
                    padding: 0;
                    margin: 0;
                    width: 100%;
                    .boxs {
                        .status-btn {
                            font-size: 14px;
                            height: 24px;
                            padding: 0 6px;
                        }
                    }
                    .box-plans {
                        padding: 0;
                        height: unset;
                        &.active {
                            pointer-events: unset;
                        }
                    }
                }
            }
            &:has(.--pipeline):not(:has(.--routing)):not(:has(.--smart-view)) {
                .boxs-wrapper,
                .header {
                    width: 900px;
                }
            }
            &:has(.box-voip) {
                .header {
                    .v2-btn-default.--grey {
                        border-color: $grey-light-3 !important;
                    }
                }
            }
            &:has(.--clock) {
                .boxs-wrapper,
                .header {
                    width: 800px;
                }
            }
            &:has(.--quickbooks) {
                .boxs-wrapper,
                .header {
                    width: 590px;
                }
            }
            &:has(.--custom-field):not(:has(.--routing)):not(:has(.--smart-view)) {
                .boxs-wrapper,
                .description,
                .header {
                    width: 660px;
                }
                .description {
                    margin: 0 auto 16px;
                }
            }
        }
        .material-content {
            width: 560px;
            margin: 0px auto;
            .boxs--no-border {
                .description {
                    margin-bottom: 16px;
                }
                .check-items {
                    margin-top: 12px;
                    .item-checkbox {
                        & > label {
                            @include font-color(20px, 13px, $black-3);
                        }
                        .txt-ellipsis {
                            flex: 1;
                            display: block;
                        }
                    }
                }
            }
        }
        .material-content-full {
            @include flextop;
            justify-content: flex-start;
            flex-wrap: wrap;
            max-width: 1156px;
            width: calc(100% - 32px);
            column-gap: 12px;
            row-gap: 6px;
            .--material {
                padding: 0;
                width: 280px;
                @include border($border-color-grey, 3px, 'null');
                &:not(.active) {
                    .boxs__header .title,
                    .boxs__header .v2-btn-default,
                    .boxs__contents {
                        opacity: 0.5;
                        pointer-events: none;
                    }
                }
                .boxs__header {
                    min-height: 64px;
                    padding: 0 24px;
                    .title {
                        @include text-ellipsis;
                        font-size: 18px;
                    }
                    .switch {
                        margin-left: 5px;
                    }
                    .header-action {
                        @include betweenitems;
                        width: 100%;
                        &.--edit {
                            padding: 12px 0;
                            flex-direction: column;
                            align-items: flex-end;
                        }
                        .btn-action {
                            margin-top: 4px;
                        }
                    }
                }
                .boxs__contents {
                    margin: 0;
                    position: relative;
                    background-color: $background-circle;
                    border-radius: 0px 0px 3px 3px;
                    box-shadow: 0 -1px 0 $border-color-grey;
                    .rows {
                        position: relative;
                        padding: 6px 8px 2px;
                        @include betweentop;
                        flex-wrap: wrap;
                        background-color: $white;
                        cursor: pointer;
                        &:not(.btn-add-material) {
                            border-bottom: 1px solid $border-color-grey;
                        }
                        &.is-edit {
                            .material-edit {
                                display: block;
                            }
                            .material-value {
                                display: none;
                            }
                        }
                        .material-edit {
                            display: none;
                            padding: 2px 0px 4px;
                            .field-input + .field-input {
                                margin-top: 3px;
                            }
                        }
                        .col-title {
                            @include text-ellipsis;
                            line-height: 32px;
                            & + .col-title {
                                margin-top: -10px;
                            }
                        }
                        &__action {
                            flex: 1;
                            width: calc(100% - 55px);
                            @include flexcenter;
                            padding-left: 16px;
                            padding-right: 5px;
                            margin-top: -2px;
                        }
                        .drag {
                            cursor: grabbing;
                            position: absolute;
                            top: 8px;
                            left: 0px;
                        }
                        .--delete {
                            opacity: 0.5;
                            &:hover {
                                opacity: 1;
                            }
                        }
                        .col-icon {
                            visibility: hidden;
                            .loading {
                                position: relative;
                                top: -6px;
                            }
                            > .v2-btn-default + .v2-btn-default {
                                margin-left: 2px;
                            }
                        }
                        &:hover {
                            .col-icon {
                                visibility: visible;
                            }
                        }
                        &.btn-add-material {
                            padding: 8px 24px;
                            .v2-btn-default {
                                width: 100%;
                            }
                        }
                    }
                    .boxs-material {
                        width: 100%;
                        max-width: calc(100% - 22px);
                        padding: 6px;
                        position: absolute;
                        bottom: 50px;
                        left: 50%;
                        transform: translateX(-50%);
                        z-index: 1;
                        background-color: $white;
                        @include border($border-color-grey, 8px, 'null');
                        box-shadow: $boxshadow-dark-gray;
                        &:not(.open) {
                            display: none;
                        }
                        &__body {
                            .field-input {
                                margin-bottom: 4px;
                            }
                        }
                        &__footer {
                            text-align: right;
                            .cancel {
                                color: $grey-greenish;
                                margin-right: 8px;
                            }
                        }
                    }
                }
            }
        }
        .tabs-dashboard {
            @include flexcolumns;
            height: 100%;
            &.is-grid-view {
                .tab-content-active {
                    display: inline-grid;
                    grid-template-columns: auto auto auto;
                    grid-gap: 16px;
                }
                .boxs {
                    width: 296px;
                    padding: 16px;
                    border: 1px solid $grey-verylight;
                    border-radius: 4px;
                    @include flexcolumns;
                    &.is-active {
                        border-color: $green-default;
                    }
                    &__header {
                        flex-direction: row-reverse;
                    }
                    &__contents {
                        margin: 12px 0px;
                    }
                    &__last-items {
                        &.has-pro-btn {
                            justify-content: flex-end;
                        }
                    }
                    .switch {
                        position: relative;
                        &__label {
                            position: absolute;
                            right: 100%;
                        }
                    }
                }
            }
            &:not(.is-grid-view) {
                .wrapper-addons {
                    &__content {
                        border: 1px solid $grey-verylight;
                        border-radius: 8px;
                        overflow-x: hidden;
                    }
                }
                .boxs {
                    padding: 24px;
                    border: none;
                    @include flextop;
                    & + .boxs {
                        box-shadow: inset 0px 1px 0px $border-color-grey;
                        border-radius: 0px;
                    }
                    &:first-child {
                        .tooltiptext {
                            top: calc(100% + 4px);
                            bottom: auto;
                        }
                    }
                    &__header {
                        width: 160px;
                        .icon {
                            justify-content: flex-end;
                        }
                    }
                    &__contents {
                        margin: 0px 60px 0px 8px;
                    }
                    &__last-items {
                        height: 32px;
                        width: 25%;
                        min-width: 220px;
                        &:not(.has-pro-btn) {
                            flex-direction: row-reverse;
                        }
                    }
                    .switch__label {
                        margin-right: 0px;
                        margin-left: 6px;
                    }
                    .check-radio {
                        > label {
                            padding-right: 25px;
                            padding-left: 8px;
                            &::before {
                                left: auto;
                                right: 0px;
                            }
                        }
                        input[type='radio']:checked + label::after {
                            right: 4px;
                            left: auto;
                        }
                    }
                    .title {
                        line-height: 32px;
                    }
                    .title,
                    .description {
                        color: $granite-gray;
                    }
                }
            }
            .wrap-empty {
                .tables-empty {
                    max-width: calc(920px - 48px);
                    width: calc(920px - 48px);
                }
            }
            .boxs {
                &.is-active {
                    background: light-dark(#f2fcf5, #0b311d);
                    .switch__label,
                    .title {
                        color: $green-default;
                    }
                    .icon-inactive,
                    .is-label-off {
                        display: none;
                    }
                    .switch input.toggle-round:checked + label:before {
                        background-color: $green-default;
                    }
                }
                &:not(.is-active) {
                    .icon-active,
                    .is-label-on {
                        display: none;
                    }
                    .icon {
                        &:not(.not-filter) {
                            svg path:first-child {
                                fill: $transparent;
                            }
                        }
                    }
                }
                &__contents {
                    flex: 1;
                }
                &__last-items {
                    @include flexcenter;
                    .tooltip {
                        display: flex;
                        @include size(16px);
                    }
                }
                .icon {
                    &__main {
                        @include size(32px);
                        @include centeritem;
                        border-radius: 4px;
                    }
                    .icon-active {
                        background: $white-default;
                        box-shadow: 0px 2px 5px $black-verydark, 0px 2px 2px $black-lighter,
                            0px 0px 1px $black-extradark;
                    }
                    .icon-inactive {
                        background: $bg-white-flash;
                    }
                }
                .title {
                    @include font-color(23px, 15px, $black-eerie);
                }
                .description {
                    @include font-color(20px, 13px, #808080);
                    word-break: break-word;
                    margin-top: 2px;
                }
                .check-radio {
                    > label {
                        @include font-color(15px, 11px, $purple-default);
                        margin: 0px;
                        &::before {
                            top: -1px;
                        }
                    }
                    input[type='radio']:checked {
                        & + label {
                            &::before {
                                background: $purple-default;
                            }
                            &::after {
                                content: '';
                                width: 8px;
                                height: 4px;
                                top: 4px;
                                border-radius: 0px;
                                border: 2px solid $white-default;
                                border-top: none;
                                border-right: none;
                                background: $transparent;
                                @include transform(rotate(-45deg));
                            }
                        }
                    }
                }
            }
            .tabs-heading {
                @include betweenitems;
                .v2-dropdown {
                    margin: 0px 8px;
                    min-width: 185px;
                    .dropbtn {
                        &.has-icon {
                            color: $white-default;
                            .arrow svg path {
                                stroke: $white-default;
                            }
                        }
                        &.--other {
                            background: $grey-platinum;
                            border-color: $grey-platinum;
                        }
                        > svg {
                            @include size(16px);
                        }
                    }
                    &.active {
                        .arrow {
                            margin-top: 3px;
                        }
                    }
                }
            }
            .search-input {
                width: 230px;
            }
            .wrapper-addons {
                padding-bottom: 30px;
            }
            .alert,
            .tabs-heading,
            .tab-contents {
                width: 920px;
                margin: 0 auto;
            }
            .alert {
                margin-bottom: 20px;
            }
            .status-btn:not(.--beta) {
                height: 20px;
                padding-right: 6px;
            }
            .growth-plan {
                background-color: $green-default;
                border-color: $green-default;
            }
            .pro-plan {
                background-color: $orange-default !important;
                border-color: $orange-default;
            }
            @media (max-width: 980px) {
                .alert,
                .tabs-heading,
                .tab-contents {
                    width: 100%;
                    padding: 0px 20px 20px;
                }
                .tabs-heading {
                    padding-bottom: 0px;
                }
                .scrolls {
                    overflow: auto !important;
                }
            }
        }
        .tabs-sync {
            .tab-contents {
                .screen-sync {
                    height: 258px;
                    background-color: $black-gunmetal;
                    margin-bottom: 24px;
                    border-radius: 4px;
                    padding: 12px;
                    overflow-y: auto;
                    &__history {
                        color: $white;
                        margin-bottom: 9px;
                    }
                }
                .rows {
                    display: inline-block;
                    margin-bottom: 12px;
                    &__user {
                        text-transform: capitalize;
                    }
                    & > * {
                        margin-right: 5px;
                        display: inline;
                    }
                    .status-btn {
                        display: inline-block;
                        min-height: 18px;
                        line-height: 18px;
                    }
                }
            }
        }
        .page-heading {
            color: $granite-gray;
            &.is-active {
                *:not(.status-btn):not(.v2-btn-default):not(.v2-btn) {
                    color: $green-default;
                }
                .switch {
                    input.toggle-round:checked + label:before {
                        background-color: $green-default;
                    }
                    &__label {
                        padding-right: 2px;
                    }
                }
                .header-title__svg {
                    background: $white-default;
                    box-shadow: 0px 2px 5px $black-verydark, 0px 2px 2px $black-lighter, 0px 0px 1px $black-extradark;
                }
            }
            .header-title {
                height: 40px;
                &__svg {
                    @include size(40px);
                    @include centeritem;
                    background: $white-default;
                    border-radius: 4px;
                    margin-right: 8px;
                    margin-left: 1px;
                }
            }
            .switch__label {
                min-width: 23px;
                text-align: right;
                margin-right: 6px;
            }
        }
        &:not(:has(.container-chatbot)) {
            .status-btn {
                border-radius: 4px;
                font-size: 11px;
                padding: 0px 4px 0px 2px;
                height: 16px;
                @include flexinline;
                font-weight: 500;
                svg {
                    @include size(16px);
                    margin-right: 2px;
                }
                &.--pro {
                    width: fit-content;
                }
                &.--beta {
                    padding: 0 6px;
                }
            }
        }
        &:has(.container-chatbot):has(.modal.open) {
            z-index: 100001;
        }
        .boxs {
            @include border($grey-soft, 4px, 'null');
            padding: 24px 16px 16px;
            height: 100%;
            &:not(.boxs--no-border) {
                background: $white;
            }
            &--no-border {
                border-color: $transparent;
                padding: 0;
                .customize-ml {
                    margin-left: -8px;
                }
            }
            &--power-up {
                padding: 16px;
            }
            &__header {
                @include betweenitems;
                .powerups {
                    @include flexcenter;
                    @include font-color(1.54, 13px, $orange-default);
                    font-weight: 500;
                    white-space: nowrap;
                    svg {
                        margin-right: 2px;
                    }
                }
                .dots::before {
                    margin-top: 3px;
                }
                .header-title {
                    flex: 1;
                    justify-content: flex-start;
                    overflow: hidden;
                    height: auto;
                    padding: 2px 0;
                    .title {
                        @include text-ellipsis;
                    }
                }
                &:has(.header-title:empty) {
                    padding: 0 !important;
                }
                .switch:not(:has(label)) {
                    .switch__label {
                        margin-right: 0;
                    }
                }
            }
            &__contents {
                margin: 6px 0px 4px;
                .required {
                    color: $black-2;
                    margin-bottom: 5px;
                    display: block;
                }
                .tax-desc {
                    padding-top: 12px;
                }
                &:has(> .switch) {
                    @include betweentop;
                    .description {
                        width: calc(100% - 105px);
                    }
                }
                &:has(.description:empty) {
                    padding: 0 !important;
                }
            }
            &__footer {
                @include flexend;
                background-color: $bg-body;
                border-top: 1px solid $border-color-grey;
                height: 50px;
                border-radius: 0px 0px 4px 4px;
                padding: 0px 16px;
                &.--multi {
                    justify-content: space-between;
                }
                &.--has-code {
                    flex-direction: column;
                    height: unset;
                    padding: 12px 16px;
                    &.active {
                        .show-code {
                            display: block;
                        }
                    }
                }
            }
            .title {
                @extend .fw-600;
                line-height: 24px;
            }
            .email-description {
                padding: 7px 12px 9px;
                background-color: $background-grey;
                font-size: 13px;
                border-radius: 4px;
            }
            &-has-footer {
                padding: 0;
                .boxs__header {
                    padding: 16px 16px 0px;
                }
                .boxs__contents {
                    padding: 6px 16px 18px;
                    margin: 0px;
                }
            }
            &--booking {
                padding: 0;
                &:not(:last-child) {
                    margin-bottom: 6px;
                }
                .boxs__header {
                    height: 52px;
                    @include flexcenter;
                    padding: 0 16px;
                    margin-bottom: 8px;
                    &.border-bottom-border-color-grey {
                        margin-bottom: 0;
                    }
                }
                .boxs__contents {
                    margin-top: 0;
                    padding: 0 16px 20px;
                    .rows {
                        @include betweentop;
                        flex-wrap: wrap;
                        &:not(:last-child) {
                            margin-bottom: 20px;
                        }
                        &__name {
                            max-width: 400px;
                            @include text-ellipsis;
                        }
                    }
                    &.--service {
                        padding: 0;
                        .rows {
                            margin-bottom: 0;
                            padding: 20px 16px;
                            align-items: center;
                            &:not(:last-child) {
                                border-bottom: 1px solid $border-color-grey;
                            }
                            &:last-child:not(:first-child) {
                                > .v2-dropdown {
                                    .v2-dropdown__menu {
                                        top: auto;
                                        bottom: calc(100% + 5px);
                                    }
                                }
                            }
                            &__title {
                                @include text-ellipsis;
                                @include flexcenter;
                                width: 200px;
                                margin-right: 5px;
                                margin-left: -6px;
                                @extend .fw-600;
                                & > svg {
                                    margin-right: 4px;
                                    cursor: grab;
                                    &:active {
                                        cursor: grabbing;
                                    }
                                }
                                .txt-ellipsis {
                                    max-width: calc(100% - 28px);
                                }
                            }
                            &__multi {
                                flex: 1;
                                @include flexwrap;
                                @include text-ellipsis;
                                .price {
                                    width: 95px;
                                    margin-right: 10px;
                                }
                                .price,
                                .hour {
                                    @include text-ellipsis;
                                }
                            }
                            .description,
                            &__user {
                                width: 100%;
                            }
                            .description {
                                margin-top: 10px;
                                @include text-line-clamp(2);
                            }
                            &__user {
                                @include flexcenter;
                                margin-top: 13px;
                                .avt-img,
                                .avt {
                                    margin-right: 6px;
                                }
                            }
                        }
                    }
                    .--booking-menu {
                        margin-left: 20px;
                        .dropbtn {
                            border-radius: 8px;
                        }
                        .v2-dropdown__menu {
                            min-width: 200px;
                            width: max-content;
                            right: 0;
                            .btn-modal {
                                @include flexcenter;
                            }
                            .items {
                                .txt-ellipsis {
                                    color: $black-3;
                                    font-weight: 500;
                                }
                            }
                        }
                    }
                }
            }
            &.--mr-line {
                &::after {
                    padding-top: 17px;
                    margin-bottom: 16px;
                }
            }
            .link-portal {
                @include flexcenter;
                max-width: max-content;
                &:hover {
                    text-decoration: underline;
                }
                &.--md {
                    max-width: 70%;
                }
                .txt-ellipsis {
                    flex: 1;
                }
            }
            .link-edit {
                @include flexwrap;
                .link-portal,
                .field-input {
                    margin-right: 5px;
                    max-width: 100%;
                }
                .field-input {
                    width: 145px;
                    line-height: 32px;
                }
            }
            &.--routing {
                height: unset;
                .boxs__header {
                    padding-bottom: 16px;
                }
                .boxs__contents {
                    padding-top: 16px;
                    border-top: 1px solid $border-color-grey;
                    .v2-dropdown__menu {
                        z-index: 103;
                    }
                    .txt-content {
                        max-width: 195px;
                        @include text-ellipsis;
                    }
                    .switch__label {
                        font-size: 13px;
                    }
                    .wrapper-choosetime {
                        width: 200px;
                    }
                }
                & + .wrap-tables {
                    height: unset;
                    .tables-list {
                        .rows:last-child {
                            box-shadow: none;
                        }
                    }
                }
            }
            &.--setting-portal {
                box-shadow: 0px 0px 1px 0px $black-extradark, 0px 0px 2px 0px $black-light,
                    0px 0px 3px 0px $black-verydark;
            }
        }
        .boxs-wrapper {
            padding-bottom: 30px;
            .boxs {
                &:not(.boxs--no-border) {
                    margin-bottom: 6px;
                }
                &--api {
                    @include flexcolumns;
                    padding: 0;
                    border-radius: 8px;
                    border-color: $border-color-grey;
                    box-shadow: 0 0 3px $black-verydark, 0 0 2px $black-light, 0 0 1px $black-extradark;
                    .rows {
                        @include betweentop;
                        flex-wrap: wrap;
                        padding: 16px;
                        row-gap: 8px;
                        & + .rows {
                            border-top: 1px solid $border-color-grey;
                        }
                        .col-desc {
                            width: calc(100% - 85px);
                            color: $black-jungle-green;
                            margin-right: 10px;
                        }
                        .col-apikey {
                            color: $black-darklight;
                            @include centeritem;
                            svg {
                                margin-right: 2px;
                            }
                        }
                        .v2-dropdown__menu {
                            min-width: 130px;
                            width: max-content;
                        }
                        .v2-btn-default {
                            &.is-copy:hover {
                                svg path {
                                    stroke: $white-default;
                                }
                            }
                        }
                    }
                }
            }
            &--sms {
                .wrap-content {
                    @include flexwrap;
                    column-gap: 8px;
                    .boxs {
                        @include flexcolumns;
                        flex: 1;
                        height: auto;
                        padding: 12px 10px;
                        .info {
                            flex: 1;
                            word-break: break-word;
                            padding: 14px 0px 16px;
                            &__price {
                                font-size: 17px;
                                @extend .fw-600;
                            }
                            &__sm {
                                display: block;
                                margin-top: 3px;
                                width: 100%;
                            }
                        }
                    }
                    &::after {
                        width: 100%;
                    }
                }
            }
            &.--email {
                .alert {
                    max-width: 100%;
                    &__description {
                        padding: 0px;
                    }
                }
                .boxs-has-footer {
                    .boxs__contents {
                        padding: 0px;
                        margin-bottom: 16px;
                    }
                    .email-title {
                        display: block;
                        padding: 0 16px;
                        margin-top: 3px;
                    }
                    .address {
                        min-height: 56px;
                        @include flexcenter;
                        padding: 0 16px;
                        border-top: 1px solid $border-color-grey;
                        &__content {
                            width: 415px;
                            .title {
                                font-size: 15px;
                            }
                            .email,
                            .title {
                                @include text-ellipsis;
                            }
                        }
                    }
                    .email {
                        color: $grey-extrlight;
                    }
                    .rows {
                        padding: 16px 16px 0px;
                        &__content {
                            flex: 1;
                            .title-action {
                                @include betweenitems;
                            }
                            .title {
                                @include text-ellipsis;
                                max-width: 230px;
                            }
                        }
                        &:not(:last-child) {
                            border-top: 1px solid $border-color-grey;
                        }
                        .status-btn {
                            border-radius: 10px;
                            height: auto;
                            svg {
                                @include size(24px);
                                path {
                                    stroke: $white-default;
                                }
                            }
                            &.connected {
                                background-color: $green-default;
                            }
                            &.disabled,
                            &.progress {
                                background-color: $red-default;
                            }
                        }
                    }
                    &.active {
                        margin-top: 24px;
                    }
                }
                .btn-action {
                    margin-top: 16px;
                }
            }
            &.--document {
                .v2-btn-main {
                    font-weight: 500;
                    margin: 18px 0px;
                }
            }
            &.--quickbooks {
                .boxs-has-footer {
                    .--hasline::after {
                        margin-bottom: 12px;
                    }
                }
                .check-radio {
                    &__label {
                        @include font-color(20px, 13px, $black-3);
                    }
                }
                .wrap-tooltip {
                    margin-top: 3px;
                    margin-left: 24px;
                    @include flexinline;
                    cursor: pointer;
                    .tooltip {
                        margin-left: 5px;
                    }
                }
                .--header-qbid {
                    flex-wrap: wrap;
                }
                .alert {
                    max-width: 100%;
                }
            }
            .desc-connect {
                margin-top: 17px;
                @include flexcenter;
            }
            // Pipeline Permissions
            &.--pipeline {
                .tag-label {
                    color: $white-default;
                    line-height: 20px;
                }
                .wrap-tables {
                    .rows {
                        .col {
                            &.col-role {
                                max-width: 125px;
                            }
                            &.col-sm {
                                min-width: 135px !important;
                            }
                            @media (max-width: 1180px) {
                                &.col-lg {
                                    min-width: 150px !important;
                                }
                                &.col-role {
                                    min-width: 140px !important;
                                }
                            }
                        }
                    }
                    .tables {
                        .tables-list {
                            .rows {
                                align-items: center;
                                .col {
                                    &-customer {
                                        .avt-img img,
                                        .avt-img,
                                        .avt {
                                            @include size(32px);
                                        }
                                        .avt {
                                            font-size: 12px;
                                        }
                                    }
                                    .switch.active {
                                        .switch__label {
                                            color: $purple-default;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            // Notification, Clock In/Out
            &.--notification,
            &.--clock {
                .wrap-tables {
                    height: unset;
                    border-radius: 4px !important;
                    .tables {
                        height: 100%;
                        .rows {
                            align-items: center;
                            &.--header {
                                box-shadow: 0px 0px 1px 0px $black-extradark, 0px 1px 2px 0px $black-light,
                                    0px 1px 3px 0px $black-verydark;
                            }
                            .col {
                                &-customer {
                                    .avt-img img,
                                    .avt-img,
                                    .avt {
                                        @include size(32px);
                                    }
                                    .avt {
                                        font-size: 12px;
                                    }
                                }
                                .switch__label {
                                    min-width: 20px;
                                    font-weight: 500;
                                }
                                @media (max-width: 1180px) {
                                    &.col-sm-plus {
                                        max-width: 140px !important;
                                    }
                                    &.col-xl {
                                        max-width: unset !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.--clock {
                .wrap-tables {
                    .tables-list {
                        .rows {
                            height: 56px;
                        }
                        .col {
                            padding: 12px 0;
                            .switch__label {
                                margin: 0 0 0 6px;
                            }
                            &.col-rate {
                                .cost-input {
                                    flex: 1;
                                    .field-input {
                                        padding: 0 54px 0 36px !important;
                                        &::placeholder {
                                            color: $black-dark-jungle1;
                                        }
                                    }
                                    &::before {
                                        content: '/hour';
                                        width: 48px;
                                    }
                                    &::after,
                                    &::before {
                                        background-color: $white-light-cultured;
                                        border-color: $grey-light-3;
                                        font-size: 14px;
                                        color: $black-jungle-green;
                                    }
                                }
                            }
                            .switch.active {
                                .switch__label {
                                    color: $purple-default;
                                }
                            }
                        }
                    }
                    .rows {
                        &.--header {
                            overflow: unset;
                            .tooltiptext {
                                max-width: 300px;
                            }
                        }
                        .col {
                            &:first-child {
                                padding-left: 16px;
                            }
                            &.col-rate {
                                min-width: 200px !important;
                                max-width: 200px !important;
                                padding-right: 16px;
                            }
                            @media (max-width: 1180px) {
                                & {
                                    max-width: unset !important;
                                }
                                &.col-lg {
                                    min-width: 135px !important;
                                }
                                &.col-md {
                                    max-width: 140px !important;
                                }
                            }
                        }
                    }
                }
            }
            // Custom field
            &.--custom-field {
                .wrap-tables {
                    @media (max-width: 1180px) {
                        .col:not(.col-lg):not(.col-xl) {
                            max-width: 100px !important;
                        }
                    }
                }
            }
            &:has(.--routing) {
                min-height: 650px;
            }
            &.--smart-view {
                .wrap-tables {
                    height: auto;
                    border-radius: 8px !important;
                    .rows:last-child {
                        box-shadow: none;
                    }
                }
            }
        }
        // Material Preset
        .--material-preset {
            .header,
            .boxs-wrapper {
                width: 650px;
            }
            .preset-list {
                border-radius: 4px;
                background-color: $white;
                border: 1px solid $border-color-grey;
            }
            .preset-items {
                padding: 16px;
                &:not(:last-child) {
                    border-bottom: 1px solid $border-color-grey;
                }
                .tag-label {
                    min-width: unset;
                    color: $black-dark-charcoal;
                    pointer-events: none;
                    border-radius: 4px;
                }
                .col {
                    min-width: 80px;
                    max-width: 160px;
                    &:nth-last-child(2) {
                        text-align: right;
                    }
                }
                .col-material {
                    min-width: 20%;
                }
            }
        }
        &__preset {
            min-width: 600px;
            width: fit-content;
            max-width: 100%;
            padding-right: 16px;
            margin: 0 auto;
            &:not(:has(.preset-unit)) {
                &:not(:has(.preset-column:nth-of-type(3))),
                &:not(:has(.preset-container > .preset-column)) {
                    max-width: 600px;
                }
            }
            .alert,
            .header,
            .boxs-wrapper {
                width: 100%;
            }
            .alert {
                max-width: 100%;
            }
            .boxs-wrapper {
                padding-bottom: 16px;
                &__preset {
                    border-radius: 4px;
                    background-color: $white;
                    border: 1px solid $border-color-grey;
                }
            }
            .preset {
                &-header,
                &-footer {
                    padding: 16px;
                }
                &-container {
                    border-top: 1px solid $border-color-grey;
                    border-bottom: 1px solid $border-color-grey;
                    height: calc(100% - 152px);
                    overflow-x: auto;
                    &:has(.preset-unit + .preset-column),
                    &:has(.preset-column + .preset-unit),
                    &:has(.preset-unit + .preset-unit),
                    &:has(> *:nth-of-type(3)) {
                        .preset-column {
                            width: 200px;
                        }
                    }
                    & > *:not(:first-child) {
                        border-left: 1px solid $border-color-grey;
                    }
                }
                &-column {
                    min-width: 200px;
                    &__header {
                        padding: 16px 16px 0;
                        &:not(:has(.v2-btn-default)) {
                            padding: 22px 16px 6px;
                        }
                    }
                    &__content {
                        overflow-y: auto;
                        padding: 5px 16px 16px;
                        .txt-checkbox {
                            transform: translateY(-1px);
                        }
                    }
                    .unit-items:has(.field-input) {
                        @include border($grey-soft, 4px, 'null');
                        &:has(.field-input:focus) {
                            box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.04),
                                0 2px 3px 0 rgba(0, 0, 0, 0.03), 0 4px 6px 0 rgba(0, 0, 0, 0.05),
                                0 8px 8px 0 rgba(0, 0, 0, 0.03), 0 0 0 2px $box-shadow-color-message;
                            border-color: $border-color-message !important;
                        }
                        .field-input {
                            width: calc(100% - 55px);
                            padding-left: 5px;
                        }
                    }
                    .unit-btn {
                        position: absolute;
                        right: 4px;
                        top: 50%;
                        @include transform(translateY(-50%));
                    }
                }
                &-unit {
                    .preset-column {
                        max-width: 300px;
                    }
                }
            }
        }
        .email-sign-form {
            .txt {
                @include font-color(1.54, 13px, $black-3);
                font-weight: 500;
                margin-bottom: 3px;
                display: inline-block;
            }
            .form-save {
                text-align: right;
                margin-top: 16px;
            }
        }
        .--hasline {
            &::after {
                content: '';
                display: block;
                border-bottom: 1px solid $border-color-grey;
                padding-top: 24px;
                margin-bottom: 24px;
            }
            &.--line-sm::after {
                padding-top: 16px;
                margin-bottom: 16px;
            }
        }
        .square-api-code {
            max-width: 240px;
            &.disable {
                background-color: $bg-body;
                .dropbtn {
                    &.items {
                        color: $grey-extrlight;
                    }
                }
            }
            .dropbtn {
                &.items {
                    color: $black;
                }
            }
        }
        .tabs {
            .btn-item {
                margin-left: 0;
            }
        }
        .form-zaipier {
            .password-input {
                position: relative;
                flex: 1;
                max-width: 323px;
            }
            .field-input {
                padding-right: 32px;
                color: $black-2;
            }
            .svg-search-absolute {
                left: inherit;
                right: 5px;
            }
        }
        &__documents {
            min-width: 100%;
            @include size(100%);
            @include flexcolumns;
            align-items: center;
            &:has(.--adddocs) {
                padding-right: 16px;
            }
            .header {
                @include betweenitems;
                padding-top: 12px;
            }
            .list {
                width: 100%;
                flex: 1;
                overflow-y: auto;
                &:not(.--adddocs) {
                    .list__document {
                        width: 560px;
                        margin: 10px auto 20px;
                        padding-top: 4px;
                        border: 1px solid $grey-soft;
                        border-radius: 3px;
                    }
                    .rows:last-child {
                        border-bottom: none;
                    }
                }
                &__document {
                    &-upload {
                        padding: 16px;
                        border-bottom: 1px solid $border-color-grey;
                    }
                    .search-input {
                        border-bottom: 1px solid $border-color-grey;
                        .svg-search-absolute {
                            left: 12px;
                        }
                        .no-effect {
                            border: 0;
                            height: 40px;
                            padding: 3px 16px 3px 40px;
                        }
                    }
                }
                &__preview {
                    width: calc(100% - 400px);
                    @media (max-width: 767px) {
                        .page-document {
                            min-width: 730px;
                        }
                    }
                    &:has(.page-document) {
                        padding: 25px;
                    }
                }
                &.--adddocs {
                    @include betweentop;
                    background: $white;
                    border-radius: 4px 4px 0 0;
                    border: 1px solid $border-color-grey;
                    .list__document {
                        flex: 0 0 auto;
                        width: 400px;
                        height: 100%;
                        border-right: solid 1px $border-color-grey;
                    }
                    .rows {
                        padding: 8px 16px;
                        &__name {
                            cursor: pointer;
                        }
                    }
                }
            }
            .rows {
                @include flexcenter;
                height: 64px;
                padding: 8px 12px;
                border-bottom: 1px solid $border-color-grey;
                &__icon {
                    cursor: pointer;
                    &.svg-purple {
                        display: flex;
                    }
                    &:not(:has(.check-items)) + .rows__name {
                        margin-left: 10px;
                    }
                }
                &__template {
                    border-radius: 4px;
                    border: solid 1px $grey-boder-line;
                    overflow: hidden;
                }
                &__name {
                    flex: 1;
                    @include flexcolumns;
                    &:not(:has(.field-input)) {
                        width: calc(100% - 145px);
                        .name {
                            @include text-ellipsis;
                            line-height: 24px;
                        }
                    }
                }
                &__action {
                    @include flexcenter;
                    gap: 4px;
                    margin-left: 8px;
                }
                &:not(.--uploading) {
                    .rows__name {
                        color: $violet-name;
                    }
                }
                &.--uploading {
                    & > *:not(.rows__action) {
                        opacity: 0.5;
                        pointer-events: none;
                    }
                    &:not(.--error) {
                        .rows__action {
                            color: $blue-default;
                        }
                    }
                }
                &.--error {
                    .rows__action {
                        color: $red-default;
                    }
                    .circular-progress {
                        background: conic-gradient($red-default var(--progress), $black-darker6 0deg);
                        margin-right: 8px;
                    }
                }
                &.active {
                    background-color: $yellow-cosmic-latte;
                    border-right: 2px solid $yellow-default;
                    padding-right: 14px !important;
                }
            }
            .is-drag {
                position: relative;
                & > *:not(.box-drop-file) {
                    display: none;
                }
                .box-drop-file {
                    display: block;
                }
            }
            .title-header {
                width: 100%;
                flex: 0 0 auto;
                padding: 0;
            }
            .check-items {
                margin-top: -10px;
            }
            .wrap-empty {
                @include centeritem;
            }
        }
        .show-code {
            &:not(.open) {
                display: none;
            }
            &__more {
                border-top: 1px solid $border-color-grey;
                margin-top: 15px;
                padding-top: 10px;
            }
            .form-installation {
                @include betweentop;
                margin-top: 10px;
                > .v2-btn-default {
                    margin-left: 8px;
                    flex: 0 0 auto;
                }
            }
            .installation-content-code {
                padding: 10px;
                border-radius: 4px;
                border: 1px solid $border-color-grey;
                background-color: $bg-body;
                line-height: 22px;
                font-size: 13px;
                &__items {
                    display: inline-block;
                    padding: 0px 2px;
                    background: $grey-softlight;
                    border-radius: 4px;
                }
            }
            .rows {
                line-height: 24px;
                margin-bottom: 12px;
                .tag-body {
                    margin: 0 3px;
                    height: auto;
                    border-radius: 8px;
                }
            }
            & > img {
                @include border($border-color-grey, 3px, 'null');
            }
            pre {
                border-color: $border-color-grey !important;
            }
        }
        .hide {
            display: none !important;
        }
        .multi-unit-settings {
            padding: 18px 24px 24px;
            background-color: $white;
            .manage-tracking {
                line-height: 20px;
                color: $grey-dark;
                &__title {
                    margin-bottom: 10px;
                    font-weight: bold;
                    color: $grey-extradark;
                }
                &__content {
                    .rows {
                        @include betweenitems;
                        margin-top: 24px;
                    }
                }
            }
        }
        .btn-upgrage {
            @include flexcenter;
            background-color: $yellow-bright;
            color: $white-default;
            width: max-content;
            padding: 4px 8px 4px 4px;
            margin-left: auto;
            border-radius: 4px;
            svg {
                margin-right: 4px;
            }
        }
        .btn-action {
            @include flexcenter;
            .v2-btn-main {
                margin-left: 8px;
            }
        }
        .tables-empty {
            max-width: 560px;
        }
        .timewindown-template {
            @include betweentop;
            margin-top: 8px;
            &__name {
                color: $purple-default;
                word-break: break-word;
                flex: 1;
                font-size: 13px;
            }
            &__number {
                width: 150px;
                margin-left: 10px;
                text-align: right;
                color: $grey-generic;
            }
        }
        .box-timewindow {
            position: relative;
            margin-top: 16px;
            .tables-empty {
                margin: 16px;
                max-width: none;
            }
        }
        .form-timewindow {
            position: absolute;
            z-index: 1;
            background: $white;
            width: 690px;
            height: auto;
            min-height: calc(100% + 10px);
            left: -85px;
            top: -5px;
            padding: 0px;
            @include flexcolumns;
            &:not(.is-active) {
                display: none;
            }
            .dots {
                color: $black;
                margin-right: 23px;
            }
            .boxs__header {
                padding: 24px;
            }
            .boxs__contents {
                flex: 1;
                border-top: 1px solid $border-color-grey;
                padding: 0px !important;
            }
            .boxs__footer {
                background: $transparent;
                padding: 16px;
                height: auto;
            }
            .template-rows {
                padding: 16px;
                @include flexcenter;
                & + .template-rows {
                    box-shadow: 0px -1px 0px $border-color-grey;
                }
                .field-name {
                    flex: 1;
                    margin-right: 8px;
                }
                .v2-btn-default {
                    &.--delete {
                        margin-left: 8px;
                    }
                }
            }
        }
        .box-voip {
            border: 1px solid $grey-light-3;
            border-radius: 4px;
            box-shadow: $boxshadow-grey-light;
            margin-top: 8px;
            &__rows {
                padding: 14px 16px;
                gap: 2px 8px;
                &:not(:first-child) {
                    border-top: 1px solid $grey-light-3;
                }
                &:not(.is-enabled) {
                    .detail-enable {
                        display: none;
                    }
                }
                &.--setting-number {
                    .v2-dropdown__menu {
                        &,
                        .container-column {
                            @media (min-height: 749px) {
                                max-height: calc(100vh - 590px);
                            }
                        }
                    }
                }
                .detail-enable {
                    > .v2-btn-main {
                        margin: 0 auto;
                    }
                    .v2-btn-default {
                        &.btn-speech {
                            width: fit-content;
                        }
                    }
                }
                .dropdown-duration {
                    .dropbtn {
                        input {
                            max-width: 70px;
                        }
                    }
                }
                .txt-voicemail {
                    line-height: 32px;
                }
                .user-items {
                    background-color: $black-light;
                    border-radius: 4px;
                    padding: 8px;
                    margin-bottom: 4px;
                    height: 40px;
                }
                .dropdown-routing {
                    .v2-dropdown__menu {
                        z-index: 2;
                    }
                }
            }
            .status-btn {
                border-radius: 20px;
                height: 20px;
                padding: 0 6px;
            }
            .title-number {
                word-break: break-word;
                .dots::before {
                    background-color: rgba(28, 31, 34, 0.3);
                    margin: 0 12px 0 4px;
                }
            }
            .personal-info {
                &__name {
                    @include text-ellipsis;
                    margin: 0 6px 0 8px;
                    max-width: 140px;
                }
            }
        }
        .btn-exclamation {
            background: rgba(28, 31, 34, 0.04);
            border-radius: 4px;
            padding: 10px 8px;
            word-break: break-word;
            svg {
                flex: 0 0 auto;
                margin-right: 8px;
            }
        }
        // Dropdown Search member
        .search-member {
            .v2-dropdown__menu {
                right: 0;
                width: 204px;
                @media (min-height: 750px) {
                    &,
                    .container-column {
                        max-height: 400px;
                    }
                }
            }
            .item-checkbox {
                label {
                    @include flexcenter;
                    &::before {
                        top: 4px;
                    }
                    &::after {
                        top: 8px !important;
                    }
                }
                .txt-ellipsis {
                    padding-left: 6px;
                    font-weight: 500;
                    white-space: nowrap;
                }
            }
        }
        .--music {
            .box-voip {
                margin-top: 0;
                & + .box-voip {
                    margin-top: 16px;
                }
            }
        }
        @media (max-width: 1270px) {
            .addons-wrap__container {
                &.addons-wrap__material {
                    .material-content-full {
                        width: 560px;
                        .--material {
                            width: 100%;
                            margin-right: 0;
                        }
                    }
                }
            }
        }
        &__blocked {
            .header,
            .boxs-wrapper {
                width: 640px;
            }
            .tables {
                @include font-color(16px, 13px, $black);
                @include flexcolumns;
                border-radius: 8px;
                height: 100%;
                & > * {
                    font-weight: 500;
                }
                &__header {
                    @include flexcenter;
                    gap: 8px;
                    padding: 8px 16px;
                    position: sticky;
                    z-index: 1;
                    top: 0;
                    border-bottom: 1px solid $black-dark-jungle1;
                }
                &__contents {
                    overflow-y: auto;
                    .rows {
                        @include flexcenter;
                        justify-content: space-between;
                        column-gap: 8px;
                        min-height: 28px;
                        padding: 6px 16px;
                        border-bottom: 1px solid $black-dark-jungle1;
                    }
                    .col-phone {
                        .status-btn {
                            border-radius: 2px;
                            height: 20px;
                            padding: 0 6px;
                        }
                    }
                    .col-checkbox {
                        .check-items {
                            input[type='checkbox']:checked + .item-checkbox label::after {
                                top: 0;
                            }
                            label::before {
                                top: -4px;
                            }
                        }
                    }
                    .col-icon {
                        flex: 1;
                        text-align: right;
                        .v2-btn-default {
                            color: $black;
                            font-size: 13px;
                        }
                    }
                    .col-checkbox {
                        width: 18px;
                    }
                    .col-phone,
                    .col-time {
                        width: 125px;
                        min-width: 125px;
                    }
                    .col-customer {
                        width: 184px;
                        min-width: 184px;
                    }
                }
            }
            .wrap-tables {
                box-shadow: 0px 0px 1px 0px #00000014, 0px 0px 2px 0px #0000000a, 0px 0px 3px 0px #0000000f;
                border-radius: 8px !important;
            }
            & > .alert,
            & .scrolls > .alert {
                max-width: 600px;
            }
        }
        &:has(.container-user-list) {
            &:has(.wrap-tables + .header) {
                overflow-y: auto !important;
            }
        }
        // Setting portal
        .--setting-portal,
        .setting-portal {
            .boxs__footer {
                background-color: $white;
            }
            .boxs__contents {
                padding: 16px;
            }
            .line {
                @include flexcenter;
                gap: 10px;
            }
            .txt {
                color: $black-darker3;
                width: 170px;
                flex-shrink: 0;
                @include text-ellipsis;
            }
            .field {
                flex: 1;
                width: calc(100% - 180px);
            }
        }
        .setting-portal {
            border-bottom: 1px solid $border-color-grey;
            padding-top: 0 !important;
        }
    }
    &:has(.wrapper-opp-pipelines) {
        .header,
        .boxs-wrapper {
            width: 550px;
        }
        .wrapper-opp-pipelines__content {
            padding: 0;
            overflow-y: unset;
            .boxs {
                box-shadow: 0px 0px 1px 0px $black-extradark, 0px 0px 2px 0px $black-light,
                    0px 0px 3px 0px $black-verydark;
                &__header {
                    align-items: start;
                }
                &__contents {
                    margin: 0;
                }
                .status-btn {
                    height: 24px;
                    padding: 0 6px;
                    font-size: 14px;
                }
            }
        }
    }
    &:has(.tabs-job) {
        .header:not(.--filter),
        .boxs-wrapper,
        .container-print {
            width: 600px !important;
        }
        .boxs-wrapper {
            .wrapper-job-status__content {
                .boxs {
                    margin-bottom: 4px;
                    padding: 0;
                    border-radius: 8px;
                    &__header {
                        padding: 16px;
                        align-items: flex-start;
                        border-bottom: 1px solid $black-extradark;
                    }
                    .rows {
                        @include flexcenter;
                        padding: 10px 16px;
                        gap: 4px;
                        .status-btn {
                            color: var(--color-job-status);
                            font-size: 14px;
                            height: 24px;
                            padding: 0 6px;
                            background-color: var(--bg-job-status);
                            @include text-ellipsis;
                            display: inline-block;
                            text-transform: none;
                            & + .status-btn {
                                margin-left: 4px;
                            }
                            &:first-child {
                                max-width: 60%;
                                flex-shrink: 0;
                            }
                        }
                        .col-status {
                            display: flex;
                            width: calc(100% - 108px);
                        }
                        .col-drag {
                            cursor: grab;
                        }
                        .col-select-color {
                            .field-control {
                                padding-left: 24px;
                                width: 100px;
                            }
                            .sketch-picker {
                                padding-top: 32px !important;
                            }
                            .color-picker__close {
                                position: absolute;
                                right: 8px;
                                top: 4px;
                            }
                            .wrap-rows {
                                position: relative;
                                &__icons {
                                    position: absolute;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    left: 10px;
                                    .is-dots {
                                        @include size(8px);
                                        border-radius: 50%;
                                        background-color: var(--bg-job-status);
                                    }
                                }
                            }
                        }
                        &:not(.--custom-status):not(:has(.col-drag)),
                        &:has(.col-drag.dp-hide) {
                            padding-left: 45px;
                        }
                        &.--custom-status {
                            background-color: $black-lighter;
                            .col-status {
                                justify-content: space-between;
                                & > * {
                                    width: calc(50% - 2px);
                                }
                                .v2-dropdown {
                                    background-color: $white;
                                }
                            }
                            .btn-add-job {
                                width: 68px;
                                justify-content: center;
                            }
                        }
                        .col-icon,
                        .btn-add-job {
                            margin-left: 4px;
                        }
                    }
                    .box-draggable {
                        .rows {
                            border-top: 1px solid $black-extradark;
                            &.is-drag {
                                background-color: $white;
                            }
                        }
                        & + .--custom-status {
                            border-top: 1px solid $black-extradark;
                        }
                    }
                    &:not(:has(.rows + .box-draggable)) {
                        .box-draggable {
                            .rows:first-child {
                                border-top: 0;
                            }
                        }
                    }
                }
            }
        }
        .container-print {
            margin: auto;
            padding-bottom: 20px;
            .header.--filter {
                margin: 0;
                width: unset;
            }
            .wrap-tables {
                .col-job-status {
                    max-width: unset !important;
                    .status-btn {
                        max-width: 100%;
                        display: block;
                    }
                }
                .tables-list {
                    .rows:last-child {
                        box-shadow: inset 0px -1px 0px $border-color-grey;
                    }
                    .col-job-status {
                        .status-btn {
                            @include text-ellipsis;
                        }
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 921px) {
        .addons-wrap {
            .tabs-dashboard {
                &.is-grid-view {
                    .tab-content-active {
                        grid-template-columns: auto auto;
                    }
                    .boxs {
                        width: auto;
                    }
                }
                &:not(.is-grid-view) {
                    .boxs {
                        &__contents {
                            margin-right: 20px;
                        }
                        &__last-items {
                            min-width: 185px;
                        }
                    }
                }
                .tables-list {
                    .tables-empty {
                        max-width: calc(600px - 48px);
                        width: calc(600px - 48px);
                    }
                }
            }
        }
        .material-content-full {
            .wrap-loading {
                width: 100%;
            }
        }
    }
    &:has(.call-bar):not(:has(.is-disable-calls)) {
        .container-user-list {
            .dropdown-add-user {
                .v2-dropdown__menu {
                    max-height: calc(100dvh - 350px);
                    @media (max-height: 600px) {
                        max-height: 250px;
                    }
                }
            }
        }
    }

    .container-chatbot {
        @include flextop;
        gap: 16px;
        width: 100%;
        padding-top: 16px;
        .sidebar-chatbot {
            width: 225px;
            align-items: flex-start;
            position: relative;
            .sidebar-menu {
                padding: 0;
                @include flexcolumns;
                gap: 4px;
                width: 100%;

                .sidebar-items {
                    line-height: 20px;
                    &:not(:active):not(:hover):not(.active) {
                        background: light-dark(#ffffffe6, #181a1be6);
                    }
                }
                &__title {
                    padding-left: 6px;
                }
            }
            .txt-chatbot {
                max-width: calc(100% - 28px);
            }
        }
        .wrapper-chatbot {
            flex: 1;
            height: 100%;
            width: calc(100% - 241px);
            padding-right: 16px;
            &:not(.--setting) {
                .container-print {
                    margin-top: 14px;
                    .header:not(.--filter) {
                        padding: 2px 0 8px;
                    }
                }
            }
            &__title {
                line-height: 32px;
            }
            .rows {
                &.--fixed {
                    background-color: $white-anti-flash;
                }
                .status-btn {
                    &.deleted {
                        background-color: rgba($red-default, 0.06);
                        color: $red-default;
                    }
                    & > svg {
                        @include size(24px);
                    }
                }
                .col {
                    &:has(.has-icon) {
                        padding-top: 6px;
                        padding-bottom: 6px;
                    }
                    @media (max-width: 1180px) {
                        &.col-sm {
                            max-width: 125px !important;
                        }
                    }
                    &.--tags {
                        &:has(.list-add-tags) {
                            padding: 4px 0;
                        }
                        &:not(:has(.tags)) {
                            display: flex;
                        }
                        .tags {
                            & > .tags__info {
                                margin-top: 2px;
                            }
                        }
                    }
                }
                &:not(.--header):last-child {
                    box-shadow: inset 0px -1px 0px $border-color-grey;
                }
                &:has(.--tags) {
                    .col-label,
                    .name {
                        line-height: 22px;
                    }
                }
            }
            &.--setting {
                @include flextop;
                justify-content: center;
                padding-bottom: 20px;
                overflow-y: auto;
                .box-setting {
                    width: 520px;
                    border-radius: 4px;
                    border: 1px solid $grey-verylight;
                    box-shadow: 0px 0px 1px 0px $black-light, 0px 0px 2px 0px $black-verydark,
                        0px 0px 3px 0px $black-extradark;
                    background-color: $white;
                    & > .flexcenter {
                        .is-loading-box {
                            width: 32px;
                            margin-left: -4px;
                        }
                    }
                    .from-time {
                        .dropbtn {
                            & > *:first-child {
                                width: 19px;
                            }
                        }
                        @media (max-height: 768px) {
                            .v2-dropdown__menu {
                                max-height: 200px;
                            }
                        }
                    }
                    & > *:not(.container-print) {
                        padding: 16px;
                    }
                    &__container {
                        border-top: 1px solid $border-color-grey;
                        border-bottom: 1px solid $border-color-grey;
                        @media (max-height: 700px) {
                            & > *:last-child {
                                .v2-dropdown__menu {
                                    bottom: calc(100% + 4px);
                                    top: unset;
                                }
                            }
                        }
                    }
                    .v2-dropdown__menu {
                        .is-divider {
                            margin: 2px 0;
                        }
                    }
                }
                .wrap-tables {
                    box-shadow: none;
                    border: 0;
                    border-radius: 0 !important;
                    overflow-y: unset;
                }
                .table-setting {
                    overflow: unset;
                    border-bottom: 1px solid $border-color-grey;
                    .rows {
                        box-shadow: none;
                        align-items: center;
                        .col-day {
                            min-width: 60px;
                            max-width: 60px;
                            &:has(.check-items) {
                                padding-top: 4px;
                            }
                        }
                    }
                    .tables-list {
                        padding-top: 8px;
                        .col {
                            padding-top: 0;
                            padding-bottom: 0;
                        }
                        .rows {
                            margin-bottom: 8px;
                            overflow: unset;
                        }
                        .col-lg:not(.v2-dropdown) {
                            height: 32px;
                            line-height: 32px;
                        }
                    }
                }
            }
            .btn-question-sms {
                width: 230px;
            }
        }
        .box-empty {
            background-color: $black-lighter;
            margin-top: 14px;
            &__container {
                margin: auto;
            }
        }
    }
    .addons-chatbot {
        position: relative;
        &::before {
            content: '';
            position: absolute;
            background: url(#{$cdnPathImages}ai-agent-avatar.webp) bottom center no-repeat;
            background-size: 220px auto;
            width: 225px;
            height: 100%;
            left: 0;
            bottom: 14px;
            @media (max-height: 860px) {
                background-size: 180px auto;
            }
            @media (max-height: 780px) {
                background-size: 150px auto;
            }
        }
        // Box test SMS
        &.boxs-notification {
            .notification-dropdown {
                box-shadow: 0px 0px 1px 0px $black-light, 0px 0px 2px 0px $black-verydark,
                    0px 0px 3px 0px $black-extradark;
                border: 1px solid $grey-verylight;
                position: absolute !important;
                height: calc(100% - 16px) !important;
                &.active {
                    @include flexcolumns;
                }
                &.notification-sms {
                    .box-message-chat {
                        position: unset;
                    }
                }
                .message-header {
                    @include betweenitems;
                    padding: 8px;
                    border-bottom: 1px solid $border-color-grey;
                    gap: 8px;
                    &__title {
                        width: calc(100% - 40px);
                    }
                }
                .message-contents {
                    flex: 1;
                    overflow-y: auto;
                    .description {
                        max-width: 100% !important;
                        width: fit-content;
                        min-height: 32px;
                        min-width: 32px;
                        color: $black;
                        white-space: pre-wrap;
                    }
                    &:not(:has(.conts-conversation)) {
                        padding: 8px 8px 16px;
                    }
                }
                .message-chat {
                    height: 130px;
                    @include flexcolumns;
                    border-top: 1px solid $border-color-grey;
                    & > * {
                        padding: 8px;
                    }
                }
                .wrapper-message {
                    .avt {
                        line-height: 24px;
                    }
                }
            }
        }
        &:has(.--has-code) {
            .boxs-wrapper,
            .header {
                width: 600px;
            }
            .scrolls > .alert,
            > .alert {
                max-width: 600px;
            }
        }
        code {
            padding: 0 4px !important;
        }
    }
}
// Plans in VoIP
.wrap-plans {
    .has-tooltip {
        display: none;
        .tooltiptext {
            flex-direction: column;
            text-align: left;
            .dots {
                &::before {
                    background-color: $white;
                }
            }
        }
    }
    &__option {
        .header-plans {
            border-bottom: 1px solid $border-color-grey;
            min-height: 88px;
            color: #7f7f7f;
        }
        .content-plans {
            padding-top: 6px;
            min-height: 120px;
        }
        .svg-sm {
            &,
            svg {
                @include size(10px);
            }
        }
        .is-grey {
            svg rect {
                fill: $grey-verylight;
            }
        }
        .status-btn {
            background: #44a850;
            font-size: 12px;
            margin: 2px auto 0;
            height: 20px !important;
            line-height: 20px;
            padding: 0 6px !important;
        }
    }
    &__balance {
        .status-btn {
            border-radius: 50px !important;
        }
        .divide-bar {
            width: 2px;
        }
    }
    &__cost {
        .progress-bar {
            .slider-main {
                left: calc(15% - 6px);
                width: calc(70% + 14px);
            }
            .range-overlay {
                width: 15%;
                background: $blue-default;
            }
            .slider-main {
                &::-webkit-slider-thumb {
                    @include size(12px);
                    background: $blue-default;
                }
            }
        }
        .progress-value {
            margin-top: 8px;
            .values {
                min-width: 33px;
                margin-left: -16px;
                text-align: center;
            }
        }
        .values,
        .divide-bar {
            &:nth-last-child(3) {
                left: 15%;
            }
            &:nth-last-child(2) {
                left: 50%;
            }
            &:last-child {
                left: 85%;
            }
        }
        .box-plans:not(.has-four-value) {
            .progress-bar {
                &.is-sm {
                    .range-overlay {
                        width: 15% !important;
                    }
                    .divide-bar:nth-last-child(3) {
                        display: none;
                    }
                }
                &.is-md {
                    .range-overlay {
                        width: 50%;
                    }
                    .divide-bar:nth-last-child(2) {
                        display: none;
                    }
                }
                &.is-lg {
                    .range-overlay {
                        width: 85% !important;
                    }
                    .divide-bar:last-child {
                        display: none;
                    }
                }
            }
            .values,
            .divide-bar {
                &:nth-last-child(3) {
                    left: 15%;
                }
                &:nth-last-child(2) {
                    left: 50%;
                }
                &:last-child {
                    left: 85%;
                }
            }
        }
        .has-four-value {
            .progress-bar {
                &.is-sm {
                    .range-overlay {
                        width: 15% !important;
                    }
                    .divide-bar:nth-last-child(4) {
                        display: none;
                    }
                }
                &.is-md {
                    .range-overlay {
                        width: 38% !important;
                    }
                    .divide-bar:nth-last-child(3) {
                        display: none;
                    }
                }
                &.is-lg {
                    .range-overlay {
                        width: 62% !important;
                    }
                    .divide-bar:nth-last-child(2) {
                        display: none;
                    }
                }
                &.is-xl {
                    .range-overlay {
                        width: 85% !important;
                    }
                    .divide-bar:last-child {
                        display: none;
                    }
                }
            }
            .values,
            .divide-bar {
                &:nth-last-child(4) {
                    left: 15%;
                }
                &:nth-last-child(3) {
                    left: 38%;
                }
                &:nth-last-child(2) {
                    left: 62%;
                }
                &:last-child {
                    left: 85%;
                }
            }
        }
    }
    &__user {
        .v2-dropdown {
            .dropbtn {
                color: $blue-default;
                border-color: rgba($color: $blue-default, $alpha: 0.5) !important;
                background: rgba($color: $blue-default, $alpha: 0.06) !important;
                &.has-icon {
                    svg path {
                        fill: $blue-default;
                    }
                }
                &:hover {
                    color: $blue-default !important;
                    border-color: rgba($color: $blue-default, $alpha: 0.5) !important;
                }
            }
            &__menu {
                right: 0;
                max-height: calc(100dvh - 700px) !important;
                z-index: 2;
                min-width: unset !important;
                @media (max-height: 750px) {
                    bottom: calc(100% + 4px);
                    top: unset !important;
                    max-height: 250px !important;
                }
                .items {
                    min-height: 32px !important;
                }
            }
        }
    }
    .box-plans {
        background: $white;
        border: 1px solid $border-color-grey;
        box-shadow: 0px 0px 3px $black-verydark, 0px 0px 2px $black-light, 0px 0px 1px $black-extradark;
        border-radius: 4px;
        padding: 8px 6px;
        &.active {
            border-color: $orange-red;
            pointer-events: none;
        }
        &.--lg {
            padding: 16px 12px;
        }
        .switch__label {
            height: 20px;
            line-height: 24px;
        }
        &.old-activated {
            border-color: $grey-soft;
            &,
            .flex-column {
                background: $extra-white;
            }
        }
        &.box-checkboxs {
            padding: 12px;
            .item-checkbox label {
                width: unset;
                max-width: 100%;
                .txt-ellipsis {
                    display: block;
                }
            }
        }
    }
    .balance-remaining {
        .progress-bar:not(.per-0) {
            .remaining-value {
                @include transform(translateX(-50%));
                left: 50%;
            }
        }
        .remaining-value {
            margin-top: 12px;
        }
    }
    .balance-credit {
        width: 85px;
        .progress-bar {
            min-width: 100%;
            margin-bottom: 3px;
        }
        .status-btn {
            height: 16px;
            line-height: 16px;
            padding: 0 6px;
        }
    }
    .slider {
        .active {
            @extend .fw-600;
        }
    }
    .progress-bar {
        background-color: $black-darker4;
        margin: 8px 0 6px;
    }
    .note-billing {
        background: $white-lotion;
        border-radius: 4px;
        height: 28px;
    }
}
.wrapper-billing {
    .wrap-container {
        &__form,
        &__preview {
            width: 620px !important;
        }
        &__preview {
            min-width: 580px !important;
        }
        .wrap-plans {
            height: unset;
            .has-tooltip {
                display: block;
            }
            .without-tooltip {
                display: none;
            }
            .user-content {
                align-items: center;
                .v2-dropdown__menu {
                    z-index: 3;
                }
            }
        }
    }
    .credit-content {
        @include flexcolumns;
        gap: 4px;
    }
}
// Add phone number in Addons > voip
.manager-phone-number {
    .wrap-manager {
        gap: 4px;
        margin-bottom: 24px;
        .tab-items {
            background: $white;
            border: 2px solid $grey-platinum;
            border-radius: 4px;
            cursor: pointer;
            gap: 8px;
            padding: 18px 16px;
            text-align: center;
            position: relative;
            flex: 1;
            &.active {
                background: $purple-extralight;
                border-color: $purple-default;
            }
            .check-items {
                position: absolute;
                left: 6px;
                top: 0px;
                input[type='checkbox']:checked + .item-checkbox label::after {
                    left: 3px;
                }
            }
        }
        .list-tabs {
            align-items: stretch;
        }
    }
    .tab-panel {
        .message-info {
            padding: 8px 12px;
            border-radius: 4px;
            margin-bottom: 16px;
        }
    }
}
// Voip user list
.container-user-list {
    padding: 0 16px 0 0;
    .header:not(.--filter) {
        padding-bottom: 12px;
        position: relative;
        z-index: 10000;
        background-color: $white-anti-flash;
        &:first-child {
            padding-top: 12px;
        }
    }
    .dropdown-add-user {
        .v2-dropdown__menu {
            z-index: 10001;
            width: 204px;
            right: 0;
        }
        .txt-ellipsis {
            white-space: nowrap !important;
        }
    }
    .wrapper-call {
        box-shadow: unset;
        background: unset;
        z-index: 1;
        .avt-has-micro {
            &.--normal {
                border: 0px;
            }
            .avt-img {
                @include size(32px);
                margin-top: 0px;
            }
        }
    }
    .wrap-tables {
        border-top: 0 !important;
        .table-user {
            overflow-y: unset;
            min-width: 1250px;
            .--header {
                z-index: 9998 !important;
                border-top: 1px solid $border-color-grey;
            }
            .col {
                &.col-name {
                    min-width: 185px !important;
                    @media (max-width: 1180px) {
                        min-width: 300px !important;
                    }
                    .txt-name {
                        max-width: calc(100% - 46px);
                    }
                }
                &.col-xl,
                &.col-lg {
                    max-width: 250px;
                }
                .switch input.toggle-round:checked + label:before {
                    background-color: $green-default;
                }
                &.col-group-call {
                    min-width: 380px !important;
                    max-width: 420px;
                    .switch {
                        width: calc(50% - 5px);
                        &__label {
                            width: calc(100% - 40px);
                            @include text-ellipsis;
                        }
                    }
                }
            }
            .dropbtn.items {
                max-width: 190px;
                height: 24px;
                &.--without-number {
                    width: 24px;
                    padding: 0px;
                }
            }
            .tables-list .rows {
                overflow: visible;
                min-height: 56px;
                align-items: center;
                &:last-child {
                    box-shadow: inset 0px -1px 0px $border-color-grey;
                }
                &:has(.v2-dropdown.active) {
                    z-index: 9999;
                    position: relative;
                }
            }
            .switch__label {
                color: $grey-quick-silver;
                font-weight: 500;
                &.--active {
                    color: $black;
                }
            }
            .tables-empty {
                max-width: 100%;
            }
        }
        .footer-pages {
            z-index: unset;
        }
        & + .header {
            z-index: 9999;
        }
    }
    .v2-dropdown {
        .select-phone-number {
            width: 400px;
            max-height: 400px;
            &__container {
                max-height: 300px;
                @media (max-height: 700px) {
                    max-height: 200px;
                }
                &,
                .v2-dropdown__menu {
                    & > .border-bottom-border-color-grey:last-child {
                        border-bottom: 0 !important;
                    }
                }
                .number-assigned {
                    .number-assigned__items {
                        & + .number-assigned__items {
                            border-top: 1px solid $border-color-grey;
                        }
                    }
                    &:has(.number-assigned__items + .number-assigned__items) {
                        min-height: 105px;
                        max-height: 105px;
                        overflow-y: auto;
                        @media (max-height: 700px) {
                            max-height: 53px;
                        }
                    }
                }
            }
            .number-title {
                color: $grey-generic;
                text-transform: uppercase;
                padding: 16px 16px 4px;
            }
            .schedule-user .tech-name,
            .number-items .txt-ellipsis {
                line-height: 24px;
            }
            .swap-user {
                border-top: 1px solid $border-color-grey;
                &.active {
                    .dropbtn {
                        background-color: $yellow-light !important;
                        box-shadow: none !important;
                    }
                }
                .v2-dropdown__menu {
                    max-height: 120px;
                    @media (min-height: 700px) {
                        max-height: 18vh;
                    }
                    @media (min-width: 900px) {
                        left: calc(100% + 4px) !important;
                        top: 0;
                        width: 330px;
                    }
                }
            }
        }
    }

    .dropdown-option {
        top: 50% !important;
        transform: translateY(-50%);
        min-width: 140px !important;
        width: max-content !important;
        right: 0;
        box-shadow: 0px 0px 3px $black-verydark, 0px 0px 2px $black-light, 0px 0px 1px $black-extradark !important;
    }
    &:has(.custom-field) {
        .wrap-tables {
            height: unset;
            .tables {
                .tag-label {
                    cursor: default;
                }
            }
        }
    }
    &:has(.wrap-tables + .header) {
        .header {
            background-color: $transparent;
        }
        .container-print {
            height: unset;
        }
        .wrap-tables {
            height: unset;
            .tables-list {
                .rows:last-child {
                    box-shadow: none;
                }
            }
        }
    }
}
// End Voip user list
.box-st-hour {
    padding: 16px 24px;
}
.box-use-variable,
.box-st-hour {
    .box-record {
        flex-direction: column-reverse;
    }
}
.wrapper-extensions {
    .btn-add {
        width: max-content;
    }
    .wrap-border {
        border-radius: 4px;
        overflow-y: visible !important;
    }
    .rows {
        overflow: visible !important;
        gap: 0 !important;
        &:last-child {
            box-shadow: none !important;
        }
        &.--fixed {
            border-radius: 4px 4px 0 0;
        }
        .v2-dropdown {
            &:not(.active) {
                .dropbtn:hover {
                    border-color: $transparent !important;
                    box-shadow: none !important;
                }
            }
        }
        .box-custom-extension {
            width: 250px;
            max-height: unset;
        }
        .phone-info {
            width: 250px !important;
        }
        .custom-input::placeholder {
            color: $grey-medium;
        }
    }
    .col {
        &.--sm {
            max-width: 120px !important;
            border-right: 1px solid $border-color-grey;
        }
        &.--lg {
            max-width: none !important;
        }
        &.--action {
            flex: 0 0 32px !important;
            min-width: unset !important;
            padding: 4px 0 !important;
        }
    }
    .nav-call-module {
        > .dropbtn {
            border-radius: 4px;
            padding: 0 12px 0 4px;
        }
        > .v2-dropdown__menu {
            max-height: unset;
            top: auto;
            bottom: calc(100% + 2px);
        }
        .setting-device {
            .v2-dropdown {
                max-width: calc(100% - 100px);
            }
        }
        .wrap-dialpad__item {
            min-width: 100px;
        }
    }
    .wrap-action {
        background: $white;
        box-shadow: $boxshadow-btn-grey;
        border-radius: 4px;
        padding: 4px;
        right: -1px;
    }
    .enable-number {
        .v2-dropdown__menu {
            max-width: 350px;
            .items-container {
                border-bottom: 1px solid $border-color-grey;
                &:last-child {
                    border-bottom: 0;
                }
                .items {
                    margin: 2px;
                    .check-items {
                        width: 100%;
                        .item-checkbox {
                            label {
                                &::before {
                                    top: 4px;
                                }
                                &::after {
                                    top: 8px;
                                }
                            }
                        }
                    }
                }
            }
        }
        .schedule-user {
            max-width: 50%;
            .tech-name {
                & > svg {
                    margin-left: 6px;
                }
            }
        }
        .txt-ellipsis {
            line-height: 24px;
        }
    }
    .dropdown-delete {
        height: 24px;
        .v2-dropdown__menu {
            right: 0;
            top: 50%;
            @include transform(translateY(-50%));
        }
    }
    .has-img-user {
        .field-custom {
            padding: 4px 8px 0;
        }
        .check-items {
            margin-top: 0 !important;
            .avt-img,
            .avt-img img,
            .avt {
                @include size(24px);
                margin: 0;
            }
            .txt-ellipsis {
                line-height: 24px;
            }
        }
        .tabs {
            margin: 5px 8px;
        }
        .v2-dropdown__menu {
            width: max-content;
            max-width: 480px;
            &.has-drag-items {
                .scrolls {
                    &:has(.icon-drag) {
                        padding: 0;
                        margin: 3px 2px 1px;
                        .items {
                            background-color: $black-light;
                            .check-items {
                                width: calc(100% - 24px);
                            }
                        }
                    }
                    .items {
                        margin-bottom: 2px;
                        &:not(:has(.icon-drag)) {
                            padding-left: 30px;
                        }
                        & + .items {
                            margin-top: 0;
                        }
                    }
                }
            }
            &:not(.has-drag-items) {
                .icon-drag {
                    display: none;
                }
            }
        }
        .scrolls {
            max-height: 200px;
            & + .scrolls {
                &:has(.items) {
                    border-top: 1px solid $border-color-grey;
                }
                &:not(:has(.items)) {
                    padding: 0;
                }
            }
        }
        .icon-drag {
            margin: 0 4px 0 -6px;
            cursor: grab;
        }
    }
}
.wrapper-extensions,
.box-voip {
    .has-drag {
        border: 1px dashed $black-2 !important;
        background: $black-verydark;
        opacity: 0.5;
    }
}
// Multiple branch
.multiple-branch {
    .rows {
        align-items: center !important;
        overflow: unset !important;
        .search-member {
            width: fit-content;
            .box-auto {
                max-height: 250px;
            }
        }
        .wrap-member-group {
            .avt-img {
                margin-top: 0;
                margin-right: 0;
                width: unset;
                &.avt-more {
                    min-width: 22px;
                }
            }
        }
        &.--header {
            z-index: 3 !important;
        }
        .col-company {
            max-width: 55px !important;
            min-width: 55px !important;
            .avt-img {
                object-fit: cover;
            }
            .avt-img,
            .avt {
                @include size(32px);
                border-radius: 50%;
            }
        }
        .col {
            .col-label {
                white-space: pre !important;
            }
            &.--menu {
                .v2-dropdown {
                    .dropbtn {
                        border-radius: 4px !important;
                    }
                    &__menu {
                        width: 100px !important;
                    }
                }
            }
        }
        &:last-child {
            box-shadow: inset 0px -1px 0px $border-color-grey !important;
        }
        .select-status {
            width: fit-content;
            .dropbtn {
                width: fit-content;
                background-color: $white;
            }
            .dots {
                &::before {
                    background-color: var(--branch-color);
                    border: 1px solid $grey-philippine-silver;
                }
            }
            .v2-dropdown__menu {
                font-weight: 500;
                min-width: unset;
                max-height: 250px;
            }
        }
    }
    .tables-list {
        .rows {
            background-color: var(--background);
            @extend .fw-600;
            color: $black;
            .col-company {
                font-weight: 500;
                .avt,
                .avt::before {
                    background-color: var(--branch-color);
                }
            }
            .dropbtn:active {
                background-color: $white !important;
            }
        }
    }
    .tables-empty {
        max-width: unset !important;
    }
}
.wrap-plans,
.modal-edit-branch {
    .upload-avt {
        input[type='file'] {
            display: none;
        }
    }
    .preview-avt {
        height: 40px;
        @include flexcenter;
        .avt-img,
        .avt {
            @include size(40px);
            border-radius: 50%;
        }
        .avt-img {
            object-fit: cover;
        }
        .avt {
            font-size: 16px;
            &,
            &::before {
                background-color: var(--branch-color);
            }
        }
    }
}

// Modal Drive Matrix
.modal-drive-matrix,
.addons-wrap {
    .wrap-process {
        padding: 0;
        margin-bottom: 0;
        background: inherit;
        .rows {
            @include betweenitems;
            & + .rows {
                margin-top: 8px;
            }
            &__label {
                width: 188px;
                @include text-ellipsis;
            }
            &__number {
                text-align: right;
                font-weight: 500;
                @include font-color(28px, 17px, inherit);
                @include text-ellipsis;
                &:not(.phone) {
                    width: 60px;
                }
                &.phone {
                    max-width: 40%;
                }
            }
            .phone {
                color: $purple-default;
            }
            .progress-bar {
                flex: 1;
                margin: 0 8px;
                height: 12px;
                border-radius: 2px;
                background-color: $background-grey;
                span {
                    height: 100%;
                    border-radius: 2px;
                }
            }
        }
    }
    .customer-process {
        .rows {
            &__label {
                width: 195px;
            }
            .rows__number {
                width: 115px;
            }
        }
    }
}
.box-report-sms:not(:has(.notification-dropdown.active)) {
    display: none;
}
// Chatbot SMS
.modal-chatbot {
    .modal__container {
        width: 645px;
    }
    .body-modal {
        padding: 0;
        @include flexcolumns;
        & > .rows {
            padding: 10px 23px;
            min-height: 52px;
            & + .rows {
                border-top: 1px solid $border-color-grey;
            }
            .switch {
                label {
                    &::before {
                        top: 0;
                    }
                    &::after {
                        top: 2px;
                    }
                }
                .switch__label {
                    line-height: 20px;
                }
                &:has(.toggle-round:checked) {
                    label:before {
                        background-color: $green-default;
                    }
                    .switch__label {
                        color: $green-default;
                    }
                }
            }
            .btn-question {
                width: fit-content;
            }
            &:has(.search-input) {
                min-height: auto;
                .svg-search-absolute {
                    left: -3px;
                }
            }
            .--h-40 {
                min-height: 40px;
            }
        }
        .wrap-tables {
            height: unset;
            max-height: 350px;
            overflow-y: unset;
            border-radius: 8px !important;
            .rows {
                &.--header {
                    border-radius: 8px 8px 0 0;
                }
                &:last-child {
                    box-shadow: none;
                }
                .col:last-child {
                    max-width: 130px;
                }
            }
            .content-empty {
                border-radius: 0 0 8px 8px;
            }
        }
        .wrapbox-editor {
            &__form {
                height: calc(100% - 48px);
            }
            .DraftEditor-root,
            .DraftEditor-editorContainer,
            .public-DraftEditor-content,
            & {
                height: 100%;
            }
            .public-DraftEditor-content {
                color: $grey-extradark;
            }
        }
    }
    &.--select-option {
        .header-modal {
            height: auto;
            padding: 8px 23px;
        }
        .option-items {
            border-bottom: 1px solid $border-color-grey;
            padding: 10px 23px;
            &__desc {
                @include text-line-clamp(2);
            }
        }
    }
    .chart {
        height: 317px;
    }
}

#gorilladesk-portal-widget-chat {
    transform: translateY(64px);
    @media (min-width: 501px) {
        z-index: 10000 !important;
    }
}
body {
    @media (min-width: 501px) {
        &:has(.connection-bar),
        &:has(.notify-bar) {
            #gorilladesk-portal-widget-chat {
                height: calc(100dvh - 160px) !important;
            }
        }
        &:has(.call-bar) {
            #gorilladesk-portal-widget-chat {
                height: calc(100dvh - 180px) !important;
            }
            &:has(.connection-bar),
            &:has(.notify-bar) {
                #gorilladesk-portal-widget-chat {
                    height: calc(100dvh - 235px) !important;
                }
            }
        }
    }
}
