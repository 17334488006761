@import 'src/assets/css/common/variables';
@import 'src/assets/css/common/mixins';

.inbox-page {
    &:not(.active) {
        .sidebar-left {
            padding: 16px 16px 0 8px;
        }
    }
    .container-inbox {
        @include font-color(16px, 13px, light-dark(#333639, #c6c1b9));
        .tooltip {
            .tooltiptext {
                &.top {
                    bottom: calc(100% + 5px);
                }
                max-width: 200px;
                line-height: 16px;
                white-space: normal;
            }
        }
        .tables__content .rows:first-child .rows-content,
        .tables__content .rows:first-child .calls-preview,
        .header {
            .tooltiptext.top {
                bottom: auto;
                top: calc(100% + 3px);
            }
        }
        .nav-top:has(button:last-child.active-tab-selector) + .wrap-tables {
            *.sms-content {
                .col-content {
                    width: 450px;
                    min-width: 450px;
                }
            }
        }
    }
    .header {
        padding: 16px 8px 16px 0;
        .check-items {
            min-width: 100px;
        }
        .dropbtn {
            &.selection {
                padding-left: 4px;
            }
        }
        .--haslabel {
            .dropbtn .text {
                font-weight: 500;
                color: $black-3;
            }
        }
        .search-input {
            width: 240px;
        }
        .datetime-view.--sendlater {
            bottom: auto;
            top: calc(100% + 4px);
            left: 50%;
            @include transform(translateX(-50%));
        }
        .tab-items {
            &.active-tab-selector {
                svg {
                    path,
                    circle {
                        stroke: $white-default;
                    }
                }
                .budget {
                    background-color: $grey-platinum-dark;
                    color: $black-darklight1;
                }
            }
        }
        &__content {
            .v2-dropdown__menu {
                z-index: 2;
            }
        }
        &.--filter {
            z-index: 10;
            & + .inbox-content {
                .tables {
                    border-top: 0;
                }
            }
            .v2-dropdown__menu {
                .items {
                    .tooltiptext.right {
                        left: calc(100% + 8px);
                        top: 50%;
                        @include transform(translateY(-50%));
                    }
                }
            }
        }
    }
    .btn-selector {
        &.items {
            padding-left: 4px;
        }
        .d-flex,
        .d-flex svg {
            margin-right: 4px;
        }
    }
    .tables:not(.table-fixed) {
        @include flexcolumns;
        border-top: 1px solid $border-color-grey;
        height: 100%;
        .tables__content {
            min-width: fit-content;
            .rows {
                &-content {
                    padding: 6px 16px;
                }
                .email-title:not(.--w-full) {
                    max-width: 60%;
                }
            }
        }
        .rows {
            border: solid 1px $transparent;
            border-bottom: 1px solid $border-color-grey;
            &.active {
                @include border($yellow-default, 0px, 'null');
                box-shadow: 0 0 11px 0 rgba(0, 0, 0, 0.14);
                .hide-boxs {
                    display: none;
                }
                .show-boxs {
                    display: block;
                    .col-content {
                        min-width: unset;
                    }
                }
                &:not(.sms-content) {
                    .rows-content:not(.hide-boxs) {
                        > .col-from {
                            min-width: 365px;
                        }
                    }
                }
                .l-detail {
                    .col-content {
                        width: 310px;
                        margin-right: 10px;
                    }
                }
            }
            .show-boxs {
                display: none;
                .col-action {
                    .v2-btn-default {
                        &.--purple {
                            border-color: $violet-name;
                        }
                    }
                    .tooltip.--purple .tooltiptext {
                        max-width: 185px;
                    }
                }
            }
            &:hover {
                .email-hover {
                    visibility: visible;
                }
            }
        }
        .col-from {
            min-width: 360px;
            max-width: 400px;
            .name {
                margin-left: 0px;
            }
            .valid-name {
                width: unset;
                max-width: calc(100% - 120px);
                &__title {
                    max-width: unset;
                    flex: 1;
                }
                .label-content {
                    max-width: 100px;
                }
                .name {
                    max-width: unset;
                    flex: 1;
                }
            }
            &:has(.company-name:empty) {
                .valid-name {
                    max-width: calc(100% - 24px);
                    .label-content {
                        max-width: 150px;
                    }
                }
            }
        }
        .col-checkbox {
            width: 32px;
            flex: 0 0 auto;
            .check-items,
            .item-checkbox {
                height: 100%;
            }
            .item-checkbox {
                @include flexcenter;
                label {
                    @include size(16px);
                    padding-left: 0px;
                }
            }
        }
        .col-type {
            width: 36px;
            flex: 0 0 auto;
            .type-icon {
                @include size(26px);
                @include centeritem;
                border-radius: 16px;
                cursor: pointer;
                &.--bg-blue {
                    background-color: $royal-blue;
                }
                &.--bg-green {
                    background-color: $green-default;
                }
                &.--bg-orange {
                    background-color: $orange-peel;
                }
                & > svg {
                    @include size(22px);
                }
            }
        }
        .col-email-hover {
            width: 35px;
            min-width: 35px;
            padding-left: 5px;
            justify-content: flex-end;
            .email-hover {
                @include centeritem;
                @include size(25px);
                @include border($grey-soft, 3px, 'null');
                visibility: hidden;
                &:hover {
                    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.05%);
                }
                @media only screen and (max-device-width: 1024px) {
                    visibility: visible;
                }
            }
        }
        .col-date {
            width: 168px;
            padding-left: 30px;
            position: relative;
            & > svg {
                position: absolute;
                left: 4px;
            }
            .date-time {
                @include text-ellipsis;
                flex: 1;
            }
            &.tooltip {
                .tooltiptext {
                    bottom: calc(100% + 2px);
                    right: 0px;
                    left: auto;
                    @include transform(none);
                }
            }
        }
        .col-content {
            width: 600px;
        }
    }
    .l-detail {
        .rows-content {
            &.--task {
                .col-from {
                    flex-wrap: wrap;
                    padding-top: 8px;
                    > .v2-btn-default {
                        margin-right: 8px;
                    }
                    > * {
                        margin-bottom: 5px;
                    }
                }
            }
        }
        .col-action {
            column-gap: 8px;
            min-width: max-content;
        }
        .--task {
            align-items: flex-start;
            cursor: default;
            padding: 10px 16px 10px 0;
            > div {
                padding-top: 6px;
                padding-bottom: 6px;
            }
            .col-content {
                overflow: visible;
                padding-left: 0px;
            }
            .--clock {
                padding: 4px 10px;
                .calendar-date {
                    margin-top: 0;
                    border-top: 0;
                }
            }
        }
        .col-content {
            .content {
                width: calc(100% - 10px);
            }
            .rows {
                border-bottom: none;
                & + .rows {
                    margin-top: 16px;
                }
            }
        }
        .col-action .v2-dropdown__menu.--clock,
        .v2-dropdown__menu.--remind {
            left: auto;
            @include transform(none);
        }
        .col-action .v2-dropdown__menu.--clock {
            right: 0px;
        }
        .preloader-small {
            top: 23px;
        }
    }
    .l-detail-email {
        &__title {
            .v2-dropdown {
                .--clock {
                    left: auto;
                    right: 0;
                    @include transform(none);
                }
            }
        }
        .wrap-email-action__btn {
            .v2-btn-default {
                &.--transparent {
                    .tooltiptext {
                        margin-left: 20px;
                    }
                }
            }
        }
    }
    .tasks-list {
        &.has-edit {
            .--task-editing {
                display: flex;
            }
            .--task-completed {
                display: none;
            }
        }
        .--task-editing {
            display: none;
            flex-wrap: wrap;
            .col {
                width: calc(33% - 5px);
            }
        }
        .--task {
            > div {
                padding-top: 16px;
                padding-bottom: 16px;
            }
            .col-checkbox {
                margin-left: 20px;
                min-height: 32px;
                box-sizing: content-box;
            }
            .col-action {
                margin-right: 16px;
            }
        }
        &:not(.--in-details) {
            .box-task {
                padding: 0px;
                width: 100%;
                box-shadow: none;
                .title {
                    .avt-img {
                        @include size(32px);
                    }
                    > .v2-btn-default {
                        margin-right: 12px;
                    }
                }
                .name {
                    margin: 0px;
                    max-width: 100%;
                    font-size: 14px;
                    color: $grey-extradark;
                }
                .time {
                    font-size: 13px;
                    line-height: 16px;
                }
            }
        }
        .rows-content {
            padding: 0px;
            &__action {
                width: 100%;
                padding: 16px;
                box-shadow: inset 0px 1px 0px $border-color-grey;
                justify-content: flex-end;
            }
        }
    }
    .c-inbox-email {
        .container-content-email {
            margin: 0 auto;
        }
        .email-remind {
            height: 24px;
            border: 1px solid $yellow-default;
            background: $yellow-light;
            border-radius: 30px;
            padding: 2px 6px;
            @include flexcenter;
            gap: 4px;
            margin-right: 8px;
            svg {
                width: 18px;
                height: 18px;
            }
        }
        .l-detail {
            .v2-dropdown {
                &__menu {
                    &.--clock:not(.select-remind) {
                        left: auto;
                        right: 0;
                        @include transform(none);
                    }
                }
            }
            .box-task__list-tags,
            .information__label {
                min-width: 100%;
                max-width: 10vw;
            }
        }
        .box-follow-up {
            .--snooze-ic {
                .tooltiptext {
                    right: 0;
                    left: initial;
                    top: initial;
                    transform: none;
                }
            }
        }
        .col-content {
            min-width: 350px;
        }
    }
    .wrapper-calls {
        max-width: 100%;
        min-width: 100%;
        width: fit-content;
        .calls-content {
            position: relative;
            &:not(.is-open) .calls-preview {
                display: none;
            }
            &.is-open {
                background: rgba(255, 187, 0, 0.06);
                .check-items {
                    position: absolute;
                    top: 50%;
                    @include transform(translateY(-50%));
                }
            }
            .dots::before {
                background-color: $black-darklight;
            }
            .col-from {
                margin-right: 35px;
                .dots {
                    column-gap: 6px;
                    &::before {
                        @include size(3px);
                    }
                }
                .v2-btn-default {
                    font-size: 13px;
                }
            }
            .name {
                white-space: normal;
            }
            .col-type {
                .type-icon {
                    svg {
                        @include size(20px);
                    }
                }
            }
            .col-content {
                & > span {
                    max-width: 300px;
                    @include text-ellipsis;
                }
            }
        }
        .calls-preview {
            &__inner {
                flex-wrap: wrap;
                padding: 0 16px 12px 84px;
            }
            .gap-button {
                gap: 8px 4px;
            }
            .v2-dropdown__menu {
                &.--clock {
                    left: auto;
                    right: 0;
                    transform: none;
                }
            }
            &__inner {
                .task-done {
                    .tooltiptext.top {
                        left: 35%;
                    }
                }
                .v2-dropdown__menu {
                    .tooltiptext.right {
                        right: calc(100% + 4px);
                        left: unset;
                    }
                }
            }
            .wrap-record {
                border-radius: 8px;
                background-color: $white;
                border: 1px solid $grey-verylight;
                &__body {
                    border-radius: 0 0 8px 8px;
                }
            }
        }
        .call-information {
            &__number {
                @include text-ellipsis;
                max-width: 130px;
            }
            .status-btn {
                border-radius: 2px;
            }
            & + .call-note {
                min-width: 50px;
                flex: 1;
            }
        }
        .call-note {
            @include text-line-clamp(3);
            white-space: pre-wrap;
            &:empty {
                min-width: unset;
            }
        }
        .sms-content {
            &:not(.active) .sms-preview {
                display: none;
            }
            &.active {
                .rows-content {
                    border-bottom: 1px solid $border-color-grey;
                }
            }
            .rows-content {
                .type-icon {
                    background: $purple-default;
                    svg {
                        @include size(14px);
                    }
                }
                .col-content {
                    position: relative;
                    overflow: unset;
                    .call-information {
                        min-width: unset;
                    }
                    .call-information + .txt-ellipsis {
                        margin-left: 20px;
                    }
                }
                .col-date {
                    .tooltiptext {
                        right: unset;
                    }
                }
            }
            .sms-preview {
                height: 500px;
                overflow: hidden;
                .header-title {
                    padding: 0 16px 0 48px;
                    .info {
                        width: unset;
                        max-width: 100%;
                        min-width: 380px;
                    }
                    .wrap-toggle-phone {
                        margin-left: 0;
                        padding: 10px 0;
                        .box-tags {
                            width: 100%;
                            flex-wrap: nowrap;
                            margin-right: 16px;
                        }
                        .tag-label {
                            max-width: 250px;
                        }
                        .dropdown-list-friends {
                            right: 0;
                            width: 380px;
                        }
                    }
                }
                .notification-dropdown {
                    height: 100% !important;
                    .conversation-details {
                        position: relative;
                    }
                    .message-contents {
                        .conts-conversation {
                            width: 600px;
                            margin: 0 auto;
                        }
                        .box-message-to {
                            .name {
                                margin-left: 0px;
                                max-width: 100%;
                            }
                        }
                    }
                    .box-message-chat {
                        position: sticky;
                        bottom: 0px;
                        width: 100%;
                        min-height: unset;
                        &.active {
                            .file-attachment {
                                max-width: 100%;
                            }
                            .wrap-show-message-chat {
                                min-height: 100px;
                            }
                        }
                        .title-message-chat {
                            .schedule-user {
                                max-width: 350px;
                            }
                            .v2-dropdown__menu {
                                left: 15px;
                                top: calc(100% - 8px);
                                max-height: 150px;
                                .items {
                                    .name {
                                        margin-left: 0;
                                        white-space: normal;
                                    }
                                }
                            }
                        }
                        .footer-action {
                            border-radius: 0;
                        }
                    }
                    .wrap-conversation-contents {
                        margin-bottom: 0;
                        @include flexcolumns;
                        justify-content: space-between;
                        height: 100%;
                    }
                }
                .box-drop-file {
                    height: calc(100% - 8px);
                    inset: 4px;
                    z-index: 105;
                }
                .footer-action {
                    box-shadow: 0 -1px 0 0 $border-color-grey !important;
                }
            }
            &.--unknown {
                .rows-content {
                    .type-icon {
                        background: $orange-default;
                    }
                }
                .call-information__number {
                    color: $orange-default;
                }
                .wrapper-message:not(.box-message-send) {
                    .description {
                        &:not(.loading) {
                            background-color: $orange-default !important;
                        }
                    }
                }
            }
        }
        .sms-content,
        .calls-content {
            .col-from {
                margin-right: 35px;
                .dots {
                    column-gap: 6px;
                }
                .v2-btn-default {
                    font-size: 13px;
                }
                .bg-shawdown + .dots::before {
                    background-color: $black-darklight;
                    transform: none;
                }
            }
            .col-content {
                .phone-to {
                    margin-left: 4px;
                    min-width: 185px;
                    & + .txt-ellipsis {
                        margin-left: 20px;
                    }
                    .status-btn {
                        font-size: 11px;
                        line-height: 20px;
                        height: 20px;
                        padding: 0 6px;
                        border-radius: 37px;
                    }
                }
                .col-attention {
                    position: absolute;
                    left: -16px;
                    top: 50%;
                    @include transform(translateY(-50%));
                }
            }
        }
        .calls-content,
        .sms-content {
            .btn-block:hover {
                border-color: $red-default !important;
                > svg path {
                    fill: $red-default;
                }
            }
        }
    }
    .wrap-tables {
        margin: 0 8px 0 0;
        border-radius: 4px 4px 0 0 !important;

        &.smart-view-table {
            margin-right: 16px;
            .header {
                &:first-child {
                    padding-left: 12px;
                }
                .btn-filter {
                    padding-left: 0;
                    &:hover {
                        border-color: $transparent !important;
                        box-shadow: none !important;
                    }
                    svg {
                        @include size(20px);
                        margin-right: 6px;
                    }
                    .budget {
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
                .is-divider {
                    margin: 0 8px;
                    width: 1px;
                    height: 16px;
                }
                .v2-dropdown {
                    &__menu {
                        max-height: unset;
                    }
                    &.is-sort {
                        > .dropbtn {
                            padding-left: 2px;
                            gap: 4px;
                            .sort-icon {
                                display: flex;
                            }
                            .txt-ellipsis {
                                margin-right: 0;
                            }
                        }
                    }
                    &:not(.is-sort) {
                        .v2-dropdown__menu {
                            width: 304px !important;
                            max-width: unset;
                        }
                    }
                }
                &:has(.smartview-column) {
                    z-index: 11;
                }
            }
            &.is-hide-filter {
                .wrapper__filter {
                    display: none;
                }

                .btn-filter {
                    svg {
                        @include transform(rotateZ(90deg));
                    }
                }
            }
            .wrapper__filter {
                &-tags {
                    border: 1px solid rgba($color: $blue-default, $alpha: 0.4);
                    background-color: rgba($color: $blue-default, $alpha: 0.1);
                    border-radius: 4px;
                    padding: 2px 2px 2px 4px;
                    height: 24px;
                    cursor: pointer;
                    > .black-darklight {
                        color: rgba(28, 31, 34, 0.7) !important;
                    }
                    .budget:empty,
                    .fw-600.ml-1:empty {
                        display: none;
                    }
                    > .gap-5 {
                        > * {
                            margin-left: 0 !important;
                        }
                    }
                    .budget {
                        &.--active {
                            background-color: $orange-ripe;
                            color: $black-jungle-green;
                        }
                        &.--lost {
                            background-color: $grey-sonic-silver;
                        }
                        &.--won {
                            background-color: $green-default;
                        }
                    }
                    .filter-tag {
                        border-radius: 4px;
                        background-color: $black-darker4;
                        padding: 0 4px;
                        font-size: 11px;
                        font-weight: 500;
                        margin-left: 4px;
                        @include text-ellipsis;
                        max-width: 300px;
                        &.--job-status {
                            background-color: var(--bg-job-status);
                            color: var(--color-job-status);
                        }   
                    }
                    &.--error {
                        border-color: $red-default;
                        background-color: $red-extralight;
                    }
                }
                .add-filter {
                    svg {
                        @include size(18px);
                    }
                }
                & + .tooltiptext {
                    flex-direction: column;
                    .tooltiptext-title {
                        font-size: 12px;
                        &::first-letter {
                            text-transform: uppercase;
                        }
                    }
                    & > *:not(.tooltiptext-title) {
                        font-weight: 400;
                    }
                }
            }
            .tables:not(.table-fixed) {
                z-index: 0;
                border-top: 0;
                .rows {
                    border: none;
                    &.--header {
                        min-height: 36px;
                        .has-orderby {
                            width: 100%;
                            .orderby-txt {
                                @include text-ellipsis;
                            }
                        }
                    }
                    .col {
                        &.col-sm,
                        &.col-md {
                            max-width: unset;
                        }
                    }
                }
                .tables-list {
                    .rows {
                        overflow: unset;
                        &:last-child {
                            box-shadow: inset 0 -1px 0 $border-color-grey;
                        }
                    }
                }
            }
            &:has(.content-empty) {
                .tables {
                    &-list {
                        position: sticky;
                        left: 0;
                    }
                }
            }
        }
        &:not(.smart-view-table) {
            > .header.--filter {
                > .header__content {
                    > .check-items {
                        line-height: 32px;
                        input[type='checkbox']:checked + .item-checkbox label::after {
                            top: -3px;
                        }
                        &.--uncheck {
                            input[type='checkbox']:checked + .item-checkbox label::after {
                                top: -1px;
                            }
                        }
                        label::before {
                            top: -8px;
                        }
                    }
                }
            }
        }
    }
    .wrap-called {
        @include flexcolumns;
        margin: 16px 16px 16px 0;
        overflow-y: auto;

        .relative {
            @include centeritem;
            flex-direction: column;
            gap: 35px;
            border: 1px solid $grey-soft;
            background-color: $white;
            position: relative;
            padding-bottom: calc(3vw + 3vh);
            @include size(100%);
            min-height: 640px;
            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                clip-path: polygon(0% 45px, 100% 0, 100% 100%, 0% 100%);
                height: 18%;
                background: linear-gradient(180deg, #f2f3f9 -30%, rgba(242, 243, 249, 0) 100%);
            }
        }
        .line {
            border-bottom: 1px solid $grey-soft;
            width: 100%;
        }
        .rows:not(.--icon) {
            max-width: 520px;
            @include flexcenter;
            flex-direction: column;
            gap: 24px;
            @include font-color(16px, 14px, $grey-storm);
            .title {
                @include font-color(1, 24px, $black-4);
                margin-bottom: -10px;
            }
            .desc {
                margin-top: -10px;
                @include flexcenter;
                gap: 4px;
                > span:first-child {
                    @extend .fw-600;
                }
            }
        }
    }
    .sidebar-left {
        padding: 16px 16px 0 0;
        font-size: 14px;
        .sidebar-items {
            line-height: 24px;
            border-radius: 4px;
            cursor: pointer;
            padding: 4px;
            width: 100%;
            .txt-ellipsis {
                font-weight: 500;
                color: $black;
            }
            &:hover,
            &:active,
            &.active {
                background: $yellow-light;
                box-shadow: $boxshadow-grey-softlight;
            }
            &.active {
                .txt-ellipsis {
                    @extend .fw-600;
                }
            }
            svg {
                flex: 0 0 auto;
            }
            & > *:first-child.txt-ellipsis {
                padding-left: 4px;
            }
        }
        > *:nth-child(n + 6) {
            margin-top: 4px;
        }
    }
    .title-header {
        height: 100% !important;
    }
    .activity-comparison {
        grid-area: maintable;

        .header {
            &.--filter {
                .v2-dropdown {
                    .scrolls {
                        .check-items {
                            input[type='checkbox']:checked + .item-checkbox label::after {
                                top: 8px;
                            }
                            label {
                                @include flexcenter;
                                &::before {
                                    top: 4px;
                                }
                            }
                            .txt-ellipsis {
                                white-space: nowrap;
                            }
                        }
                    }
                }
            }
        }
        .wrap-tables {
            position: relative;
        }
    }
    .sidebar {
        &.--smart-views {
            padding-top: 8px;
            gap: 8px;
            .sidebar-header {
                @include flexcenter;
                gap: 4px;
                position: relative;
                .flex-auto.absolute {
                    width: 100%;
                    top: 0;
                    left: 0;
                    .search-input {
                        input[type='text'] {
                            height: 34px;
                        }
                    }
                }
                .txt-smart-view {
                    line-height: 32px;
                    max-width: 103px;
                }
            }
            .sidebar-list {
                color: $black-verylight-green;
                .v2-btn-default {
                    &.--drag {
                        cursor: grab;
                        &:hover,
                        &:active {
                            border-color: transparent !important;
                            box-shadow: none !important;
                        }
                    }
                }
                .sidebar-items {
                    margin-bottom: 4px;
                    &:not(:hover) {
                        .v2-btn-default:not(.--drag) {
                            display: none !important;
                        }
                    }
                    & + .v2-btn-default {
                        min-height: 32px;
                    }
                    &.has-drag {
                        border: 0.5px dashed $black-2 !important;
                        background: $black-verydark !important;
                        opacity: 0.5;
                        &[draggable] * {
                            opacity: 1;
                            user-select: none;
                        }
                    }
                    > .txt-ellipsis {
                        white-space: pre !important;
                    }
                }
                > .v2-btn-default {
                    min-height: 32px;
                }
            }
        }
    }
    .container-wrap {
        .header {
            &.--smart-views {
                padding-right: 16px;
                .btn-limit {
                    .btn-limit__option {
                        background: rgba($color: $purple-default, $alpha: 0.06);
                        border: solid 1px rgba($color: $purple-default, $alpha: 0.5);
                        color: $purple-default;
                        &:hover {
                            text-decoration: none;
                        }
                    }
                    .field-input {
                        width: 75px;
                        height: 24px;
                        line-height: 24px;
                        &::placeholder {
                            color: $black-dark-jungle1;
                        }
                    }
                    > .txt-ellipsis {
                        color: $black-darklight1;
                    }
                }
                .dots::before {
                    background-color: $grey-soft;
                    margin-top: 4px;
                }
                .header-items {
                    > .v2-btn-main {
                        border: 1px solid transparent;
                    }
                    .wrap-button__right .dropbtn {
                        border-color: $black-dark-jungle;
                    }
                }
            }
        }
    }
    .alert.--smart-views {
        margin: 16px 0 0;
    }
    &:has(.call-bar):not(:has(.is-disable-calls)) {
        .v2-dropdown {
            .drop-container {
                max-height: calc(100dvh - 520px);
                @media (max-height: 700px) {
                    max-height: 250px;
                }
            }
        }
    }
    .opportunity-container,
    .status-changes,
    .sales-funnel {
        grid-area: maintable;
    }

    // Opportunity
    &:has(.opportunity-container) {
        .sidebar-container,
        .sidebar-left,
        .nav-top {
            background-color: $white-anti-flash;
        }
        .opportunity-container {
            z-index: 2;
        }
        .sidebar-left {
            z-index: 3;
        }
        .sidebar-container,
        .nav-top {
            z-index: 4;
        }
        .header-v2 {
            z-index: 5;
        }
        .sidebar-container {
            &:not(.active) {
                display: none;
            }
        }
    }
    .opportunity-container {
        @include flextop;
        gap: 16px;
        overflow-y: auto;
        .opportunity-column {
            max-width: 260px;
            min-width: 260px;
            height: 100%;
            flex: 1 0 25%;
            @include flexcolumns;
            &:last-child {
                margin-right: 16px;
            }
            &__header {
                background-color: $white;
                border-radius: 4px 4px 0 0;
                z-index: 3;
                box-shadow: 0px 2px 4px 0px $black-darker4, 0px 1px 2px 0px $black-light, 0px 2px 3px 0px $black-light,
                    0px 4px 6px 0px $black-verydark;
                .header-status {
                    padding: 8px;
                    gap: 5px;
                }
                .header-info {
                    padding: 4px 8px;
                    .items {
                        padding: 4px 0;
                        font-size: 12px;
                        color: light-dark(#7a83a6, #a29a8e);
                        &:not(.items:first-child) {
                            border-top: 1px solid $border-color-grey;
                        }
                        &.is-disable {
                            opacity: 1;
                            & > * {
                                opacity: 0.6;
                            }
                        }
                        .txt-ellipsis {
                            max-width: 140px;
                        }
                    }
                }
            }
            &__list {
                padding: 7px;
                @include flexcolumns;
                background-color: $grey-platinum-dark;
                gap: 6px;
                flex: 1;
                &.is-drag {
                    & > * {
                        display: none !important;
                    }
                    .box-drop-file {
                        display: block !important;
                    }
                }
                overflow-y: clip;
                overflow-x: hidden;
                &:hover {
                    overflow-y: auto !important;
                }
            }
            &__item {
                background-color: $white;
                border-radius: 4px;
                border: 1px solid $transparent;
                cursor: grab;
                .item-title {
                    padding: 8px 6px 8px 12px;
                    border-bottom: 1px solid $border-color-grey;
                    gap: 5px;
                    .v2-dropdown__menu {
                        min-width: unset;
                    }
                }
                .item-info {
                    padding: 8px;
                    cursor: pointer;
                    .title {
                        @include flexcenter;
                        gap: 5px;
                        .avt-img,
                        .avt {
                            @include size(32px);
                            &.tooltip {
                                .tooltiptext {
                                    left: -12px;
                                    transform: none;
                                    max-width: 240px;
                                }
                            }
                        }
                        .avt {
                            font-size: 13px;
                        }
                        &__info {
                            width: calc(100% - 36px);
                            .time {
                                width: fit-content;
                                .tooltiptext {
                                    max-width: 240px;
                                    left: -48px;
                                    transform: none;
                                }
                            }
                        }
                    }
                    .contact {
                        .tooltiptext {
                            right: 0;
                            left: unset;
                            transform: none;
                        }
                        .tooltip {
                            display: flex;
                            &:nth-child(1) {
                                .tooltiptext {
                                    right: -56px !important;
                                }
                            }
                            &:nth-child(2) {
                                .tooltiptext {
                                    right: -28px !important;
                                }
                            }
                        }
                    }
                    .contact,
                    .description {
                        margin-top: 8px;
                        padding-top: 6px;
                        border-top: 1px dashed $black-darker5;
                    }
                    .description {
                        color: $black-darklight1;
                        word-wrap: break-word;
                        white-space: pre-wrap;
                    }
                }
            }
            .box-drop-file {
                position: unset;
                @include size(100%);
                .title {
                    font-size: 16px;
                    margin: 0 0 16px;
                }
                .description {
                    max-width: unset;
                    @include text-ellipsis;
                    width: 95%;
                }
            }
            &.--won {
                .status-btn {
                    background-color: $green-default;
                }
            }
            &.--active {
                .status-btn {
                    background-color: $orange-ripe;
                    color: $black-default;
                }
            }
            &.--lost {
                .status-btn {
                    background-color: $grey-sonic-silver;
                }
            }
        }
    }
    // Sales funnel
    .sales-funnel {
        .wrap-tables {
            .rows {
                align-items: center;
                border-top: 0;
                border-bottom: 0;
                &.--fixed {
                    background-color: $white-anti-flash;
                    z-index: 1;
                }
                & > .col {
                    @media (min-width: 1400px) {
                        &:not(.col-xl):not(.col-lg) {
                            min-width: 100px !important;
                            max-width: 180px !important;
                        }
                    }
                    &.col-lg {
                        max-width: 180px;
                        &:has(svg) {
                            padding: 5px 0;
                            svg {
                                margin-left: -5px;
                            }
                        }
                    }
                    .status-btn {
                        width: max-content;
                        max-width: 100%;
                        &.--won {
                            background-color: $green-default;
                        }
                        &.--active {
                            background-color: $orange-ripe;
                            color: $black-default;
                        }
                        &.--lost {
                            background-color: $grey-sonic-silver;
                        }
                    }
                    &.col-count,
                    &.col-value,
                    &.col-weighted {
                        padding-left: 5px;
                    }
                    @media (max-width: 1180px) {
                        &.col-xs {
                            max-width: 90px !important;
                        }
                        &.col-md {
                            max-width: 130px !important;
                        }
                    }
                }
                &:last-child {
                    border-bottom: 1px solid $border-color-grey;
                }
            }
            .tables {
                &.--count .col-count,
                &.--value .col-value,
                &.--weighted .col-weighted {
                    background-color: rgba($color: $blue-default, $alpha: 0.1);
                }
                .tables-list {
                    &:has(.rows:nth-of-type(6)) {
                        .rows:last-child {
                            border-bottom: 0;
                        }
                    }
                }
            }
        }
        .contents-pages {
            min-width: 1005px !important;
            .wrap-border {
                height: 490px;
            }
        }
    }
    // Status change
    &:has(.status-changes) {
        .nav-top,
        .status-changes__container {
            max-width: 850px;
            width: 100%;
        }
    }
    .status-changes {
        @include flexcolumns;
        &__container {
            @include flexcolumns;
            gap: 8px;
            margin-bottom: 24px;
            .tables-list {
                .rows {
                    align-items: center;
                    cursor: pointer;
                    .col {
                        &:not(.col-net) {
                            .tooltip {
                                width: fit-content;
                                max-width: 100%;
                            }
                        }
                        &.--label-r {
                            .col-label {
                                @include flexend;
                            }
                        }
                        &.col-net {
                            color: $green-crayola;
                            svg {
                                width: 10px;
                                height: 20px;
                                margin-top: -2px;
                            }
                            & > .txt-ellipsis {
                                width: 45px;
                                .tooltip {
                                    width: fit-content;
                                }
                            }
                            &.--reduce {
                                color: $red-default;
                                svg {
                                    @include transform(rotateZ(180deg));
                                    margin-top: 3px;
                                    margin-right: 1px;
                                    path {
                                        fill: $red-default;
                                    }
                                }
                            }
                            .net-percent {
                                max-width: calc(100% - 51px);
                            }
                        }
                        &.col-lg {
                            svg {
                                margin-left: -4px;
                            }
                        }
                    }
                    &:has(.col-chart) {
                        display: none;
                    }
                    &.is-chart {
                        .col {
                            &:first-child {
                                svg {
                                    @include transform(rotateZ(90deg));
                                }
                            }
                        }
                        & + .rows:has(.col-chart) {
                            display: block;
                        }
                    }
                    .col-chart {
                        padding: 10px;
                        .mermaid {
                            text-align: center;
                            .edgeLabel,
                            .nodeLabel {
                                font-family: 'Inter', sans-serif !important;
                                font-size: 13px;
                            }
                            .edgeLabel {
                                color: $black !important;
                                background-color: $transparent !important;
                                foreignObject {
                                    @include transform(translate(-2px, -16px));
                                    width: 22px;
                                    .edgeLabel {
                                        font-size: 12px;
                                    }
                                }
                            }
                            .nodeLabel {
                                @include text-ellipsis;
                                display: inline-block;
                                max-width: 220px;
                                @extend .fw-600;
                                color: $black-default !important;
                            }
                            .flowchart-label:not(.activeNodeStyle) {
                                .label-container {
                                    fill: $white-dark !important;
                                    stroke: rgba(0, 0, 0, 0.12) !important;
                                }
                            }
                            .activeNodeStyle {
                                .label-container {
                                    fill: #e2f4fe !important;
                                    stroke: rgba(0, 0, 0, 0.12) !important;
                                }
                            }
                            .edge-thickness-normal {
                                stroke-width: 1px !important;
                                stroke: $black !important;
                            }
                        }
                    }
                }
            }
        }
        &__title {
            @include betweenitems;
            margin-right: 8px;
            .title-desc {
                color: $black-darklight1;
                margin-top: 2px;
            }
            .has-img-user {
                .v2-dropdown__menu {
                    z-index: 2;
                }
            }
        }
        &.container-print {
            .wrap-tables {
                .tables {
                    .rows {
                        border: 0;
                    }
                    .col {
                        &.col-net {
                            min-width: 130px !important;
                            max-width: 130px !important;
                        }
                        &.col-sm {
                            flex-basis: 100px;
                            @media (max-width: 1180px) {
                                min-width: 85px !important;
                                max-width: 85px !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .status-changes,
    .sales-funnel {
        .wrap-tables {
            .rows {
                &.--fixed {
                    background-color: $white-anti-flash;
                }
            }
        }
    }

    .modal-outbound {
        @include flexbottom;
        position: absolute;
        .modal__container {
            width: 100%;
            height: 75%;
            @include transform(none);
            top: auto;
            border-radius: 8px 8px 0px 0px;
        }
        .tables-list {
            flex: 1;
            .rows {
                overflow: unset;
            }
            &:has(.content-empty) {
                position: sticky;
            }
        }
    }

    @media (max-width: 768px) {
        .rows-content {
            &.--task:not(.--task-editing) {
                flex-wrap: wrap;
                position: relative;
                .col-checkbox {
                    order: 1;
                }
                .col-type {
                    order: 2;
                }
                .col-from {
                    order: 3;
                }
                .col-action {
                    order: 4;
                    position: absolute;
                    right: 24px;
                }
                .col-content {
                    order: 5;
                    padding: 0px 20px 20px;
                    min-width: 100%;
                }
            }
        }
        .l-detail .col-action {
            > .v2-btn-default + .v2-btn-default {
                margin-left: 5px;
            }
        }
    }
    @media screen and (max-width: 1024px) {
        overflow-x: auto;
        width: 1024px !important;
        .container-wrap {
            max-width: 1024px;
        }
    }
}
.grid-right-side {
    @include grid(auto auto 1fr, 220px 1fr, 0);
    grid-template-areas:
        'bookmark bookmark'
        'leftside navtop'
        'leftside maintable';
    @media (max-width: 1180px) {
        grid-template-columns: 210px 1fr;
    }
    .wrap-called,
    .wrap-tables {
        grid-area: maintable;
    }
    .nav-top {
        grid-area: navtop;
    }
    .bookmark {
        grid-area: navtop;
    }
    .sidebar-left {
        grid-area: leftside;
    }
    .alert.--smart-views {
        grid-area: error;
    }
    &:has(> .alert.--smart-views) {
        grid-template-rows: auto auto auto 1fr;
        grid-template-areas:
            'bookmark bookmark'
            'leftside error'
            'leftside navtop'
            'leftside maintable';
    }
}
