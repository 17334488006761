.ReactModalPortal,
.ReactModal__Overlay--after-open {
    z-index: 100000 !important;
}
.ReactModalPortal_customer,
.ReactModal__Overlay--after-open {
    z-index: 100001 !important;
}
.ReactModalPortal_alert_customer,
.ReactModal__Overlay--after-open {
    z-index: 100002 !important;
}
.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99999;
    @include size(100%);
    opacity: 0;
    visibility: hidden;
    transition: visibility 0s linear 0.1s, opacity 0.1s ease;
    &.open {
        &.is-priority {
            z-index: 1000001;
        }
        visibility: visible;
        opacity: 1;
        transition-delay: 0s;
        z-index: 100000;
    }
    &__overlay {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 9;
        background-color: $bg-overlay;
    }
    &__container {
        position: relative;
        z-index: 22;
        top: 50%;
        @include transform(translateY(-50%));
        margin: 0 auto;
        background-color: $white;
        box-shadow: 0 3px 8px 0 $black-light, 0 2px 6px 0 $black-light, 0 0 8px 2px $black-extradark;
        width: 392px;
        height: auto;
        max-height: 90dvh;
        border-radius: 3px;
        @include flexcolumns;
        &.x-lg {
            width: 75%;
            max-width: 650px;
        }
        &.large {
            width: 496px;
        }
        &.--sm {
            width: 348px;
        }
        &.--md {
            width: 380px;
            max-width: 380px;
        }
        &.--lg {
            width: 415px;
        }
        &.--preview-r {
            width: 680px;
            max-width: 680px;
            height: 100%;
            max-height: 100%;
            border-radius: 0;
            top: 0;
            right: 0;
            margin: 0;
            margin-left: auto;
            @include transform(none);
            .body-modal {
                max-height: 100%;
            }
            > .header-modal {
                > span {
                    @include text-ellipsis;
                    margin-right: 20px;
                }
            }
            .--header-info {
                border-bottom: none;
                padding-top: 10px;
                padding-bottom: 10px;
            }
            &.has-existing-notes {
                max-width: 700px;
            }
        }
        &.--xl-plus {
            width: 570px;
            max-width: 570px;
        }
        &:not(.service-plan-container) {
            .detail-plan,
            .--service-plan {
                display: none;
            }
        }
        &.sidebar-left-content {
            width: 295px !important;
        }
    }
    &:not(.c-job-details):not(.--add-number):not(.modal--mdu):not(.modal-addcustomer):not(.modal-chatbot) {
        .body-modal .alert {
            max-width: 100%;
            margin-top: 0px;
        }
    }
    .header-modal {
        justify-content: space-between;
        > span:first-child,
        &__label {
            @include font-color(24px, 18px, $black-2);
            @extend .fw-600;
            @include text-ellipsis;
            flex: 1;
        }
        &.--breakline {
            height: auto;
            padding: 20px 24px;
            .header-modal__label {
                white-space: normal;
                word-break: break-word;
            }
        }
        .v2-btn-main {
            height: 32px;
            margin: 0;
        }
        .line {
            height: 16px;
            width: 1px;
            margin: 0px 16px;
            background: linear-gradient(0deg, $grey-very-light, $grey-very-light), $grey-softlight;
        }
    }
    .header-modal,
    .footer-modal {
        width: 100%;
        height: 64px;
        padding: 0px 23px;
        flex: 0 0 auto;
        @include flexcenter;
    }
    .footer-modal {
        justify-content: flex-end;
        .v2-btn-main {
            margin-left: 8px;
        }
        > span.v2-btn-default {
            margin-left: -10px;
        }
    }
    .btn-close span {
        position: relative;
        cursor: pointer;
        color: $black-3;
        font-weight: 500;
    }
    .body-modal {
        border-top: solid 1px $border-color-grey;
        border-bottom: solid 1px $border-color-grey;
        padding: 20px 23px;
        font-size: 13px;
        color: $black-3;
        max-height: calc(90dvh - 128px);
        .row {
            margin: 0px;
            .txt {
                margin-bottom: 5px;
                display: inline-block;
                font-weight: 500;
            }
            & + .row {
                margin-top: 20px;
            }
            .field-input {
                padding: 0px 10px;
            }
        }
        .row-multi {
            @include betweenitems;
        }
        .rows {
            .has-many-address {
                @include flexwrap;
                .field-input:not(:hover):not(:active) {
                    &.field-street {
                        border-bottom-color: $transparent;
                    }
                    &.--first {
                        border-radius: 4px 4px 0px 0px;
                    }
                    &.--second,
                    &.field-state {
                        border-radius: 0px;
                    }
                    &.field-city {
                        border-radius: 0px 0px 0px 4px;
                    }
                    &.field-zip {
                        border-radius: 0px 0px 4px 0px;
                    }
                    &.field-state {
                        border-left-color: $transparent;
                        border-right-color: $transparent;
                    }
                }
                .field-city {
                    width: calc(100% - 130px);
                }
                .field-state {
                    width: 50px;
                }
                .field-zip {
                    width: 80px;
                }
            }
        }
        &.--assign {
            padding: 0;
            .row {
                @include flexcenter;
                flex-wrap: wrap;
                padding: 20px;
                &:not(:last-child) {
                    border-bottom: 1px solid $border-color-grey;
                }
                & + .row {
                    margin-top: 0;
                }
            }
            .title {
                width: 100%;
                padding: 16px 20px 0px;
                font-weight: 500;
                text-transform: uppercase;
                @include font-color(2.08, 12px, $grey-extrlight);
            }
            .check-items {
                max-width: 100%;
            }
            .check-radio {
                display: inline-flex;
                width: 100%;
                label {
                    @include text-ellipsis;
                    margin: 0px;
                    line-height: 18px;
                }
            }
        }
        &.--reviews {
            padding: 15px 23px;
            @include font-color(1.43, 14px, $black-2);
            .description {
                p {
                    margin-bottom: 0;
                }
            }
            .link-review {
                margin-top: 17px;
                margin-bottom: 5px;
                color: $black-3;
                display: block;
            }
            .field-input {
                border-color: $grey-light-2;
                &::placeholder {
                    color: $grey-extrlight;
                }
            }
            .row-multi {
                align-items: flex-start;
            }
        }
        &.--email {
            padding: 0;
            .row {
                @include flexcenter;
                flex-wrap: wrap;
                padding: 16px 20px;
                &:not(:last-child) {
                    border-bottom: 1px solid $border-color-grey;
                }
                & + .row {
                    margin-top: 0;
                }
                &:first-child {
                    padding-top: 8px;
                    padding-bottom: 13px;
                }
                .title,
                .description {
                    @include font-color(1.43, 14px, $black-2);
                }
                .title {
                    @extend .fw-600;
                }
                .description {
                    padding-left: 24px;
                    margin-top: 5px;
                }
                .check-items {
                    max-width: 100%;
                    .item-checkbox {
                        @include flexcenter;
                        height: 24px;
                        & > label {
                            @include text-ellipsis;
                        }
                    }
                }
                .has-tag {
                    label {
                        @include flexcenter;
                        &::before {
                            top: 4px;
                        }
                    }
                    input[type='checkbox'] {
                        &:checked + .item-checkbox {
                            label::after {
                                top: 8px;
                            }
                        }
                    }
                }
                .status-btn {
                    &.upgrade-pro {
                        border-radius: 8px;
                        padding: 0px 8px;
                    }
                }
            }
        }
    }
    //modal add email page addons
    &.--add-email {
        .body-modal {
            padding: 0px;
            @include flexcolumns;
            &:not(.is-overflow) {
                overflow: auto;
            }
        }
    }
    &.--edit-smtp,
    &.--add-email {
        .modal__container {
            width: 683px;
            &.--sm {
                width: 348px;
            }
        }
        .body-modal {
            border: 0;
            @include font-color(20px, 14px, $grey-dark);
            .txt {
                font-weight: 400;
                margin-bottom: 3px;
            }
            .alert {
                width: 90%;
            }
        }
        .header-modal {
            &__label {
                .txt-ellipsis {
                    max-width: calc(50% - 22px);
                }
            }
        }
        .btn-back {
            margin: 0 8px 0 -5px;
        }
        .step {
            display: none;
            padding: 0px 20px 16px;
            &.show {
                display: block;
            }
        }
        .check-items {
            max-width: 100%;
            .item-checkbox {
                @include flexcenter;
                & > label {
                    @include text-ellipsis;
                }
            }
        }
        .is-disable {
            &.check-items {
                input[type='checkbox']:checked {
                    & + .item-checkbox {
                        label::before {
                            background-color: $grey-soft;
                            border-color: $grey-soft;
                        }
                    }
                }
            }
        }
        .row {
            &.--email-address {
                background-color: $btn-disable;
                padding: 8px;
                border-radius: 4px;
                .txt {
                    display: block;
                }
                .label-email,
                .change-email {
                    display: inline-block;
                }
                .label-email {
                    @extend .fw-600;
                    word-break: break-word;
                }
                .change-email {
                    color: $purple-default;
                    font-weight: 500;
                    margin-left: 4px;
                    cursor: pointer;
                }
            }
            &.--recommended {
                .txt {
                    margin-bottom: 6px;
                    color: $orange-default;
                }
                .check-items {
                    margin-bottom: 2px;
                }
                .fs-12 {
                    margin-left: 24px;
                }
            }
            &.--multi {
                @include betweentop;
                position: relative;
                .col {
                    width: calc(50% - 16px);
                    &.--lg {
                        width: calc(50% - 4px);
                    }
                    .v2-btn-default,
                    .fs-12 {
                        margin-top: 9px;
                    }
                }
                &.--hasline::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 50%;
                    width: 1px;
                    height: 100%;
                    @include transform(translateX(-50%));
                    background-color: $grey-softlight;
                }
            }
            &.outgoing-email {
                .txt,
                .check-items {
                    margin-top: 16px;
                }
                .title {
                    font-size: 14px;
                    @extend .fw-600;
                    color: $grey-extradark;
                }
                .col {
                    background-color: $btn-disable;
                    padding: 16px;
                    border-radius: 4px;
                }
            }
        }
        .btn-signin {
            @include flexcenter;
            cursor: pointer;
            border-radius: 1px;
            max-width: 230px;
            margin-top: 16px;
            &.is-google {
                color: $white;
                background-color: #4285f4;
                border: solid 1px $transparent;
            }
            &.is-ms {
                border: solid 1px $border-color-grey;
            }
            &:hover {
                box-shadow: $boxshadow-dark-gray;
            }
            .btn-icon {
                @include size(38px);
                background-color: $white;
            }
            .btn-label {
                line-height: 38px;
                font-weight: 500;
                text-align: center;
            }
        }
    }
    &.--edit-address {
        .body-modal {
            padding: 10px 24px 24px;
            .v2-dropdown__menu {
                @media (max-height: 800px) {
                    max-height: 220px;
                }
            }
        }
    }
    &.--new-job {
        &:not(.--new-payment) {
            .body-modal {
                .customer-info {
                    .txt {
                        margin-bottom: 0;
                        &.--large {
                            width: 125px;
                            flex: 0 0 auto;
                        }
                    }
                    .sm-row {
                        .detail {
                            flex: 1;
                            max-width: calc(100% - 125px);
                        }
                    }
                    .select-info {
                        width: 100%;
                    }
                }
            }
            > .modal__container {
                min-height: 90dvh;
            }
            .show-addpayment {
                .items-payment {
                    padding-left: 0px !important;
                    padding-right: 0px !important;
                }
            }
            .react-datepicker__tab-loop {
                position: relative;
            }
        }
        > .modal__container {
            width: 670px;
            > .body-modal {
                flex: 1;
                padding-left: 0px;
                padding-right: 0px;
                padding-bottom: 0px;
                border-bottom: 0px;
                &:not(.show-repeat-inv) {
                    .form-edit-template {
                        .--header-info {
                            border-bottom: 0px;
                        }
                    }
                }
                &.show-repeat-inv,
                &.show-addpayment {
                    padding-top: 5px;
                }
            }
            > .footer-modal {
                border-top: solid 1px $border-color-grey;
            }
        }
        .show-addpayment {
            .content-elm-edit {
                display: none;
            }
        }
        .form-invoice-repeat {
            position: fixed;
            box-shadow: 0px 4px 6px $black-verydark, 0px 2px 3px $black-light, 0px 1px 2px $black-light,
                0px 0px 1px $black-extradark;
            height: calc(90dvh - 65px) !important;
            width: 670px;
            margin-top: -5px;
            border-radius: 0 0 4px 4px;
            z-index: 1;
            .content-form {
                overflow-y: auto;
            }
            .footer-form {
                position: static;
            }
        }
        .form-add-estimate {
            .items-package__header {
                .field-input {
                    width: 230px;
                }
            }
        }
        &:has(.c-job-details) {
            @include centeritem;
            > .modal__container {
                top: unset;
                transform: none;
                @media (max-width: 700px) {
                    width: 95%;
                    .body-modal {
                        > .container-column {
                            min-width: 640px;
                        }
                    }
                    .form-invoice-repeat {
                        width: 95%;
                        & > .content-form {
                            & > * {
                                min-width: 550px;
                            }
                        }
                    }
                }
            }
        }
    }
    &.--new-payment {
        > .modal__container {
            > .body-modal {
                border-top: 0;
                padding: 0;
                overflow-y: overlay;
            }
        }
        .body-modal {
            .form-add-payment {
                display: block;
                text-align: left;
            }
            .v2-dropup {
                .v2-dropdown__menu {
                    max-height: 225px;
                }
            }
            .customer-info-number {
                .sm-row {
                    &.has-icon {
                        .txt {
                            padding-left: 30px;
                        }
                    }
                    .txt {
                        margin-right: 4px;
                    }
                }
            }
            .v2-dropdown {
                &.select-info {
                    .dropbtn {
                        width: 195px;
                    }
                }
                &.select-method,
                &.select-credit-card {
                    .v2-dropdown__menu {
                        max-height: 175px;
                    }
                }
            }
        }
    }
    &.--managestripe {
        .rows {
            &__note {
                margin-top: 7px;
            }
            &.--multi {
                @include betweentop;
            }
            .col {
                width: calc(50% - 8px);
                .v2-dropdown__menu {
                    max-height: 220px;
                }
            }
        }
    }
    &.--contains {
        z-index: 100001;
        > .bg-fixed {
            background-color: $transparent;
        }
    }
    &.--credit {
        .body-modal {
            border-top: none;
            padding-top: 0px;
        }
        .rows {
            &:not(.--customer-name) {
                @include betweentop;
            }
            &.--customer-name {
                .field-input {
                    line-height: 30px;
                    pointer-events: none;
                    background-color: $bg-body;
                    border-color: $border-color-grey;
                    @include text-ellipsis;
                }
            }
        }
        .width-xs {
            width: 48%;
        }
        .width-sm {
            width: 50%;
            &.is-onefield {
                width: 48%;
                .method-select {
                    border-radius: 4px;
                    border-right: solid 1px $grey-soft;
                }
            }
        }
        .method-select {
            &:not(:hover) {
                border-radius: 4px 0px 0px 4px;
            }
            &:hover {
                border-color: $btn-hover-black;
            }
        }
        .memo-input {
            border-top-left-radius: 0 !important;
            border-bottom-left-radius: 0 !important;
        }
        .dropbtn {
            font-size: 13px;
            font-weight: normal;
        }
        .v2-dropdown__menu {
            max-height: 225px;
            @media (max-height: 800px) {
                bottom: calc(100% + 4px);
                top: unset;
            }
        }
        @media (max-height: 600px) {
            .body-modal {
                overflow-y: auto;
                .v2-dropdown__menu {
                    max-height: 150px;
                }
            }
        }
    }
    &.--more-button {
        .body-modal {
            line-height: 20px;
            padding: 20px;
            font-size: 14px;
            .v2-btn-default {
                width: 100%;
                margin-top: 16px;
            }
            p + p {
                margin-top: 5px;
            }
        }
    }
    &.--form-no-border {
        .modal__container {
            .header-modal,
            .footer-modal {
                padding: 0px 16px;
            }
            .body-modal {
                border: none;
                padding: 0px 16px;
            }
            .footer-modal {
                justify-content: flex-end;
            }
            .rows + .rows {
                margin-top: 16px;
            }
            .txt {
                font-size: 14px;
                color: $grey-dark;
                @include text-ellipsis;
            }
            .row-multi {
                margin-top: 16px;
                .col {
                    width: calc(100% / 3);
                    & + .col {
                        padding-left: 8px;
                    }
                }
            }
            .field-input {
                &::placeholder {
                    color: $grey-soft-txt;
                }
                &.--form-md {
                    height: 100px;
                    padding: 8px;
                    line-height: 20px;
                    resize: none;
                }
            }
        }
    }
    &.--manage-phone-label {
        .modal__container {
            width: 520px;
        }
        .header-modal {
            padding: 0px 16px;
            &__label {
                @include flexcenter;
                .v2-btn-default {
                    margin-left: 16px;
                    font-weight: 400;
                    svg path {
                        fill: #666;
                    }
                }
            }
        }
        .list-types {
            border-radius: 0 0 4px 4px;
            padding: 0px;
            filter: opacity(1);
            &__rows {
                @include betweenitems;
                padding: 0px 16px;
                height: 48px;
                & + .list-types__rows {
                    box-shadow: inset 0px 1px 0px $border-color-grey;
                }
                .label-type {
                    @include text-ellipsis;
                    flex: 1;
                    color: $black;
                    font-weight: 500;
                }
                .v2-btn-default {
                    margin-left: 8px;
                }
            }
        }
    }
    .wrap-address-exists {
        @include transform(none);
        top: 150px;
        .info-address {
            text-align: center;
            word-break: break-word;
        }
    }
    &.--add-items {
        &.--modal-on-header {
            .body-modal {
                max-height: calc(100vh - 245px);
                padding: 16px 24px;
            }
        }
        .row {
            & + .row {
                margin-top: 12px;
            }
        }
    }
    &.--has-contain {
        &.open {
            z-index: 100001;
        }
    }
    &.--modal-on-header {
        @media only screen and (min-width: 1024px) and (min-height: 500px) {
            position: absolute;
            > .modal__container {
                margin: unset;
                left: auto;
                right: 0px;
                top: calc(100% + 2px);
                transform: none;
                float: right;
            }
        }
    }
    // Modal view task popup on fullcalendar
    &.--viewtask-details {
        .modal__container {
            width: 600px;
            max-height: 80vh;
            &.--in-details {
                .body-modal {
                    max-height: calc(80vh - 64px);
                }
            }
            &:not(.--in-details) {
                .body-modal {
                    max-height: calc(80vh - 128px);
                }
            }
        }
        .header-modal {
            padding: 0px 16px;
        }
        .body-modal {
            border-bottom: none;
            padding: 0px;
        }
        .box-task {
            padding: 16px;
            @include font-color(20px, 13px, $grey-extradark);
            border-radius: 0px 0px 4px 4px;
            .title {
                .avt-img {
                    @include size(32px);
                }
                &__info {
                    font-size: 14px;
                    line-height: 17px;
                }
            }
            .description {
                margin-top: 19px;
                @include text-line-clamp(12);
            }
            &__list-tags {
                margin-top: 6px;
            }
            &__logs {
                margin-top: 10px;
            }
            &.is-edited {
                padding: 0px;
                > .title,
                > .description,
                > .box-task__list-tags,
                > .box-task__logs {
                    display: none;
                }
                .form-editing {
                    margin: 0px;
                    background: $transparent;
                    &__footer {
                        background: $transparent;
                    }
                }
            }
        }
    }
    &.container-assign-lead {
        .choose-lead {
            .dropbtn {
                padding: 0 4px;
            }
            .lead-info {
                column-gap: 4px;
                max-width: 100%;
                &__label {
                    max-width: 200px;
                    flex: 0 0 auto;
                    width: auto;
                }
                &__number {
                    max-width: 100px;
                }
                .txt-ellipsis {
                    white-space: nowrap;
                }
            }
        }
        .phone-field {
            .v2-dropdown {
                flex: 1;
                max-width: calc(100% - 140px);
                .dropbtn {
                    border-radius: 4px;
                }
                .scrolls {
                    max-height: 230px;
                }
            }
        }
        @media (max-height: 640px) {
            .body-modal {
                overflow-y: auto;
                .container-column {
                    max-height: 200px;
                }
                .phone-field {
                    .scrolls {
                        max-height: 130px;
                    }
                }
            }
        }
    }
    // Modal has monthly date picker
    .react-datepicker__tab-loop {
        .react-datepicker-popper {
            .filter-range {
                min-height: unset !important;
            }
            .datepicker-header-custom {
                height: 40px;
            }
            .react-datepicker {
                &__day-name,
                &__day {
                    width: 42px;
                }
                &__day {
                    height: 37px;
                    margin-bottom: 2px;
                }
                &__day--today:not(.react-datepicker__day--outside-month)::after {
                    bottom: 2px;
                }
            }
            .wrapper-select-range {
                height: 315px;
            }
            @media (max-height: 700px) {
                .react-datepicker {
                    &__day-name,
                    &__day {
                        width: 35px;
                        height: 30px;
                    }
                }
                .wrapper-select-range {
                    height: 270px;
                }
                .datepicker-header-custom {
                    .datepicker-selected {
                        .v2-dropdown__menu {
                            max-height: 220px;
                        }
                    }
                }
            }
        }
        .react-datepicker__month-container {
            padding-bottom: 5px;
        }
    }
    // Modal add number
    &.--add-number {
        .tables {
            max-height: calc(90vh - 422px);
            border-top: 1px solid $border-color-grey;
            overflow-y: unset !important;
            &-list {
                overflow-y: auto;
            }
        }
    }
    .rows-include-jobs {
        @include betweenitems;
        margin-top: 8px;
        font-size: 13px;
        &__label {
            font-weight: 500;
            color: $grey-very-dark;
        }
        &.is-active {
            .label-active {
                display: none;
            }
        }
        &:not(.is-active) {
            .label-inactive {
                display: none;
            }
        }
    }
    &.--email-smartview {
        .modal {
            &__container {
                font-size: 14px;
                line-height: 20px;
                width: 540px;
                .fw-600 {
                    font-weight: 600 !important;
                }
                .body-modal {
                    padding: 0;
                    overflow-y: auto;
                    @include flexcolumns;
                }
                .--lead {
                    background-color: $white-lotion;
                    border-bottom: 1px solid $border-color-grey;
                }
                .--alert {
                    @include flextop;
                    gap: 8px;
                    background-color: $pink-linen;
                    padding: 10px 24px 10px 24px;
                    border-bottom: 1px solid $border-color-grey;
                    .col-icon {
                        background-color: $white;
                        border-radius: 50%;
                        @include size(32px);
                        @include centeritem;
                    }
                    .col-desc {
                        flex: 1;
                        @include font-color(20px, 13px, $black-dark-charcoal);
                        &__title {
                            @extend .fw-600;
                            padding-top: 8px;
                        }
                    }
                }
                .has-form {
                    @include flexcolumns;
                    gap: 16px;
                    padding: 16px 23px;
                    .v2-dropdown {
                        .dropbtn:hover {
                            border-right-color: $grey-soft;
                        }
                        &__menu {
                            max-height: 200px;
                        }
                    }
                }
                .--lead,
                .has-form + .rows {
                    padding: 10px 23px;
                }
                .line {
                    border-bottom: 1px solid $border-color-grey;
                }
            }
        }
        &.--preview {
            .body-modal {
                height: 650px;
                @media (max-height: 650px) {
                    .container-content-email {
                        overflow: unset;
                    }
                }
                .rows {
                    @include flexcenter;
                    > .grey-faux-granite {
                        max-width: 40%;
                    }
                    .col-action {
                        display: flex;
                        gap: 4px;
                    }
                    &.--alert {
                        align-items: flex-start;
                        background-color: $yellow-blanched-almond;
                        .flex-1 {
                            padding-top: 8px;
                        }
                    }
                }
                .rows,
                .wrap-form {
                    gap: 8px;
                    padding: 12px 24px;
                    border-bottom: 1px solid $border-color-grey;
                }
                .wrap-form {
                    @include flexcolumns;
                    background-color: $white-lotion;
                    .form {
                        &,
                        &__field {
                            @include flexcenter;
                        }
                        &__label {
                            width: 60px;
                        }
                        &__field {
                            flex: 1;
                            gap: 6px;
                            @include text-ellipsis;
                            .name {
                                max-width: 70%;
                            }
                            .txt {
                                width: 30%;
                                flex: 1;
                            }
                        }
                    }
                }
                .wrap-content-email {
                    padding: 24px;
                    overflow-y: auto;
                }
                .to-email {
                    padding-top: 24px;
                    & + .wrap-content-email {
                        padding-top: 12px;
                    }
                }
                .is-sms {
                    min-height: unset;
                }
                .wrap-content-email {
                    min-height: unset;
                    width: unset;
                    overflow-y: unset;
                    .sms-message {
                        padding-bottom: 24px;
                        &__info {
                            @include flexcolumns;
                            gap: 5px;
                        }
                    }
                    .message-desc {
                        min-height: 30px;
                        white-space: pre-line;
                        p {
                            margin-bottom: 0;
                        }
                    }
                    .box-attach {
                        @include flexcolumns;
                        align-items: flex-end;
                        .img-attach {
                            max-width: 165px;
                            img {
                                margin: 0;
                                border-radius: 12px 4px 12px 12px;
                            }
                            & + .pdf-attach {
                                margin-top: 5px;
                            }
                        }
                        .pdf-attach {
                            width: 165px;
                            & + .img-attach {
                                margin-top: 5px;
                            }
                            &:hover {
                                text-decoration: none;
                            }
                        }
                        &.has-manyimage {
                            .img-attach {
                                &:first-child img {
                                    border-radius: 12px 4px 0px 0px;
                                }
                    
                                &:last-child img {
                                    border-radius: 0px 0px 12px 12px !important;    
                                }
                            }
                        }
                    }
                    .video-attach {
                        max-width: 65%;
                        video {
                            border-radius: 12px;
                        }
                    }
                    .attachment-audio {
                        audio {
                            max-width: 100%;
                        }
                    }
                }
            }
        }
    }
    &.modal-add-bank {
        .v2-dropdown__menu {
            max-height: 280px;
        }
    }
    @media (max-width: 1023px) {
        &.--add-items {
            .modal__container {
                max-height: calc(100% - 20px);
            }
        }
    }
    &:not(.c-job-details):not(.modal-route-optimizer):not(.wrap-datepicker):not(.view-task-popup):not(
            .modal-edit-setting
        ):not(.wrap-schedule-picker__list):not(.form-stripe-subscription):not(.modal-smart-views):not(
            .modal-tutorials
        ) {
        > .modal__container:not(.--preview-r):not(.modal-document--jobs) {
            max-height: calc(100% - 20px);
        }
    }
    &.modal-add-time {
        .body-modal {
            .row {
                & + .row {
                    margin-top: 0;
                }
                @media (max-height: 750px) {
                    .v2-dropdown__menu:not(.content-user) {
                        max-height: 220px;
                    }
                }
                .wrap-time {
                    .dropbtn {
                        max-width: unset;
                    }
                    .txt-title {
                        display: block;
                        color: $black-3;
                        font-weight: 500;
                    }
                }
                .col-duration {
                    flex: 1;
                }
            }
        }
    }
    &.modal-add-terms {
        .has-many-field__items {
            .field-input {
                width: calc(100% - 85px);
            }
            .v2-dropdown {
                width: 85px;
            }
        }
    }
    // Modal resize
    &.modal-resize {
        .modal__container {
            width: max-content;
        }
        .box-image {
            background-color: $white;
            @include centeritem;
            border-radius: 4px;
        }
        .wrap-image {
            position: relative;
            &::before {
                content: '';
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                @include size(320px);
                border: 1px solid $white;
                border-radius: 50%;
                pointer-events: none;
            }
        }
    }
    &.--upgrade-plan {
        .modal__container {
            width: max-content !important;
        }
    }
}
// When open sidebar
.gorilladesk {
    &.active {
        .modal-revenue-details {
            width: calc(100% - 304px);
        }
    }
}
// View attachment on sms message
.modal-viewattachment {
    &.open {
        z-index: 100001;
    }
    .slick-slider {
        position: relative;
        width: 100%;
    }
    .slick-arrow {
        position: absolute;
        cursor: pointer;
        top: 50%;
        @include transform(translateY(-50%));
    }
    .gd-preview-attach {
        background: $transparent;
        width: 100%;
        height: 100%;
        box-shadow: none;
        flex-direction: unset;
        .btn-close-menu {
            top: 16px;
        }
    }
}
// modal send broadcast
.modal-sendbroadcast {
    width: 95%;
    max-width: 1000px;
    height: 85%;
    max-height: 85%;
    min-width: 780px;
    > .body-modal {
        max-height: calc(100% - 64px);
        height: calc(100% - 64px);
        padding: 0px;
        font-size: 14px;
    }
    .content-broadcast {
        @include betweentop;
        height: 100%;
        color: $grey-dark;
        &__boxs {
            height: 100%;
            &.--left {
                width: 355px;
                box-shadow: inset -1px 0px 0px $border-color-grey;
                .header-broadcast {
                    padding: 24px 24px 16px;
                    .sub-title {
                        font-weight: 400;
                    }
                }
                .title {
                    color: $grey-extradark;
                    font-size: 23px;
                    line-height: 32px;
                    margin-bottom: 16px;
                }
                .form-total {
                    color: $black;
                    border-top: solid 1px $border-color-grey;
                    margin-top: 16px;
                    padding-top: 16px;
                    .total {
                        @include betweenitems;
                        & + .total {
                            margin-top: 5px;
                        }
                        &__label {
                            @include centeritem;
                            svg {
                                margin-right: 4px;
                            }
                        }
                        &__number {
                            @extend .fw-600;
                            text-align: right;
                            @include text-ellipsis;
                            max-width: 50%;
                        }
                    }
                }
            }
            &.--right {
                width: calc(100% - 355px);
                flex: 1;
                .header-preview {
                    @include betweenitems;
                    margin-bottom: 15px;
                    padding: 24px 24px 0;
                }
                // For show tag label and form editer
                .wrapbox-editor {
                    &__form {
                        input {
                            height: 40px;
                            padding: 8px;
                            border-radius: 4px;
                        }
                    }
                }
                .has-form {
                    .wrapbox-editor__form {
                        .tag-label {
                            position: absolute;
                            left: 8px;
                            top: 5px;
                        }
                    }
                }
            }
            .flex-column__content {
                padding: 0 20px 24px;
            }
            .has-form {
                .rows {
                    & + .rows {
                        margin-top: 16px;
                    }
                    .txt {
                        font-weight: normal;
                    }
                }
            }
        }
    }
}
.modal-preview-template {
    .modal__container {
        max-height: none;
        width: 610px;
    }
    .body-modal {
        max-height: 70vh;
        height: 70vh;
    }
    .container-content-email {
        .title h3 {
            margin-bottom: 10px;
            font-size: 15px;
            word-break: break-word;
        }
    }
    .content-sms {
        white-space: pre-line;
    }
}
// Modal page setting
.modal-setting {
    position: relative;
    .modal {
        .btn-modal {
            margin-left: 0px !important;
        }
    }
}
.modal-edit-setting {
    .modal__container {
        .body-modal {
            .row-multi {
                align-items: flex-start;
                .col {
                    width: 32%;
                }
                .col-48 {
                    width: calc(48% - 3px);
                }
                .v2-dropup {
                    .content-full {
                        max-width: 250px;
                        right: 0px;
                    }
                }
            }
            .description-box {
                height: 105px;
            }
            .txt {
                @include text-ellipsis;
                display: block;
                line-height: 20px;
                margin-bottom: 3px;
            }
        }
    }
}
// Modal add new customer
.modal-addcustomer:not(.--add-number) {
    > .modal__container {
        width: 540px !important;
        .header-modal {
            height: 64px;
        }
        > .body-modal {
            padding: 0px;
            @include font-color(20px, 14px, $grey-dark);
            max-height: none;
            flex: 1;
            .alert {
                max-width: calc(100% - 48px);
                margin: 20px 24px 0px;
            }
        }
        .footer-modal {
            justify-content: space-between;
            z-index: 5;
            background-color: $white;
        }
    }
    .footer-modal {
        .dropbtn-save {
            border: solid 1px $transparent;
        }
        .dropdown-save {
            &:hover {
                .v2-dropdown__menu {
                    display: block;
                }
            }
            .v2-dropdown__menu {
                bottom: calc(100% + 1px);
                right: 0px;
                width: 145px;
                font-weight: 500;
            }
        }
    }
    .switch-form {
        &:not(.is-fastform) {
            .form-fast {
                display: none;
            }
        }
        &.is-fastform {
            .form-default {
                display: none;
            }
        }
        .required-label {
            padding: 4px 24px 8px;
            font-size: 10px;
            color: $txt-black;
            line-height: 20px;
            display: flex;
        }
    }
    .box-has-rows {
        padding: 24px;
        &:first-child {
            padding-top: 0px;
        }
        &.--box-location,
        &.form-field {
            background: $blue-alice-light;
        }
        .rows {
            @include flextop;
            & + .rows {
                margin-top: 4px;
            }
            &__label {
                width: 150px;
                line-height: 32px;
                &:not(:has(.tooltip)) {
                    @include text-ellipsis;
                }
                .required {
                    color: $red-default;
                    margin: 0px 8px 0px 2px;
                }
                .tooltiptext {
                    margin-left: 30px;
                }
            }
            &__field {
                width: calc(100% - 150px);
                flex: 1;
                &.--has-toggle {
                    @include flexcenter;
                    min-height: 32px;
                    line-height: 32px;
                    .same {
                        left: 0px;
                        margin-top: 1px;
                        &:after {
                            color: $txt-black;
                        }
                    }
                }
                .list-add-tags {
                    .v2-dropdown__menu {
                        z-index: 3;
                    }
                }
            }
            &.--location-tag {
                .v2-dropdown__menu {
                    top: auto;
                    bottom: calc(100% + 5px);
                }
            }
            .field-input {
                @include placeholder {
                    font-weight: normal;
                }
                &.--account {
                    background: $white-cultured;
                    border: 1px solid $grey-platinum;
                    color: $txt-black;
                    &:hover {
                        border-color: $transparent;
                    }
                }
            }
            .v2-dropdown {
                background-color: $white;
            }
            .info-card {
                .number__digit {
                    width: 70px;
                }
            }
        }
        .modal--mdu + .--location-tag {
            margin-top: 4px;
        }
        .contact-field {
            @include betweenitems;
            background: $btn-disable;
            border-radius: 4px;
            height: 32px;
            padding-left: 10px;
            padding-right: 4px;
            &__info {
                width: calc(100% - 32px);
                @include text-ellipsis;
                line-height: 32px;
                > *:not(.vaild-name) {
                    display: inline;
                }
                .valid-name {
                    display: inline-flex;
                    width: unset;
                    &__title {
                        max-width: unset;
                    }
                    .label-content {
                        max-width: 60px;
                    }
                }
            }
            .dots::before {
                @include size(3px);
                margin: 2px 6px;
                background-color: $grey-soft;
            }
            .cus-name {
                color: $purple-default;
                font-weight: 500;
            }
        }
        .select-source {
            .tag-label {
                max-width: calc(100% - 20px);
            }
        }
        &.form-field {
            .rows {
                .v2-dropdown {
                    background-color: $transparent;
                    .dropbtn {
                        background-color: $white;
                    }
                    &__menu {
                        z-index: 3;
                    }
                    &[data-popper-reference-hidden] {
                        display: none !important;
                    }
                }
                & + .rows {
                    margin-top: 16px;
                }
                &__info {
                    flex: 1;
                    width: calc(100% - 150px);
                    & > :first-child {
                        width: calc(100% - 36px);
                    }
                    .react-datepicker-popper {
                        z-index: 3;
                    }
                }
            }
        }
    }
    @media only screen and (max-width: 540px) {
        .box-has-rows {
            .rows {
                flex-direction: column;
                &__label {
                    text-overflow: unset;
                }
                &__field {
                    width: 100%;
                }
                .has-many-field {
                    &__items {
                        width: 100%;
                    }
                    @include flexcolumns;
                    align-items: flex-start;
                }
            }
        }
    }
}
// Modal Messaging Preferences
.--appointment {
    .modal__container {
        right: 4px;
        margin-right: unset;
    }
    .text {
        margin: 0 24px 10px;
        text-transform: uppercase;
        line-height: 25px;
        font-weight: 500;
        font-size: 12px;
        color: $grey-extrlight;
    }
    .header-modal {
        height: 56px;
    }
    .body-modal {
        border-top: none;
    }
    .box-preferences {
        .txt-appointment {
            margin-bottom: 17px;
            font-size: 12px;
        }

        .lines {
            margin-bottom: 24px;
        }
    }
    &.messaging-preferences {
        &.--wrap {
            .boxs {
                &.boxs-modified {
                    padding: 5px 25px 16px;
                }
            }
        }
    }
}
.messaging-preferences {
    &:not(.--appointment) {
        .body-modal {
            max-height: calc(100% - 128px);
        }
        .box-preferences {
            border-top: solid 1px $border-color-grey;
            padding-top: 35px;
            padding-left: 24px;
        }
    }
    .modal__container {
        width: 540px;
        &.--center {
            .box-preferences {
                border-top: 0px;
                padding-top: 25px;
            }
        }

        .body-modal {
            max-height: calc(90vh - 128px);
            padding: 0px;
            flex-direction: column;
        }

        .boxs {
            padding: 20px 25px 15px 29px;

            .txt {
                color: $date-color;
                font-weight: normal;

                @include flexcenter;

                svg {
                    margin-right: 8px;
                }
            }

            .lines {
                @include betweenitems;
            }
        }
        .tab-items {
            font-size: 13px;
        }
    }
    // Open Job - Messaging Preferences
    &.--wrap {
        .header-modal {
            .btn-cancel {
                margin-right: 8px;
            }
        }
        .boxs {
            max-width: 480px;
            .txt {
                color: $granite-gray;
                font-weight: normal;
                @include flexcenter;
                svg {
                    margin-right: 8px;
                }
            }

            &.boxs-modified {
                padding: 1px 0 16px 25px;
                .lines {
                    @include flexwrap;
                    justify-content: flex-end;
                    .field-input {
                        @include font-color(20px, 14px, $black-3);
                        font-weight: 500;
                    }
                    .field {
                        @include flexcenter;
                        .v2-dropdown {
                            flex: 1;
                            max-width: calc(100% - 37px);
                        }
                        .v2-btn-default {
                            margin-left: 5px;
                        }
                        & + .field {
                            margin-top: 5px;
                        }
                    }
                    & + .lines {
                        margin-top: 5px;
                    }
                    .v2-dropdown {
                        > .dropbtn {
                            .valid-phone {
                                width: calc(100% - 20px);
                            }
                        }
                        .item-selected {
                            width: calc(100% - 22px);
                            .txt-ellipsis {
                                flex: unset;
                                width: unset;
                            }
                        }
                        &__menu ul:empty {
                            padding: 0;
                        }
                    }
                }
                .btn-more {
                    .is-deleted {
                        display: none !important;
                    }
                    &.has-added {
                        .is-deleted {
                            display: flex !important;
                        }
                        .is-added {
                            display: none !important;
                        }
                    }
                    &.added-full {
                        width: 37px;
                        .v2-btn-default {
                            display: none !important;
                        }
                    }
                }
                &:has(.lines + .lines) {
                    .lines:first-child {
                        .v2-dropdown {
                            .items:has(.valid-phone__name) {
                                .txt-ellipsis {
                                    max-width: 60%;
                                    margin-right: 0;
                                }
                            }
                            &__menu {
                                .txt-ellipsis {
                                    white-space: nowrap;
                                }
                            }
                        }
                    }
                }
            }
        }
        .border-top {
            border-top: 1px solid $grey-light-2;
        }
        .box-appointment {
            padding: 33px 0 0 27px;
            border-top: 0;
            .txt-appointment {
                display: block;
                text-align: left;
                line-height: 25px;
                font-size: 12px;
            }
            .lines {
                .txt {
                    font-size: 13px;
                }
                & + .lines {
                    margin-bottom: 24px;
                }
            }
        }
        .field {
            width: calc(100% - 160px);
        }
        .txt {
            width: 155px;
            &:not(:has(.txt-ellipsis)) {
                @include text-ellipsis;
                display: block;
            }
        }
        .lines {
            gap: 5px;
        }
    }
    &.--has-detail {
        .boxs {
            &.boxs-modified {
                padding: 24px 24px 0;
            }
        }
    }
    .boxs {
        .valid-phone__number {
            @include text-ellipsis;
        }
    }
}
.modal-search-qb {
    .container-modal {
        .body-modal {
            border-top: 0;
        }
        .form-notfound {
            color: $black-2;
            font-weight: 500;
            font-size: 14px;
            word-break: break-word;
            white-space: normal;
        }
    }
}
.modal-methods {
    &:not(.--form-no-border) {
        .footer-modal {
            border-top: solid 1px $border-color-grey;
        }
    }
    .modal__container {
        width: 370px;
        .content {
            max-height: calc(100% - 128px);
        }
        .has-form {
            .txt {
                font-size: 13px;
            }
        }
    }
    > .form-addbank {
        .content {
            padding: 25px;
        }
    }
    .stripe-additional {
        &.active {
            max-height: 95%;
        }
        &__link {
            margin: 0px auto 15px;
        }
        &__logs {
            &.active {
                .logs-header {
                    border-bottom: 1px solid $border-color-grey;
                    .toggle-icon {
                        @include transform(rotate(180deg));
                    }
                }
                .logs-content {
                    display: block;
                }
            }
            .logs-header {
                padding: 10px 20px 10px 24px;
                border-top: 1px solid $border-color-grey;
                cursor: pointer;
                .toggle-icon {
                    svg {
                        @include size(16px);
                    }
                }
            }
            .logs-content {
                display: none;
                max-height: 200px;
                @media (max-height: 700px) {
                    max-height: 120px;
                }
            }
            .logs-item {
                line-height: 20px;
                &:not(:last-child) {
                    border-bottom: 1px solid $border-color-grey;
                }
                .item-content {
                    width: calc(100% - 150px);
                    .txt-log {
                        word-break: break-word;
                        white-space: break-spaces;
                    }
                    .avt,
                    .avt-name {
                        @include flexinline;
                        position: relative;
                        background: $white-flash;
                        color: $black-eerie;
                        border-radius: 13px;
                        vertical-align: text-bottom;
                        bottom: -2px;
                        margin-top: -6px;
                        max-width: 60%;
                    }
                }
                .grey-generic {
                    max-width: 145px;
                }
            }
        }
    }
    .rows {
        & + .rows {
            margin-top: 15px;
        }
    }
}
.form-addcard {
    &.active {
        .detail-additional {
            display: block;
        }
        .more-option {
            svg {
                @include transform(rotate(180deg));
            }
        }
    }
    .detail-additional {
        padding: 30px 0px 20px;
        display: none;
    }
    .content {
        padding: 0px 25px;
        .content-info {
            box-shadow: 0 1px 4px 0 $black-darker5;
            margin-top: 15px;
            margin-bottom: 5px;
            padding: 25px 15px;
            border-radius: 8px;
            .txt {
                color: $grey-greenish;
                font-size: 15px;
                font-weight: normal;
            }
            .field-input {
                height: 40px;
                padding: 0px 12px;
            }
            .number-card {
                width: 215px;
            }
            .col-date {
                width: 65px;
            }
            .col-cvc {
                width: 120px;
                & + .col-cvc {
                    width: 95px;
                    margin-left: 15px;
                }
            }
        }
        .more-option {
            @include centeritem;
            margin: 12px 0px;
            svg {
                margin-left: 8px;
            }
        }
    }
    .alert {
        max-width: 100%;
        &__description {
            padding-left: 0px;
        }
    }
}
.new-contact {
    > .modal__container {
        .body-modal {
            &.has-form {
                .rows .txt {
                    font-size: 13px;
                }
            }
            .col-phone {
                width: 160px;
            }
            .col-phonetype {
                width: calc(100% - 160px);
            }
            .field-input,
            .items {
                font-size: 14px;
            }
            .phone-field {
                .v2-dropdown__menu {
                    top: auto;
                    bottom: calc(100% + 5px);
                    max-height: 245px;
                    .scrolls {
                        max-height: calc(245px - 48px);
                    }
                }
            }
        }
    }
    .v2-dropdown {
        width: calc(100% - 196px);
        max-width: calc(100% - 196px);
        .dropbtn,
        .items {
            @include font-color(20px, 14px, $black-2);
        }
    }
}
.modal-mergeacount {
    .body-modal {
        padding-top: 0px;
        border-top: none;
        max-height: calc(90dvh - 128px);
    }
    .description {
        line-height: 20px;
        margin-bottom: 18px;
    }
    .has-form {
        font-size: 14px;
        .txt {
            font-weight: normal;
        }
        .field-input {
            color: $black-3;
            font-weight: 500;
        }
        .drop-txt {
            line-height: 30px;
        }
    }
    .map-merge {
        margin-top: 26px;
        color: $black-2;
        > .txt {
            font-weight: 500;
        }
    }
    .detail-map {
        margin-top: 11px;
    }
    .timeline-details {
        @include flexcenter;
        height: 24px;
        padding-bottom: 8px;
        box-sizing: content-box;
        position: relative;

        &:last-child {
            > .line {
                border: 0;
            }
        }
        &:nth-last-child(2) {
            > .line {
                height: 55%;
            }
        }

        &.primary-user {
            .line::after {
                content: '';
                display: inline-block;
                @include size(5px);
                position: absolute;
                top: -3px;
                left: -4.5px;
                background: #3075ea;
                border: solid 1px $white;
                border-radius: 50%;
                box-sizing: content-box;
            }
            .detail {
                padding-left: 8px;
                &__info {
                    max-width: calc(100% - 105px);
                }
            }
        }
        &.timeline-conts {
            .detail {
                .line {
                    height: 16px;
                    width: 18px;
                    left: 12px;
                    top: -2px;
                    border-bottom: solid 1px #a6d0f8;
                    border-bottom-left-radius: 6px;
                    &::before {
                        content: '';
                        display: inline-block;
                        @include size(5px);
                        position: absolute;
                        top: 11px;
                        right: -2px;
                        background: #3075ea;
                        border: solid 1px $white;
                        border-radius: 50%;
                        box-sizing: content-box;
                    }
                }
                &__info {
                    width: calc(100% - 30px);
                }
            }
        }

        .line {
            border-left: solid 1px #a6d0f8;
            left: 12px;
            top: 24px;
            height: 100%;
            position: absolute;
        }
        .circle {
            @include size(24px);
            @include centeritem;
            border-radius: 9px;
            background-color: light-dark(#3075ea80, #114aab80);
            color: $white-default;
            font-size: 11px;
            font-weight: 500;
            position: relative;
            flex: 0 0 auto;
        }
        .detail {
            padding-left: 30px;
            @include flexcenter;
            overflow: hidden;
            flex: 1;
            .primary {
                background-color: $orange-default;
                color: $white-default;
                font-size: 11px;
                font-weight: 500;
                height: 20px;
                line-height: 20px;
                padding: 0px 6px;
                border-radius: 8px;
                margin-left: 5px;
            }
            .circle {
                position: static;
                margin-right: 6px;
            }
        }
    }
    .customer-name {
        @include text-ellipsis;
        max-width: fit-content;
        & + .account-number {
            max-width: 100px;
        }
    }
    .account-number {
        @include text-ellipsis;
    }
}
.modal-newlocation {
    .modal__container {
        max-width: 1400px;
        width: 95%;
        height: auto;
        .content-modal {
            @include flexcolumns;
            flex-wrap: wrap;
            min-width: 520px;
        }
        .body-modal {
            font-size: 14px;
            color: $grey-dark;
            .rows {
                &:not(.note-location):not(.has-many-field) {
                    .v2-dropdown:not(.v2-dropdown--more) {
                        width: 100%;
                        max-width: 100%;
                        .dropbtn {
                            max-width: 100%;
                        }
                    }
                }
                .has-many-address {
                    .field-city {
                        flex: 1;
                    }
                    .field-state {
                        min-width: 16%;
                    }
                    .field-zip {
                        min-width: 24%;
                    }
                }
            }
            .modal--mdu {
                position: absolute;
                height: 100% !important;
                .modal__container {
                    max-height: 100%;
                    .body-modal {
                        max-height: calc(100% - 128px) !important;
                    }
                }
            }
        }
        .wrap-column-content {
            @include betweentop;
            flex-wrap: wrap;
            gap: 30px;
        }
        .left-column {
            flex: 1;
            min-width: 600px;
            > .w-100 {
                justify-content: space-between;
                gap: 20px;
            }
            @media screen and (max-width: 850px) {
                min-width: auto;
                > .w-100 {
                    flex-wrap: wrap;
                    .column-location {
                        min-width: 100%;
                    }
                }
            }
        }
        .column-content {
            .title {
                color: $grey-extradark;
                line-height: 20px;
                @extend .fw-600;
                margin-bottom: 12px;
                @include betweenitems;
                .switch {
                    font-weight: normal;
                }
            }
            .rows {
                & + .rows {
                    margin-top: 12px;
                }
                &--units {
                    .v2-btn-main {
                        width: 100%;
                        justify-content: center;
                    }
                }
                &.--multi {
                    @include betweenitems;
                }
                .txt {
                    color: $grey-dark;
                    font-size: 14px;
                    height: auto !important;
                }
                &:has(.field-textarea) {
                    @include flexcolumns;
                    .field-textarea {
                        height: 99px;
                    }
                }
            }
            .col-2 {
                width: 50%;
            }
            .field-input::placeholder {
                color: $grey-soft-txt;
            }
            &.column-tax {
                width: 100%;
            }
        }
        .column-location {
            max-width: 320px;
            min-width: 48%;
            .has-many-field {
                &__items {
                    justify-content: space-between;
                }
                .v2-dropdown {
                    max-width: calc(100% - 40px);
                }
                .v2-btn-default {
                    margin-left: 8px;
                }
            }
        }
        .tax-rate {
            width: 100%;
            border-top: 1px solid $border-color-grey;
            margin-top: 16px;
            padding-top: 16px;
            @include betweenitems;
            gap: 30px;
            .text-info {
                @include font-color(20px, 13px, $black);
            }
            & > .flexcenter {
                width: calc(100% - 405px);
            }
            .col {
                width: 48%;
            }
            & > .col-tax-rate {
                width: 375px;
            }
            @media (max-width: 850px) {
                flex-direction: column;
                gap: 10px;
                & > * {
                    width: 100% !important;
                }
                .--multi {
                    gap: 20px;
                }
                .text-info {
                    margin-right: 0;
                }
            }
            @media (max-width: 480px) {
                & > .flexcenter,
                .--multi {
                    flex-direction: column;
                }
                .col {
                    width: 100%;
                }
            }
        }
        .column-preferences {
            min-width: 400px;
            .boxs-modified {
                padding: 0px;
            }
            .box-appointment {
                padding-left: 0;
                padding-top: 15px;
            }
            .boxs-modified,
            .box-appointment {
                .lines {
                    margin-bottom: 8px;
                    .txt {
                        color: $grey-dark;
                        font-size: 14px;
                        flex: 0 0 auto;
                    }
                }
            }
            //change log sms
            &.messaging-preferences {
                .boxs-modified {
                    .field.changelog {
                        margin-top: 2px;
                    }
                }
            }
            .changelog {
                position: relative;
                &:not(.active) {
                    .changelog-box {
                        display: none;
                    }
                }
                &-label {
                    @include font-color(20px, 13px, $purple-default);
                    cursor: pointer;
                }
                &-box {
                    position: absolute;
                    z-index: 2;
                    background-color: $white;
                    border-radius: 4px;
                    width: 515px;
                    max-height: max-content;
                    top: 0;
                    right: 0;
                    box-shadow: 0px 4px 6px $black-verydark, 0px 2px 3px $black-light, 0px 1px 2px $black-light,
                        0px 0px 1px $black-extradark;
                    &__header {
                        @include betweenitems;
                        width: 100%;
                        height: 64px;
                        padding: 0 16px;
                        box-shadow: inset 0px -1px 0px $border-color-grey;
                        .title {
                            font-size: 18px;
                        }
                    }
                    &__content {
                        padding: 32px 16px 16px;
                        max-height: 395px;
                    }
                    .rows {
                        & + .rows {
                            margin-top: 28px;
                        }
                        .log-date {
                            @include font-color(20px, 15px, $grey-extradark);
                            font-weight: 500;
                            margin-bottom: 24px;
                        }
                        .log-content {
                            @include flextop;
                            &__left {
                                @include flexcenter;
                                margin-right: 16px;
                                > .v2-btn-default {
                                    margin-left: 16px;
                                    background-color: $btn-disable;
                                    border-radius: 12px;
                                    pointer-events: none;
                                }
                            }
                            &__right {
                                padding: 6px 0 20px;
                                width: calc(100% - 88px);
                                word-break: break-word;
                                > .d-flex {
                                    margin-bottom: 8px;
                                }
                                .name {
                                    max-width: 45%;
                                }
                                .name,
                                .phone-number {
                                    color: $purple-medium;
                                }
                                .date {
                                    color: $txt-black;
                                    margin-left: 8px;
                                    flex: 1;
                                }
                            }
                        }
                    }
                }
                @media screen and (max-width: 1000px) {
                    .changelog-box {
                        right: inherit;
                        left: -130px;
                        &__content {
                            max-height: 275px;
                        }
                    }
                }
            }
        }
        .v2-dropup,
        .v2-dropdown {
            .dropbtn.items {
                color: $grey-dark;
            }
        }
        .list-add-tags {
            .v2-dropdown__menu {
                top: auto;
                bottom: calc(100% + 4px);
            }
        }
    }
}
// Modal Batch
.modal-modify {
    &.modal__container {
        width: 75%;
        max-width: 1048px;
        min-width: 740px;
        height: 100%;
    }
    .reminder {
        width: 100%;
        padding: 0px 23px;
        margin-bottom: 12px;
        font-weight: 400;
        &__title {
            padding-top: 10px;
            @include font-color(24px, 18px, $black-2);
            @extend .fw-600;
            min-height: 60px;
            @include betweenitems;
        }
    }
    .body-modal {
        @include flexcolumns;
        flex: 1;
        padding: 0;
        border-top: none;
        max-height: 100% !important;
        .header-select {
            flex: 0 0 auto;
            .v2-dropdown:not(.datepicker-selected) {
                .v2-dropdown__menu {
                    max-width: 100%;
                    max-height: 230px;
                    .status-dropdown {
                        max-height: 185px;
                    }
                }
            }
        }
        .tables {
            @include flexcolumns;
            flex: 1;
        }
    }
    &.modal-send-reminder {
        .header-select {
            .col-schedule {
                width: 23%;
            }
            > div + div {
                margin-left: 16px;
            }
        }
        .col-customer {
            width: 25%;
        }
        .col-job-status {
            width: 19%;
            .col-date-range {
                flex: 1;
            }
        }
    }
    .header-select {
        @include betweenitems;
        border-bottom: 1px solid $border-color-grey;
        padding: 0px 24px 16px 24px;
        font-weight: 400;
        .text {
            display: block;
            margin-top: 1px;
            margin-bottom: 5px;
            font-size: 14px;
        }
        .field-input {
            @include text-ellipsis;
            padding-right: 26px;
        }
        .col-schedule {
            width: 17.8%;
        }
        .col-date-range {
            width: 22.1%;
        }
        .col-statuses {
            width: 22%;
        }
        .col-reasign-to {
            width: 15%;
        }
        .col-move {
            width: 19.3%;
        }
        .dropbtn {
            font-size: 13px;
            font-weight: normal;
            color: $grey-extradark;
            .txt-ellipsis {
                max-width: calc(100% - 20px);
            }
        }
    }
    .tables {
        min-height: 220px;
        .rows {
            padding: 20px 24px 14px 25px;
            line-height: 1.23;
            color: $black-5;
            &:not(:last-child) {
                border-bottom: 1px solid $grey-boder-line;
            }
            .txt-ellipsis {
                display: block;
            }
            .outline-green-arrow {
                margin-top: -4px;
            }
            > div:not(.d-flex):not(:first-child),
            > .d-flex > div:not(:first-child) {
                margin-left: 8px;
            }
        }
        .address {
            line-height: 1.54;
            color: $black-2;
            word-break: break-word;
            margin-top: 10px;
            display: inline-block;
        }
        .preloader {
            position: static;
            @include transform(none);
            margin: 10px 0px;
        }
    }
    .t-title {
        .rows {
            @include flexcenter;
            padding: 5px 24px 5px 25px;
            border-bottom: 1px solid $border-color-grey;
            height: 40px;
            min-width: max-content;
            > div {
                @include betweenitems;
                line-height: 1.54;
                @extend .fw-600;
                color: $black-2;
                font-size: 13px;
            }
        }
        .col-checkbox {
            .check-items .item-checkbox {
                margin-top: -8px;
            }
        }
    }
    .t-content {
        height: calc(100% - 40px);
        overflow: overlay;
        .col-assign,
        .col-job-status {
            margin-top: -4px;
            .status-btn {
                text-align: left;
            }
        }
        .col-checkbox {
            .check-items .item-checkbox {
                margin-top: -6px;
            }
        }
        .rows:first-child {
            .col-assign {
                .tooltiptext {
                    bottom: auto;
                    top: calc(100% + 3px);
                }
            }
        }
        .col-job-status {
            @include flexcenter;
            flex-wrap: wrap;
            gap: 4px;
            .status-btn {
                @include text-ellipsis;
            }
        }
    }
    .col-checkbox {
        width: 35px;
        min-width: 35px;
    }
    .col-customer {
        width: 18%;
        @include flextop;
        flex: 0 0 auto;
        .customer-infor {
            width: calc(100% - 32px);
            margin-left: 8px;
        }
        .avt {
            margin-top: -5px;
        }
    }
    .col-service {
        width: 16%;
        max-width: 135px;
    }
    .col-job-status {
        width: 20%;
    }
    .col-datetime {
        width: 11%;
    }
    .col-move-icon {
        width: 40px;
        min-width: 40px;
        text-align: center;
        svg {
            margin-top: -3px;
        }
    }
    .col-new-datetime {
        width: 16%;
        color: $green-default;
    }
    .col-assign {
        width: calc(20% - 75px);
        @include flexcenter;
        .avt-img {
            margin-right: 8px;
            flex: 0 0 auto;
        }
        .assign-name {
            width: calc(100% - 32px);
            flex: 1;
        }
    }
    .col-email-sms {
        width: 30%;
        flex: 1;
    }
    .col-service2 {
        width: calc(25.5% - 35px);
    }
}
// Modal Print Schedule
.modal-print-schedule {
    @media screen and (max-height: 1045px) and (min-height: 601px) {
        @include transform(none);
        top: 40px;
    }
    .header-modal {
        height: 55px;
    }
    .body-modal {
        padding: 0px 23px 15px;
        border-top: none;
        .v2-dropdown {
            .v2-dropdown__menu {
                max-height: 250px;
            }
            .status-dropdown {
                max-height: 205px;
            }
        }
        @media (max-height: 670px) {
            overflow-y: auto;
            .v2-dropdown {
                &:not(:has(.status-dropdown)) {
                    .v2-dropdown__menu {
                        max-height: 140px;
                    }
                }
                &:has(.status-dropdown) {
                    .v2-dropdown__menu {
                        bottom: calc(100% + 4px);
                        top: unset;
                    }
                    .status-dropdown {
                        max-height: 155px;
                    }
                }
            }
        }
    }
    .content-print-schedule {
        .rows {
            > .v2-dropdown .dropbtn {
                @include font-color(16px, 13px, $black-4);
            }
            .field-input {
                padding-right: 30px;
                background-color: $transparent;
            }
            &:not(:last-child) {
                margin-bottom: 15px;
            }
        }
        .title {
            margin-bottom: 2px;
            font-weight: 500;
        }
        &.has-sm-datepicker {
            .react-datepicker__month-container {
                padding-bottom: 0px;
            }
            .react-datepicker__day-names {
                margin-top: 0px;
            }
        }
    }
}
// Modal SMS Charge Failed
.modal-sms-charge-failed {
    @include size(auto);
    top: 0px;
    right: auto;
    bottom: auto;
    left: 50%;
    @include transform(translateX(-50%));
    .modal__container {
        width: 494px;
        top: 0px;
        @include transform(none);
    }
    .header-modal {
        padding-right: 22px;
    }
    .body-modal {
        border: 0;
        padding: 0 24px;
        .inform {
            margin-bottom: 11px;
            line-height: 20px;
            color: $red-delete;
        }
        .retry {
            line-height: 20px;
        }
        .current-card {
            margin: 18px 0 24px;
            font-size: 14px;
            .card-number {
                margin-left: 3px;
            }
        }
        &:not(.has-message) {
            .--success {
                display: none;
            }
        }
        &.has-message {
            .--info {
                display: none;
            }
        }
    }
}
// modal route optimizer
.modal-route-optimizer {
    > .modal__container {
        top: 0;
        @include transform(none);
        @include size(100%);
        min-width: 920px;
        max-height: 100%;
        box-shadow: none;
        border-radius: 0px;
    }
    .header-modal {
        border-bottom: 1px solid $border-color-grey;
    }
    .body-modal {
        @include betweentop;
        padding: 0;
        border: 0;
        max-height: none;
        flex: 1;
        overflow-y: auto;
    }
    &:has(.list-time.active) {
        .header-modal {
            z-index: 4;
            background-color: $white;
        }
    }
    .route-optimizer-sidebar {
        width: 310px;
        height: 100%;
        border-right: solid 1px $border-color-grey;
        @include flexcolumns;
        font-size: 14px;
        .sidebar-header {
            padding: 16px 20px;
        }
        .title-toggle {
            @include betweenitems;
            width: 100%;
            .text {
                color: $black;
                @extend .fw-600;
                line-height: 20px;
                width: 80px;
                svg {
                    margin-right: 5px;
                }
            }
            .action {
                .tooltip {
                    margin-left: 5px;
                }
                &.has-upgrade {
                    .label-upgrade {
                        display: block;
                    }
                    .switch {
                        display: none;
                    }
                }
            }
            .label-upgrade {
                display: none;
                color: $blue-default;
                font-weight: 500;
                line-height: 24px;
            }
            .switch {
                input.toggle-round {
                    & + label {
                        .enable-disable {
                            top: 0px;
                            right: calc(100% + 3px);
                        }
                    }
                    &:checked + label {
                        .enable-disable {
                            &:after {
                                content: 'Enabled';
                                color: $violet-name;
                            }
                        }
                    }
                }
            }
        }
        .has-form {
            padding: 0px 20px 16px;
            overflow-y: auto;
            .has-col {
                @include betweentop;
                .col-2 {
                    width: 48%;
                }
            }
            .line {
                width: 100%;
                margin-top: 20px;
                padding-bottom: 20px;
                border-top: solid 1px $border-color-grey;
            }
            .txt {
                font-weight: normal;
                color: $grey-very-dark;
            }
            .dropbtn {
                width: 100%;
                text-transform: capitalize;
                color: $black;
                font-weight: normal;
                padding-left: 6px;
                padding-right: 4px;
            }
            .v2-dropdown {
                .v2-dropdown__menu {
                    max-width: 250px;
                    z-index: 3;
                }
                .content-checked {
                    .scrolls {
                        max-height: 200px;
                    }
                }
            }
            .view-jobstatus {
                .wrap-tags {
                    .status-btn {
                        font-size: 11px;
                        height: 20px;
                        line-height: 20px;
                    }
                }
                .rows-include-jobs {
                    margin-top: 0px;
                    padding: 12px 14px;
                    border-bottom: 1px solid $border-color-grey;
                }
                .item-checkbox {
                    height: auto;
                    .txt-ellipsis {
                        white-space: nowrap;
                    }
                }
            }
            .list-job-status {
                .dropbtn {
                    height: auto;
                    padding: 5px;
                }
                .wrap-tags {
                    flex-wrap: wrap;
                    .status-btn {
                        @include text-ellipsis;
                        max-width: 100%;
                    }
                }
                .v2-dropdown__menu {
                    max-width: 100%;
                    .scrolls {
                        max-height: 250px;
                    }
                }
            }
            .select-date {
                @include flexcenter;
                height: 32px;
                border: 1px solid $border-color-grey;
                border-radius: 3px;
                padding: 0px 4px;
                input {
                    border: none;
                    @include size(100%);
                }
                .ip-select-date {
                    width: calc(50% - 40px);
                    padding-left: 2px;
                }
                .txt-to {
                    color: $date-color;
                    display: inline-block;
                    padding: 0px 10px;
                }
                .list-time {
                    .dropbtn {
                        background-color: $transparent !important;
                        box-shadow: none !important;
                        border: none;
                        padding-left: 3px;
                        padding-right: 0px;
                        > span:not(:first-child) {
                            display: none;
                        }
                    }
                    .v2-dropdown__menu {
                        left: -8px;
                        top: 100%;
                        width: max-content;
                        @media (max-height: 768px) {
                            max-height: 250px;
                        }
                    }
                }
            }
            .view-customjobs {
                display: none;
                margin-top: 5px;
                &.show {
                    display: block;
                }
            }
            .row-optimize-to {
                .txt {
                    @include betweenitems;
                }
                .ip-select-date {
                    width: calc(100% - 45px);
                    flex: 1;
                    padding: 0px;
                }
                .content-full {
                    right: 0px;
                }
            }
            .col-address {
                position: relative;
                .selection {
                    width: 100%;
                    padding: 0px 25px 0px 6px;
                    cursor: text;
                    font-weight: normal;
                    color: $grey-extradark;
                    text-align: left;
                }
                .svg-selectbox {
                    position: absolute;
                    right: 6px;
                    top: 7px;
                }
            }
            .field-date__select {
                color: $black;
                padding-top: 1px;
            }
            .rows {
                & + .rows {
                    margin-top: 6px;
                }
            }
            .react-datepicker-popper {
                @media (min-height: 551px) {
                    position: absolute !important;
                }
                @media (max-height: 550px) {
                    top: 50% !important;
                    transform: translate(16px, -50%) !important;
                    .wrapper-select-range {
                        height: 276px;
                    }
                    .react-datepicker__day-name,
                    .react-datepicker__day {
                        @include size(32px);
                        font-size: 13px;
                    }
                }
            }
        }
        .box-count-drive {
            padding: 16px 20px;
            border-top: 1px solid $border-color-grey;
            bottom: 0;
            &.disable {
                .count-drive {
                    display: none;
                }
            }
            .count-drive {
                border: solid 1px $purple-default;
                border-radius: 4px;
                padding: 10px;
                margin-bottom: 10px;
            }
            .content-connection {
                padding-top: 5px;
                padding-left: 3px;
                > span {
                    @extend .fw-600;
                    border-bottom: solid 1px $black;
                }
            }
            .btn-route {
                width: 100%;
                justify-content: center;
            }
        }
        .rows-include-jobs__label {
            width: 50px;
            .tooltiptext {
                margin-left: -5px;
                max-width: 230px;
            }
        }
    }
    .content-route-optimizer {
        @include flexcolumns;
        justify-content: space-between;
        width: calc(100% - 310px);
        height: 100%;
        .tab-contents {
            flex: 1 1 auto;
            overflow-y: hidden;
            height: calc(100% - 60px);
        }
        .tab-conts {
            height: 100%;
        }
        .route-footer {
            border-top: solid 1px $border-color-grey;
            width: 100%;
            height: 60px;
            padding: 0px 20px;
            @include betweenitems;
            .switch-icons {
                padding: 1px;
                border-radius: 3px;
                border: solid 1px $border-color-grey;
                @include betweenitems;

                .tab-items {
                    @include size(28px);
                    @include centeritem;
                    padding: 0;
                    &.active-tab-selector,
                    &:hover {
                        cursor: pointer;
                        background-color: $grey-boder-line;
                    }
                    svg {
                        pointer-events: none;
                    }
                }
            }
        }
        .list-columns-conts {
            height: 100%;
            position: relative;
            @include flexcolumns;
            .view-calendar {
                border-radius: 0;
                height: calc(100% - 64px);
                position: unset;
            }
        }
        .container-map {
            .map-header {
                z-index: 13;
            }
        }
    }
    .tooltip.--fixed {
        .tooltiptext {
            position: fixed;
            top: auto;
            &.bottom {
                margin-top: 12px;
                left: 250px;
            }
            &.top {
                left: 270px;
                bottom: 115px;
            }
        }
    }
    .calendar {
        @include size(100%);
    }
    .view-calendar {
        .fc .fc-scrollgrid-section table {
            > colgroup > col {
                width: 64px !important;
            }
        }
    }
    .calendar-header {
        .right-menu {
            width: 100%;
            flex-wrap: wrap;
            .list-agenda {
                pointer-events: none;
            }
            .list-action {
                flex: 1;
                @include centeritem;
                position: relative;
                .wrap-datepicker {
                    width: fit-content;
                    left: 50%;
                    @include transform(translateX(-50%));
                }
            }
            .tooltiptext {
                min-height: 20px;
                bottom: calc(100% - 5px);
            }
        }
    }
    .route-list {
        height: 100%;
        @include flexcolumns;
        min-width: fit-content;
        color: $black;
        .col-customer {
            width: 190px;
            min-width: 190px;
            @include flextop;
            .check-items {
                margin-top: -4px;
            }
        }
        .col-location {
            width: calc(47% - 280px);
            min-width: 230px;
            max-width: 400px;
            svg {
                flex: 0 0 auto;
            }
        }
        .col-service {
            width: 12%;
            min-width: 100px;
        }
        .col-duration {
            width: 8%;
            min-width: 70px;
        }
        .col-job-status {
            width: 15%;
            min-width: 155px;
            @include flexcenter;
            flex-wrap: wrap;
            gap: 2px;
            .status-btn {
                height: auto;
                text-align: left;
                padding: 4px;
                line-height: normal;
                max-width: 100%;
                @include text-ellipsis;
            }
        }
        .col-datetime {
            width: 130px;
            min-width: 130px;
        }
        .col-arrow {
            width: 40px;
            min-width: 40px;
            text-align: center;
        }
        .route-event {
            &:not(.has-optimize-route) {
                min-height: 64px;
            }
            &:not(:nth-last-child(2)) {
                .dot.--second {
                    display: none;
                }
            }
        }
        .route-list-header {
            flex: 0 0 auto;
            height: 40px;
            @extend .fw-600;
            border-bottom: solid 1px $border-color-grey;
            position: sticky;
            top: 0;
            z-index: 1000;
        }
        .route-rows {
            box-shadow: inset 3px 0 0 0 $grey-generic;
            &.has-optimized {
                box-shadow: inset 0px -1px 0px $border-color-grey, inset 3px 0 0 0 $grey-generic;
                @include flexcolumns;
                .route-event {
                    @include flexcenter;
                }
                .has-optimize-route {
                    .col-location {
                        min-height: 44px;
                    }
                }
                .word-break {
                    @include text-line-clamp(3);
                }
            }
            &:not(.has-optimized) {
                .route-event {
                    box-shadow: inset 0px -1px 0px $border-color-grey;
                    padding: 14px 0px;
                    @include flextop;
                }
                .col-location {
                    svg {
                        margin-right: 2px;
                        margin-top: -2px;
                    }
                }
            }
        }
        .route-list-conts {
            flex: 1 1 auto;
            height: auto;
            color: $black-eerie;
            @include flexcolumns;
            .tables-empty {
                margin: 24px;
            }
            .route-view {
                .col-datetime {
                    color: $grey-extrlight;
                    .time {
                        margin-top: 1px;
                        color: inherit;
                        svg {
                            margin: -4px 0px 0px 2px;
                        }
                    }
                }
            }
        }
        .route-point-address {
            color: #808080;
            min-height: 64px;
            .col-customer {
                text-transform: uppercase;
            }
        }
        .route-title-time {
            box-shadow: inset 0px -1px 0px $border-color-grey;
            &:not(:first-child) {
                margin-top: 20px;
            }
            .timeline {
                @include flexcenter;
                padding: 12px 20px;
                height: 52px;
                letter-spacing: 0.01em;
                @extend .fw-600;
            }
        }
        .location-route {
            height: 100%;
            position: relative;
            .rout-line {
                .dot {
                    display: inline-block;
                    @include size(26px);
                    background: $transparent;
                    border: solid 2px $blue-default;
                    border-radius: 50%;
                    position: absolute;
                    left: 4px;
                    z-index: 999;
                    &.--first {
                        top: -45px;
                    }
                    &.--second {
                        bottom: -45px;
                    }
                }
                .line {
                    width: 2px;
                    height: calc(100% + 38px);
                    display: inline-block;
                    background: $blue-default;
                    position: absolute;
                    left: 16px;
                    top: -19px;
                    z-index: 1;
                }
            }
            .location {
                width: 100%;
                padding-left: 25px;
            }
        }
        .events-item {
            padding: 2px 5px;
            background: $purple-lavender;
            border-radius: 4px;
            color: $granite-gray;
            margin: 4px 0px;
            @include text-line-clamp(5);
            &__label {
                color: $purple-medium2;
                margin-left: 2px;
            }
            &.--custom {
                color: $white;
                .events-item__label {
                    color: $light-silver;
                }
            }
        }
        .optimized-label {
            margin-top: 3px;
        }
    }
}
// modal batch-job
.batch-job,
.modal-route-accept {
    .body-modal {
        border: 0;
    }
    .modal__container {
        width: 358px;
        &.--lg {
            width: 500px;
        }
        .customer-info {
            font-weight: 500;
            color: inherit;
        }
    }
    &.--recurring {
        z-index: 100002;
        .modal__container {
            width: 380px;
            .check-items {
                & + .check-items {
                    margin-top: 10px;
                }
            }
        }
    }
    &.--reschedule {
        .modal__container {
            width: 530px;
            .footer-modal {
                justify-content: flex-end;
            }
        }
    }
    &.--report {
        .modal__container {
            width: 400px;
        }
    }
    .header-modal,
    .body-modal,
    .footer-modal {
        padding: 0 16px;
    }
    .header-modal {
        height: auto;
        padding-top: 16px;
        padding-bottom: 8px;
        &__label {
            line-height: 20px;
            color: $grey-extradark;
        }
    }
    .body-modal {
        padding-top: 8px;
        .v2-dropdown {
            flex: 1;
            width: calc(100% - 150px);
            .v2-dropdown__menu {
                max-height: 153px;
                max-width: 100%;
            }
        }
    }
    .rows {
        & + .rows {
            margin-top: 16px;
        }
        &.--multi {
            @include betweenitems;
            .txt {
                @include text-ellipsis;
                line-height: 30px;
                max-width: 150px;
                margin-right: 10px;
            }
        }
        .check-items {
            .item-checkbox {
                height: auto;
                label {
                    @include font-color(20px, 14px, $grey-dark);
                    @include flexcenter;
                    &::before {
                        top: 2px;
                    }
                }
            }
            .txt-ellipsis {
                white-space: normal;
            }
            input[type='checkbox']:checked {
                & + .item-checkbox {
                    label::after {
                        top: 7px;
                    }
                }
            }
        }
        .desc {
            @include font-color(20px, 14px, $grey-dark);
        }
    }
    .body-modal {
        .v2-btn-default {
            width: 100%;
            background-color: $white;
        }
    }
    .footer-modal {
        justify-content: center;
        span.v2-btn-default {
            margin-left: 0;
        }
    }
}
.modal-route-accept {
    .modal__container {
        width: 480px;
        .header-modal {
            padding-top: 24px;
            &__label {
                text-align: center;
            }
        }
        .desc {
            font-weight: 500;
            line-height: 24px;
        }
        .elm-label {
            @include size(100%);
            @include centeritem;
        }
    }
}
.modal-email-send {
    .modal__container {
        width: 580px;
    }
    .header-modal,
    .footer-modal {
        padding: 0 16px;
    }
    .footer-modal {
        .tooltiptext {
            left: -15px;
            @include transform(translateX(0px));
        }
    }
    .body-modal {
        padding: 0;
        flex: 1;
    }
    .row-multi {
        @include betweenitems;
        &:not(.--to-cc) {
            padding: 16px;
            border-top: 1px solid $border-color-grey;
        }
        &.--to-cc {
            padding: 0px 16px 8px;
        }
        &.--email-cc {
            margin-top: -8px;
        }
        &.--email-to {
            .left-txt {
                width: 75px;
                flex: 0 0 auto;
            }
            &:not(.--sms-to) {
                .v2-dropdown {
                    &:not(.--add-email) {
                        max-width: calc(100% - 115px);
                        width: 100%;
                    }
                }
            }
        }
        &:not(.--email-to):not(.--sms-to) {
            .left-txt {
                width: 90px;
            }
        }
        &.--sms-to {
            .v2-dropdown {
                flex: 1;
            }
        }
        &.--email-send {
            .left-txt {
                width: 80px;
            }
            .v2-dropdown {
                flex: 1;
                overflow: hidden;
                .box-tags {
                    width: 100%;
                }
                .tag-label {
                    max-width: 100%;
                }
            }
        }
        .left-txt {
            @include flexcenter;
            @include font-color(24px, 14px, $txt-black);
            overflow: hidden;
            .txt-ellipsis {
                max-width: calc(100% - 32px);
            }
            svg {
                margin-right: 8px;
            }
        }
        .field-input {
            margin-left: 10px;
            flex: 1;
            padding: 6px;
            &::placeholder {
                color: $grey-soft-txt;
            }
            &[disabled] {
                cursor: unset;
            }
        }
        .v2-dropdown {
            &__menu {
                max-height: 220px;
            }
        }
    }
    .container-content-email {
        padding: 16px;
        min-height: 150px;
        line-height: 20px;
        color: $black-4;
        .form-rating {
            border-top: solid 1px $border-color-grey;
            margin: 20px 0px 10px;
            padding-top: 10px;
        }
        a:not(.button-send-mail):not(.btn-lg) {
            color: $purple-default;
        }
        &.field-textarea {
            min-height: 250px;
        }
        .rating-box {
            * {
                color: $black-4 !important;
            }
            td a {
                background-color: $white !important;
            }
        }
    }
    .attachment-file {
        padding: 0px 16px 16px;
    }
    .wrap-send-email {
        overflow-y: auto;
        @include flexcolumns;
    }
    // Send email/sms
    &.--mail-dialog {
        .tabs {
            .btn-item {
                margin-right: 8px;
            }
            .tab-items {
                @include flexcenter;
                padding: 0px 15px;
            }
        }
        .tab-content {
            .tab-panel.active {
                @include flexcolumns;
                overflow-y: auto;
            }
        }
        .container-content-email {
            white-space: pre-wrap;
        }
    }
}
.modal-find-qb {
    &.selected-qb-id {
        .btn-save-id {
            visibility: visible;
        }
    }
    .modal__container {
        width: 95%;
        max-width: 830px;
        &.--sm {
            width: 292px;
            white-space: normal;
        }
    }
    .header-modal {
        height: auto;
        padding: 16px 16px 8px;
    }
    .body-modal {
        padding: 0px;
        border: 0;
        overflow: auto;
        @include flexcolumns;
        .tables {
            @include flexcolumns;
            position: relative;
            .tables-list:not(.--hastotal) {
                overflow: overlay;
                min-height: 150px;
            }
            .rows {
                cursor: pointer;
                &.--header {
                    border-top: 1px solid $border-color-grey;
                    flex: 0 0 auto;
                }
                & + .rows {
                    margin-top: 0px;
                }
                &.selected {
                    background: $purple-extralight !important;
                }
            }
        }
    }
    .label-select-qb {
        padding: 8px 16px 16px;
        @include betweenitems;
        &__txt {
            @include font-color(32px, 14px, $grey-dark);
        }
    }
    .btn-save-id {
        visibility: hidden;
    }
}
// Modal Import Customer
.step-import-customer {
    @include flexcenter;
    margin: 18px 0 16px;
    .step-number {
        @include centeritem;
        @include size(40px);
        margin-right: 7px;
        background-color: $blue-default;
        border-radius: 50%;
        font-size: 22px;
        font-weight: 500;
        color: $white-default;
    }
    .step-title {
        text-transform: uppercase;
        font-size: 12px;
        color: $txt-black;
        font-weight: 500;
    }
    .step-description {
        font-size: 15px;
        color: $black-2;
        @extend .fw-600;
    }
}
.modal-import-customer {
    @include flexcolumns;
    width: 40%;
    min-width: 579px;
    .body-modal {
        display: flex;
        padding: 0;
        border-top: 0;
        background-color: $cosmic-latte;
        .text-purple {
            @include font-color(20px, 13px, $violet-name);
            font-weight: 500;
        }
        .col-import-left {
            width: calc(100% - 208px);
            padding: 20px 24px 21px;
            background-color: $white;
            .title {
                @include font-color(24px, 18px, $black-2);
                @extend .fw-600;
            }
            .description {
                margin-bottom: 15px;
                text-align: justify;
                @include font-color(20px, 13px, $black-3);
            }
            .outline-download-purple {
                margin-bottom: -6px;
            }
            .upload {
                margin-top: 23px;
                .choose-file {
                    @include flexinline;
                    height: 32px;
                    padding: 4px 10px 4px 4px;
                    border: 1px solid $grey-soft;
                    border-radius: 3px;
                    cursor: pointer;
                    span {
                        @include font-color(20px, 14px, $grey-dark);
                        margin-left: 4px;
                    }
                }
            }
            .notify {
                margin-top: 6px;
                line-height: 20px;
                .no-file-selected {
                    color: $date-color;
                }
                .filename {
                    display: none;
                    @include text-line-clamp(2);
                }
                &.uploaded {
                    .filename {
                        display: block;
                    }
                    .no-file-selected {
                        display: none;
                    }
                }
            }
        }
        .col-import-right {
            width: 208px;
            border-radius: 0 4px 0 0;
            background: url(#{$cdnPathImages}header/bg-upgrade.png) center bottom no-repeat;
            background-size: contain;
            background-color: #efefef;
            .content-right {
                padding: 19px 18px 0 31px;
            }
            .question {
                margin-bottom: 20px;
                @include font-color(24px, 15px, $black-2);
                @extend .fw-600;
            }
            .contact {
                @include font-color(20px, 14px, $black-2);
            }
            .support {
                @include flexcenter;
            }
        }
        .col-import-left,
        .col-import-right {
            min-height: 350px;
        }
    }
    .footer-modal {
        padding: 0 16px;
        span {
            padding: 6px 14px;
        }
    }
}
// Open Job details
.c-job-details {
    left: auto;
    width: auto;
    background-color: $white;
    > .modal__container {
        width: 970px;
        max-height: 100%;
        height: 100%;
        top: 0px;
        @include transform(none);
        border-radius: 0px;
        margin: 0px;
        > .body-modal {
            height: calc(100% - 56px);
            max-height: unset;
            padding: 0px;
            border-bottom: 0px;
            font-size: 14px;
            color: $black-2;
        }
        .modal-confirm-delete {
            .body-modal {
                border-bottom: none;
            }
        }
        > .header-modal {
            z-index: 1001;
        }
        &:has(> .footer-modal) {
            > .body-modal {
                height: calc(100% - 120px);
            }
        }
    }
    .header-modal {
        height: 56px;
        .action {
            @include flexcenter;
            &:has(.line + .line) {
                .line {
                    display: none;
                }
            }
        }
        .btn-action:not(.rows) {
            @include size(24px);
            @include flexcenter;
            border-radius: 4px;
            border: solid 1px $transparent;
            cursor: pointer;
            &:hover {
                filter: brightness(0.5);
                border-color: $border-color-grey;
            }
        }
        .btn-close:not(.footer-modal) {
            margin-left: 8px;
        }
        .v2-dropdown {
            &.active {
                z-index: 2;
            }
            .tooltiptext {
                min-width: 160px;
            }
        }
        .notification-dropdown {
            &.active {
                z-index: 2;
            }
        }
    }
    .wrap-checktime {
        @include flexcenter;
        > .v2-dropdown {
            margin-right: 8px;
        }
        .set-custom-time {
            padding: 18px;
        }
        .settime-box {
            left: 50%;
            @include transform(translateX(-50%));
        }
    }
    .v2-dropdown {
        &__menu {
            .line {
                height: auto;
            }
        }
    }
    .time-checked {
        min-width: 106px;
        &.btn-disabled {
            pointer-events: none;
            border-color: $background-input;
            background-color: $background-input;
            color: $background-circle;
            .current-time svg {
                opacity: 0.3;
            }
        }
    }
    .settime-box {
        left: 0;
        width: 275px;
        &-header {
            padding: 16px 24px 0px;
        }
        .set-custom-time {
            box-shadow: inset 0px 1px 0px $border-color-grey;
        }
        .set-custom-time,
        .save-time,
        .btn-reset-time {
            padding: 24px;
        }
        .wrapper-choosetime {
            margin-bottom: 12px;
            .dropbtn {
                padding: 0px 4px 0px 10px;
                > span:first-child {
                    @extend .fw-600;
                }
            }
        }
        .time {
            @include betweenitems;
            margin-bottom: 8px;
            &__number {
                @include font-color(24px, 16px, $black-eerie);
                @extend .fw-600;
            }
        }
        .time-title {
            font-size: 13px;
            color: #808080;
        }
        .v2-btn-main,
        .v2-btn-default {
            width: 100%;
            .outline-chevron-right {
                margin-left: 4px;
            }
        }
        .btn-reset-time {
            &.hidden {
                display: none;
            }
        }
    }
    .v2-dropdown {
        .current-time {
            display: none;
        }
        &:not(.save-time-active) {
            .save-time {
                display: none;
            }
        }
        &.reset-time-active {
            > .dropbtn {
                padding-right: 2px;
            }
            .current-time {
                width: 100%;
                @include betweenitems;
                div {
                    width: 100%;
                }
                svg {
                    margin-left: 2px;
                    margin-right: 5px;
                    @include size(24px);
                }
            }
            .text {
                display: none;
            }
        }
    }
    .list-job-status {
        .dropbtn {
            color: $white-default !important;
            width: auto;
            min-width: 160px;
        }
    }
    .contents {
        height: 100%;
        @include betweentop;
        .tab-contents:not(.content-services__right) {
            width: calc(100% - 232px);
            flex: 1;
            overflow-y: auto;
        }
        &__menu {
            width: 232px;
            height: 100%;
            padding: 24px 15px;
            border-left: 1px solid $grey-light-2;
            overflow-y: auto;
            @include flexcolumns;
        }
        .customer-name {
            @include flexcenter;
            margin-bottom: 24px;
            .txt-ellipsis {
                color: $purple-default;
                flex: 1;
            }
        }
        .line {
            border-top: 1px solid $border-color-grey;
            margin: 24px 0px;
        }
        .wrap-notify {
            justify-content: flex-end;
            margin-top: 20px;
            &__items {
                padding: 12px 10px;
                border-radius: 4px;
                background-color: $black-light;
                width: 100%;
                .check-items {
                    label {
                        line-height: 16px;
                    }
                }
                .tooltiptext {
                    left: -58px;
                    max-width: 200px;
                    z-index: 1002;
                }
                .v2-dropdown__menu {
                    z-index: 1002;
                }
            }
        }
    }
    &:has(.svg-calendar.active-tab-selector) {
        &:has(.wrap-notify:not(:empty)) {
            .boxs-sms {
                bottom: 115px;
            }
        }
    }
    .tab-selectors {
        .tab-items {
            @include flexcenter;
            color: $grey-extrlight;
            font-weight: 500;
            min-height: 32px;
            padding: 4px;
            margin-bottom: 4px;
            border-radius: 4px;
            cursor: pointer;
            svg {
                margin-right: 10px;
                pointer-events: none;
                flex: 0 0 auto;
            }
            &.svg-plus svg {
                opacity: 0.5;
            }
            &.active-tab-selector,
            &:hover {
                color: $grey-extradark;
                background-color: $yellow-light;
                svg {
                    opacity: 1;
                }
            }
            &.active-tab-selector:not(.svg-noeffect),
            &:hover:not(.svg-noeffect) {
                svg {
                    path {
                        fill: $purple-light;
                    }
                    path:last-child,
                    rect {
                        fill: $purple-default;
                    }
                }
            }
            & + .line:last-child {
                display: none;
            }
        }
        .unique {
            font-size: 11px;
            font-weight: 500;
            height: 16px;
            border-radius: 6px;
            padding: 0px 3px;
            line-height: 16px;
            margin-left: 6px;
            @include text-ellipsis;
        }
    }
    .tab-conts {
        height: 100%;
    }
    .details-job {
        padding: 16px 24px 24px;
        background: $white;
        &:not(.--has-sevice) {
            .rows {
                @include betweentop;
            }
        }
        > *:not(.messaging-preferences) {
            .valid-phone {
                margin-bottom: 0;
                & + .valid-phone {
                    margin-top: 4px;
                }
            }
        }
        .rows {
            &:not(.row-summary) {
                .txt {
                    height: 32px;
                }
            }
            &.has-line-bottom {
                box-shadow: inset 0px -1px 0px $border-color-grey;
                padding-bottom: 16px;
                margin-bottom: 16px;
            }
            .txt {
                @include flexcenter;
                color: $granite-gray;
                width: 160px;
                svg {
                    margin-right: 4px;
                    flex: 0 0 auto;
                }
            }
            .details {
                width: calc(100% - 160px);
            }
            .details-repeat-end {
                @include flexwrap;
                position: relative;
                &.active-ondate {
                    .monthly-calendar {
                        display: block;
                    }
                    .field-every {
                        display: none;
                    }
                }
                &.active-after {
                    .monthly-calendar {
                        display: none;
                    }
                    .field-every {
                        display: flex;
                    }
                }
                .field-every {
                    margin-left: 12px;
                }
                .tabs-daily {
                    position: relative;
                }
                .monthly-calendar {
                    display: none;
                    position: absolute;
                    top: 0px;
                    left: 100%;
                    z-index: 1;
                    border: solid 1px $border-color-grey;
                    background: $white;
                    border-radius: 8px;
                    padding: 0 1px 4px;

                    > div > .react-datepicker {
                        max-width: 280px;
                        min-width: 50px;
                        margin-left: 0px;
                    }
                    .react-datepicker {
                        &__month-container {
                            padding: 0px;
                        }
                        .datepicker-header-custom {
                            margin: 0px;
                        }
                        &__day {
                            &,
                            &-name {
                                width: 36px;
                            }
                        }
                        .datepicker-selected {
                            & > .items {
                                padding: 0;
                            }
                            .v2-dropdown__menu {
                                height: 220px;
                            }
                        }
                    }
                }
            }
            .info {
                &.--single {
                    line-height: 32px;
                }
            }
        }
        .detail-service {
            @include flexcenter;
            .select-emoji {
                margin: 0 4px 0 2px;
            }
            .emoji-content {
                font-size: 16px;
            }
        }
        .detail-customer {
            color: $purple-default;
            .customer-name {
                &__info {
                    width: 100%;
                    margin-top: 6px;
                }
            }
            .cus-name {
                cursor: pointer;
            }
            .cus-account {
                color: $grey-generic;
                font-size: 11px;
            }
            a:not(.info) {
                display: block;
            }
            .info {
                margin-top: 2px;
                line-height: 22px;
                @include flexcenter;
            }
        }
        .right-icon {
            position: absolute;
            right: 0px;
        }
        .schedule-time {
            min-height: 32px;
            @include flexwrap;
            margin-top: 4px;
            margin-bottom: 4px;
            .info-time {
                width: 100%;
                & + .info-time {
                    margin-top: 8px;
                }
                &__svg {
                    margin-left: 6px;
                    @include size(24px);
                    @include centeritem;
                    border-radius: 50%;
                    background: $blue-alice-light;
                }
                &:not(.--repeat) {
                    .info-time__label {
                        @include text-ellipsis;
                        max-width: calc(100% - 70px);
                    }
                }
                &.--repeat {
                    .info-time__label {
                        background: $blue-alice-light;
                        color: $blue-default;
                        border-radius: 4px;
                        word-break: break-word;
                        min-height: 24px;
                        padding: 0px 6px 0px 2px;
                    }
                    svg {
                        margin-right: 2px;
                        path {
                            stroke: $blue-default;
                        }
                    }
                }
            }
        }
        .info-time {
            @include flexcenter;
        }
        .detail-location {
            line-height: 20px;
            &__box {
                @include flexwrap;
                justify-content: space-between;
            }
            .location-note {
                width: 100%;
                margin: 12px 0 20px;
                &:not(.has-edit) {
                    background: $blue-alice-light;
                    border-radius: 4px;
                    padding: 8px 8px 8px 12px;
                    .wrap-content {
                        display: none;
                    }
                }
                &.has-edit {
                    .wrap-description {
                        display: none;
                    }
                }
                .wrapbox-editor {
                    &__header {
                        border-bottom: 1px solid $border-color-grey;
                        padding: 12px;
                    }
                }
                &-content {
                    word-break: break-word;
                    white-space: break-spaces;
                }
            }
            .name-label {
                color: $black-eerie;
                font-weight: 500;
                margin: 6px 0px;
                max-width: 100%;
                width: fit-content;
                word-break: break-word;
            }
            .info-label {
                color: $purple-default;
            }
            .location-info {
                flex: 1;
                width: calc(100% - 200px);
                word-break: break-word;
            }
            .location-image {
                margin: 0px 16px;
                &__view {
                    width: fit-content;
                    cursor: pointer;
                }
                img {
                    width: auto;
                    max-width: 130px;
                    object-fit: cover;
                    border-radius: 4px;
                }
            }
            .v2-dropdown:not(.dropdown-location) {
                height: 32px;
                .v2-dropdown__menu {
                    min-width: 195px;
                    width: max-content;
                    max-width: 300px;
                }
            }
            .right-icon {
                top: 5px;
            }
            .additional-contact {
                position: relative;
                margin-top: 10px;
                width: calc(100% - 36px);
                min-height: 32px;
                &::before {
                    content: '';
                    box-shadow: inset 0px -1px 0px $border-color-grey;
                    width: 48px;
                    height: 1px;
                    display: inline-block;
                    position: absolute;
                    top: -10px;
                }
                &.has-all-contact {
                    .btn-more {
                        svg {
                            @include transform(rotateZ(180deg));
                            margin-top: 2px;
                        }
                    }
                }
                &:not(.has-all-contact) {
                    .additional-contact__items:not(:first-child) {
                        display: none;
                    }
                }
                &__items {
                    & + .additional-contact__items {
                        margin-top: 10px;
                    }
                }
                .name-label {
                    max-width: calc(100% - 40px);
                }
                .info-label {
                    @include text-ellipsis;
                    margin-top: 4px;
                }
            }
            .btn-more {
                margin-top: 6px;
                margin-left: -10px;
                color: $granite-gray;
            }
        }
        .v2-btn-default {
            &.just-icon {
                .tooltiptext {
                    right: -20px;
                }
            }
        }
        &__elm {
            & + .detail-plan {
                margin-top: 15px;
                border-top: 1px solid $border-color-grey;
            }
        }
    }
    .v2-dropup,
    .v2-dropdown {
        &--more {
            .v2-dropdown__menu {
                .items {
                    font-weight: 500;
                }
            }
        }
        &--more,
        &.--more {
            > .dropbtn {
                @include size(32px);
                @include centeritem;
                border-radius: 12px !important;
                &:hover {
                    border-color: $btn-hover-black;
                }
            }
        }
    }
    .select-service {
        min-width: 250px;
        max-width: 300px;
    }
    .select-locations {
        .v2-dropdown__menu {
            width: 300px;
            max-height: 260px;
            height: auto;
        }
    }
    .details-note {
        border-top: 1px solid $border-color-grey;
        background-color: $bg-body;
    }
    .content-top-notes {
        padding: 24px;
        .boxs {
            scroll-margin-top: 24px;
            margin-left: 40px;
            padding-top: 10px;
            &.--new-note {
                margin-top: 15px;
            }
            & + .rows-load-more {
                margin-left: 40px;
            }
        }
        .header-action {
            padding-left: 40px;
            padding-bottom: 12px;
            @include betweenitems;
            gap: 8px;
            flex-wrap: wrap;
            .v2-btn-default {
                background-color: $white;
                & + .v2-btn-default {
                    margin-left: 8px;
                }
            }
        }
        .header-box {
            .tooltiptext {
                max-width: 155px;
            }
        }
        .time-range {
            padding-left: 40px;
        }
        @media screen and (max-width: 768px) {
            padding: 20px;
        }
    }
    .boxs-sms {
        z-index: 1001;
        .conversation-sms {
            bottom: 5px;
            height: calc(100% - 65px);
            .wrap-conversation-contents {
                margin-bottom: 51px;
            }
        }
        .wrap-conversation-contents {
            &.overlay {
                margin-bottom: 246px;
                &:has(.title-message-chat + .title-message-chat) {
                    margin-bottom: 286px;
                }
            }
        }
    }
    .container-column {
        .header-modal {
            .cancel-action + .v2-btn-main {
                margin-left: 15px;
            }
            .v2-dropdown--more {
                > .v2-dropdown__menu {
                    width: max-content;
                    max-width: 320px;
                    min-width: 200px;
                }
            }
            .name-tabs {
                font-size: 18px;
                @extend .fw-600;
                max-width: 60%;
                flex: 1;
                @include flexcenter;
                .txt-ellipsis {
                    flex: 1;
                }
            }
        }
    }
    .detail-repeaton {
        .btn-item {
            border: 0px;
        }
        .tab-items {
            width: 28px;
            padding: 0px;
            + .tab-items {
                margin-left: 4px;
            }
        }
    }
    .row-summary {
        align-items: flex-start !important;
        height: auto !important;
        .details,
        .detail {
            line-height: 23px;
            font-weight: 500;
        }
        .txt-summary {
            line-height: 20px;
            &:first-child {
                margin-top: 2px;
            }
        }
    }
    .boxs-materials {
        &__form {
            display: none;
            padding-bottom: 24px;
            .rows + .rows {
                margin-top: 20px;
            }
        }
        &.is-editing {
            .boxs-materials__form {
                display: block;
            }
            .boxs-materials__completed {
                display: none;
            }
        }
        .wrap-material {
            &:last-child {
                border-bottom: none;
            }
        }
        .rows-load-more {
            padding: 8px 12px;
        }
    }
    .modal-confirm-delete {
        .footer-modal {
            justify-content: flex-end;
            border-top: 1px solid $border-color-grey;
        }
    }
    .wrap-commission {
        background: light-dark(#f1faee, #1e320f);
        border-radius: 4px;
        padding: 4px 8px 8px;
        margin: 0 0 16px 12px;
        .status-btn {
            padding: 0 3px;
            height: 20px;
            line-height: 20px;
        }
    }
    .header-template {
        padding: 12px 23px 12px 16px;
        box-shadow: inset 0px -1px 0px $border-color-grey;
        @include betweenitems;
        &__title {
            @include flextop;
            @include font-color(24px, 16px, $black);
            @extend .fw-600;
            width: calc(100% - 210px);
            svg {
                margin-right: 8px;
            }
            .list-tags {
                width: calc(100% - 32px);
                .tag-label {
                    max-width: 200px;
                    min-width: unset;
                    display: block;
                }
            }
        }
        .v2-btn-default {
            margin-left: 8px;
        }
        > .v2-dropdown {
            width: 100px;
            .v2-dropdown__menu {
                width: max-content;
                right: 0px;
            }
        }
        .has-label {
            @include flexcolumns;
            &__invoice {
                cursor: pointer;
                color: $purple-default;
                font-size: 11px;
                font-weight: 400;
                line-height: 15px;
                width: max-content;
            }
        }
        &:has(.tag-label) {
            align-items: flex-start;
            .header-template__title > * {
                min-height: 32px;
            }
            .is-divider {
                margin-top: 8px;
            }
            .txt-ellipsis:not(.tag-label) {
                line-height: 32px;
            }
        }
    }
    .wrap-action {
        &__conts {
            padding: 12px 23px 12px 35px;
            @include betweenitems;
            .list-invoice-status {
                min-width: 160px;
                width: fit-content;
                margin-right: 8px;
                & + .v2-btn-main {
                    margin-right: 8px;
                }
            }
        }
        .btn-invoice-status {
            height: 32px;
            line-height: 32px;
            min-width: 120px;
            text-align: left;
        }
        .form-edit-template {
            position: relative;
            height: calc(100dvh - 57px);
            .footer-modal {
                z-index: 2;
            }
        }
    }
    .wrap-info {
        padding: 0px 16px 24px 35px;
    }
    .box-information {
        margin-top: 24px;
        @include betweentop;
        color: $black;
        font-size: 13px;
        &__items {
            width: 33%;
            word-break: break-word;
            &.--center {
                margin: 0px 15px;
            }
            .name {
                line-height: 24px;
            }
            .info {
                color: $purple-default;
                line-height: 20px;
            }
        }
        .rows-sm {
            @include flexcenter;
            & + .rows-sm {
                margin-top: 2px;
            }
            .icon {
                opacity: 0.7;
                margin-right: 4px;
                width: 24px;
                text-align: center;
            }
            .detail {
                line-height: 24px;
                width: calc(100% - 28px);
                .txt-ellipsis {
                    display: block;
                }
            }
        }
        .logo-img img {
            object-fit: contain;
            max-height: 100px;
            width: 100%;
        }
    }
    .rows-has-table {
        border-top: 1px solid $border-color-grey;
    }
    .is-sticky {
        box-shadow: inset 0px 1px 0px $border-color-grey;
        justify-content: flex-start;
        position: sticky;
        bottom: 0;
        z-index: 2;
        background: $white;
    }
    .has-existing-notes {
        .wrapper-pane-sub__content {
            right: 680px;
        }
    }
    .body-modal {
        .show-repeat-inv {
            display: flex;
            flex-direction: column;
            .form-edit-template__content + .footer-modal {
                display: none;
            }
            .alert + .form-has-content {
                .form-edit-template {
                    height: 100%;
                    .form-invoice-repeat {
                        height: calc(100dvh - 115px) !important;
                    }
                }
            }
            &:has(.alert) {
                .footer-form {
                    position: sticky;
                }
            }
        }
    }
    &:has(.contents__menu) {
        @media (max-width: 1024px) {
            position: absolute;
            overflow-x: visible;
            .modal__container {
                overflow: hidden;
            }
            .contents__menu {
                position: static;
                border-left: 1px solid $grey-light-2;
                height: inherit;
                &::after {
                    display: none;
                }
            }
            .tab-contents {
                margin-right: 0px !important;
            }
        }
    }
    &.add-new-job {
        @media (max-width: 1024px) {
            overflow-x: auto;
            width: 100%;
            .modal__container {
                overflow: hidden;
                margin-left: auto;
            }
            .contents__menu {
                position: static;
                border-left: 1px solid $grey-light-2;
                height: inherit;
                &::after {
                    display: none;
                }
            }
            .tab-contents {
                margin-right: 0px !important;
            }
        }
    }
    &.modal:not(.add-new-job):not(:has(.modal__container.--preview-r)) {
        @media (max-width: 1024px) {
            height: 100dvh;
            min-width: 1024px;
            @include flexend;
        }
    }
    .content-services,
    .wrap-new-services {
        .form-add-estimate {
            .container-column {
                > .sm-row {
                    @include flexcenter;
                    margin-bottom: 15px;
                    padding-left: 10px;
                    .detail {
                        width: 255px;
                    }
                    .txt {
                        width: 115px;
                        color: $date-color;
                        font-size: 13px;
                        @include flexcenter;
                        font-weight: 400;
                        & > svg {
                            margin-right: 6px;
                        }
                    }
                }
            }
        }
    }
    .list-crew {
        display: flex;
        max-width: 100%;
        & > * {
            margin-top: 4px;
            &:not(:first-child) {
                margin-left: -6px;
            }
            &:not(:last-child) {
                border: 1px solid $white;
            }
        }
        .avt-img img {
            min-width: 24px;
        }
        .avt-more {
            background-color: $grey-verylight;
            justify-content: center;
            border: 1px solid $white;
        }
        .tech-name {
            width: calc(100% - 270px);
            flex: 1;
        }
    }
    .list-note-templates {
        & > .v2-dropdown__menu {
            top: unset;
            bottom: calc(100% + 4px);
            &:not(:has(.items-template:nth-of-type(4))) {
                .list-filter {
                    .v2-dropdown__menu {
                        top: unset;
                        bottom: calc(100% + 4px);
                    }
                }
            }
        }
    }
    &:not(.add-new-job):not(:has(.svg-calendar.active-tab-selector)) {
        .wrap-notify {
            display: none;
        }
        .boxs-sms {
            bottom: 16px;
        }
    }
    // Best time
    .detail-available-time {
        .btn-bg-purple {
            min-width: 68px;
        }
        .time-control {
            width: 290px;
        }
        .time-items {
            overflow: hidden;
            border: 1px solid var(--color-best-time);
            border-left: 3px solid var(--color-best-time);
            border-radius: 4px;
            color: $black-darker1;
            height: 32px;
            padding: 0 8px;
            position: relative;
            cursor: pointer;
            .check-radio {
                width: 24px;
                &__label {
                    &::before {
                        border-radius: 50%;
                        top: -13px;
                    }
                    &::after {
                        border-radius: 50%;
                        top: -9px;
                    }
                }
            }
            &__info {
                max-width: 240px;
                @include text-ellipsis;
                margin-right: 4px;
            }
            &__name {
                max-width: 140px;
            }
            & > * {
                position: relative;
                line-height: 18px;
            }
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                @include size(100%);
                opacity: 0.06;
                background-color: var(--color-best-time);
            }
            &:has(input[type='radio']:checked) {
                color: $white;
                background-color: var(--color-best-time);
                border-color: var(--color-best-time);
                .check-radio__label {
                    &::before {
                        border-color: var(--color-best-time);
                    }
                    &::after {
                        background-color: var(--color-best-time);
                    }
                }
            }
        }
        .cost-input {
            width: 192px;
            &::after {
                background-color: $black-lighter;
                border-left: 1px solid $grey-soft;
            }
        }
    }
    &:not(.add-new-job) {
        .detail-plan__items {
            .list-techs {
                width: calc(100% - 285px);
            }
        }
    }
    .set-confirm {
        @include text-ellipsis;
    }
}
// add new job
.add-new-job {
    > .modal__container {
        > .body-modal {
            height: calc(100% - 120px);
            .tab-contents {
                > .alert {
                    display: block;
                }
            }
            .header-service {
                display: none;
            }
        }
        > .footer-modal {
            background: $white;
            box-shadow: inset 0px 1px 0px $border-color-grey;
        }
        .details-job {
            padding: 24px;
            .details-job__content > .rows,
            .tab-content .tab-panel > .rows,
            .detail-plan__items > .rows,
            > .rows {
                display: flex;
                justify-content: flex-start;
                .details {
                    &.--multi {
                        @include betweenitems;
                    }
                    &.detail-select-date {
                        width: 190px;
                        .field-input {
                            padding-right: 30px;
                        }
                    }

                    .location-add,
                    .title-action {
                        @include betweentop;
                    }
                    .location-add {
                        margin-top: 7px;
                        color: $grey-extrlight;
                        .description {
                            max-width: calc(100% - 140px);
                        }
                        .v2-btn-default {
                            margin-top: 4px;
                        }
                    }
                    width: calc(100% - 173px);
                }
                .txt {
                    width: 173px;
                    .txt-ellipsis {
                        max-width: calc(100% - 35px);
                    }
                    &.no-icon {
                        padding-left: 30px;
                    }
                }
                .list-job-status {
                    .v2-dropdown__menu {
                        max-height: 215px;
                    }
                }
            }
            .details-job__content > .rows,
            .tab-content .tab-panel > .rows,
            > .rows {
                & + .rows {
                    margin-top: 16px;
                }
            }
            .row-except-month {
                .col-haft {
                    width: 40%;
                    color: $black-3;
                    font-size: 13px;
                }
                .check-items {
                    & + .check-items {
                        margin-top: 6px;
                    }
                }
            }
            .row-has-line:not(.tab-content) {
                padding-top: 16px;
            }
            .tabs-daily {
                height: auto;
            }
        }
        .v2-dropdown {
            &.--large {
                width: 300px;
                .search-input {
                    input::placeholder {
                        color: $grey-extradark;
                    }
                }
            }
            &:not(.list-job-status) {
                &.items {
                    color: $black-2;
                }
            }
        }
        .txt-service {
            width: 300px;
            padding: 0 8px;
            border: 1px solid $grey-soft;
            line-height: 30px;
            border-radius: 4px;
            box-shadow: $boxshadow-grey-light;
        }
        .contents {
            .line {
                border-top-color: $border-color-grey;
                margin: 16px 0;
            }
        }
        .contents__menu {
            .line {
                margin: 20px 0;
            }
        }
        .tab-selectors {
            .tab-items {
                &.active-tab-selector,
                &:hover {
                    background-color: $yellow-light;
                    border-radius: 4px;
                }
            }
        }
        .--assign-to,
        .detail-time,
        .detail-user {
            @include flexcenter;
        }
        .schedule-wrapper {
            .detail-toggle {
                height: auto;
                .txt-ellipsis {
                    color: $txt-black;
                }
            }
        }
        .rows-notes {
            .dashboard-wrapper {
                .wrap-content {
                    box-shadow: none;
                }
                .public-DraftEditor-content {
                    min-height: 70px !important;
                }
            }
        }
        .dropdown-assign {
            .v2-dropdown__menu.content-user {
                width: 320px;
                max-height: 350px;
                height: auto;
                right: 0;
                font-weight: 500;
            }
        }
        .nav-tabs {
            border: 0;
        }
        .tab-content {
            padding-bottom: 16px;
            .rows {
                margin-top: 16px;
            }
        }
        .details-job__content {
            &.is-disable {
                opacity: 0.3;
            }
        }
        .v2-dropdown.disable {
            opacity: 1;
        }
        .field-input {
            color: $black-2;
        }
        .form-invoice-repeat {
            height: calc(100dvh - 185px) !important;
            .tab-content {
                margin-bottom: 0px !important;
            }
            .details-job > .rows {
                margin-top: 0px !important;
            }
        }
        .detail-schedule {
            &.field-error {
                width: auto;
                max-width: fit-content;
                .v2-btn-default svg path {
                    fill: $red-default;
                }
            }
        }
        &.job-detail-container {
            .schedule-wrapper {
                & > .rows {
                    opacity: 0.6;
                    pointer-events: none;
                }
            }
        }
    }
    .wrap-new-services {
        &__right {
            margin-right: 0px !important;
        }
        .header-title {
            display: none;
        }
        > .sidebar-pages {
            border-right: none;
            border-left: solid 1px $border-color-grey;
            padding: 24px 15px !important;
            width: 232px;
        }
        .is-todolist {
            padding: 12px 24px;
        }
    }
}
// Custom css for Add job on Calendar
.fc-dayGridMonth-view {
    .fc-scrollgrid-liquid > tbody:last-child > tr:first-child > td:first-child {
        padding: 0px;
    }
}
// For add job/time off
.wrap-addjob {
    background-color: rgba(141, 74, 252, 0.2);
    border: 2px solid $purple-medium2;
    border-radius: 3px;
}
.wrap-addjob-menu {
    z-index: 999;
    min-width: 200px;
    width: fit-content;
    color: $grey-dark;
    background-color: $white;
    box-shadow: $boxshadow-dropdown-main;
    padding: 2px;
    border-radius: 4px;
    &__items {
        height: 40px;
        @include flexcenter;
        padding: 8px;
        cursor: pointer;
        svg {
            margin-right: 6px;
        }
        & + .wrap-addjob-menu__items {
            margin-top: 2px;
        }
        .txt-ellipsis {
            width: calc(100% - 30px);
        }
        &:hover {
            background-color: $yellow-light;
            border-radius: 4px;
            color: $grey-dark;
        }
    }
}
// End add job/time off
.modal-document {
    width: 590px;
    .header-modal {
        height: 64px;
        padding: 0px 25px;
    }
    .body-modal {
        padding: 0px;
        font-size: 14px;
        .has-form {
            padding: 12px 25px;
            border-bottom: 1px solid $border-color-grey;
            .row-haft {
                align-items: flex-start;
                .col {
                    flex: 1;
                    & + .col {
                        margin-left: 16px;
                    }
                }
            }
            .col-location {
                max-width: 70%;
            }
            .col-customer {
                max-width: calc(50% - 8px);
            }
        }
        .search-form {
            padding: 0px 25px;
            height: 56px;
            @include flexcenter;
            &:has(.tabs) {
                border-bottom: 1px solid $border-color-grey;
            }
        }
        .document-list {
            border-top: 1px solid $border-color-grey;
            .document-rows {
                @include flexcenter;
                padding: 0px 25px;
                height: 56px;
                border-bottom: solid 1px $border-color-grey;
                &:last-child {
                    border-bottom: 0;
                }
                &.active {
                    background-color: $bg-body;
                    .action-document {
                        .add-docs {
                            display: none;
                        }
                        .remove-docs {
                            display: block;
                        }
                    }
                }
                .action-document {
                    width: 110px;
                }
                .add-docs {
                    display: inline;
                }
                .remove-docs {
                    display: none;
                    .v2-btn-default {
                        color: $orange-default;
                        border: none !important;
                        text-transform: capitalize;
                        svg path {
                            stroke: $orange-default;
                        }
                    }
                }
                .docs-name {
                    width: calc(100% - 110px);
                    @include flexcenter;
                    .name {
                        @include text-ellipsis;
                        font-weight: 500;
                        color: $black-2;
                    }
                    &:has(.budget) {
                        max-width: calc(100% - 40px);
                    }
                }
            }
            .alert {
                max-width: calc(100% - 48px);
            }
        }
        .v2-dropdown {
            z-index: 2;
            .dropbtn {
                width: 100%;
                font-weight: normal;
                font-size: 13px;
                color: $grey-extradark;
                &.has-icon {
                    padding-left: 2px;
                }
            }
        }
    }
    &--jobs {
        width: 630px;
        @include transform(none);
        top: unset;
        margin-right: 5px;
        border-radius: 4px;
        .body-modal {
            max-height: calc(100dvh - 130px);
            border: 0;
            &__head {
                @include betweenitems;
                padding: 0px 16px;
            }
            .search-form {
                flex: 1;
                padding: 0px;
            }
            .document-list {
                .document-rows {
                    padding: 0px 16px;
                }
            }
        }
        &:has(.document-rows:nth-of-type(20)) {
            height: 100%;
            max-height: 1177px;
            .body-modal {
                max-height: 100% !important;
            }
        }
    }
    &:not(.modal-document--jobs) {
        .body-modal {
            @include flexcolumns;
            .has-form,
            .search-form {
                flex: 0 0 auto;
            }
            .document-list {
                flex: 1;
                overflow-y: auto;
                max-height: 1100px;
            }
        }
    }
}
// modal report subscription
.form-stripe-subscription {
    &:not(.--form-cancel) {
        .modal__container {
            width: 640px;
            height: 100%;
            max-height: 100%;
            top: 0;
            border-radius: 0;
            margin: 0;
            margin-left: auto;
            @include transform(none);
            @media screen and (max-width: 768px) {
                width: 100%;
                box-shadow: none;
            }
        }
        .header-modal {
            padding: 12px 20px;
            height: 56px;
            .btn-back {
                margin-right: 8px;
            }
        }
        .body-modal {
            padding: 8px 0 0;
            max-height: none;
            > .alert {
                max-width: calc(100% - 40px) !important;
                margin-bottom: 15px;
            }
        }
    }
    &.--form-main {
        .body-modal {
            border-top: 1px solid $border-color-grey;
            .billing-content {
                padding: 39px 24px 0;
            }
        }
    }
    &.--add-plan {
        .header-modal {
            .header-modal__label {
                max-width: 120px;
            }
        }
        .alert {
            margin: 0px 20px;
            width: calc(100% - 40px);
        }
    }
    .search-input {
        width: 250px;
    }
    .btn-action {
        @include flexcenter;
        & > *:not(:last-child):not(.btn-tax) {
            margin-right: 8px;
        }
    }
    .body-modal {
        padding: 24px;
        border: none;
    }
    .user-info {
        padding: 0 20px;
        @include font-color(20px, 13px, $grey-dark);
        .avt {
            margin-right: 6px;
            background-color: $orange-peel;
            &:not(.loading)::before {
                background: transparent;
            }
        }
        .flexinline-center {
            max-width: 100%;
        }
        .name {
            @include text-ellipsis;
        }
        .info {
            display: inline-block;
        }
        &__details {
            font-size: 14px;
            margin-top: 16px;
            letter-spacing: 0.01em;
        }
        .rows {
            margin-top: 8px;
            &__label {
                width: 200px;
                color: $grey-generic;
            }
            &__decs {
                flex: 1;
                color: $black-eerie;
                @include text-ellipsis;
                &.--empty {
                    color: $grey-medium;
                }
            }
        }
    }
    .v2-btn-default {
        background-color: $white;
        &.--added {
            opacity: 0.6;
            pointer-events: none;
        }
    }
    .value-empty {
        color: $grey-soft-txt;
    }
    .rows {
        &.--modify {
            @include betweenitems;
        }
        &.--has-field {
            padding-right: 20px;
        }
        &.--flexend {
            @include flexcolumns;
            align-items: flex-end;
            padding-bottom: 0;
            padding-right: 12px;
        }
        &.--wrap {
            @include flexwrap;
            border-bottom: 1px solid $border-color-grey;
            .col {
                &.--sm {
                    min-width: 100px;
                    width: auto;
                    max-width: 33%;
                }
                &.--stt {
                    min-width: 80px;
                    max-width: 33%;
                }
                &.--time {
                    text-align: right;
                    overflow: revert;
                }
            }
        }
        &__label {
            @include flexcenter;
            height: 32px;
            svg {
                margin-right: 8px;
            }
        }
        &:not(.--wrap) {
            .col {
                width: 180px;
            }
        }
        .col {
            &:not(:first-child) {
                padding-left: 8px;
            }
            &.--md {
                width: 235px;
            }
            &.--qty {
                width: 65px;
            }
            &.--remove {
                flex: 1;
                @include flexend;
            }
            &.--details {
                color: $txt-black;
            }
            &.--sm {
                width: 100px;
            }
            .react-datepicker-wrapper {
                .svg-calendar-absolute {
                    top: 4px;
                    left: 3px;
                    right: unset;
                }
                .svg-clear-date {
                    position: absolute;
                    top: 5px;
                    right: 3px;
                    cursor: pointer;
                }
                .field-input {
                    padding: 0 24px 0 28px;
                }
            }
        }
    }
    .plans-content {
        margin-top: 20px;
        @include font-color(20px, 13px, $grey-extradark);
        .header {
            @include betweenitems;
            padding: 0 24px 10px;
            &__link {
                @include text-ellipsis;
                flex: 1;
                text-align: right;
                margin-left: 20px;
            }
        }
        .rows {
            padding: 16px 24px;
            border-top: 1px solid $border-color-grey;
            &.--header {
                padding-top: 13px;
                padding-bottom: 13px;
            }
            &.has-removed {
                .--qty .field-input {
                    opacity: 0.5;
                    pointer-events: none;
                }
            }
            .title {
                @extend .fw-600;
            }
            .col {
                &.--qty {
                    .field-input {
                        width: 45px;
                        padding: 0 6px;
                        color: $grey-dark;
                    }
                }
            }
            .wrap-col {
                width: calc(100% - 188px);
                &__content {
                    display: flex;
                    .col.--md {
                        line-height: 32px;
                    }
                }
                .col:not(:first-child) {
                    padding-left: 0;
                }
                .col:not(:last-child) {
                    padding-right: 8px;
                }
                .btn-action {
                    justify-content: flex-end;
                }
                .line {
                    border-top: 1px solid $grey-softlight;
                    margin: 10px 0;
                }
            }
            .wrap-btn {
                display: none;
                & > *:not(:last-child) {
                    margin-right: 2px;
                }
                .v2-btn-default,
                .v2-btn-main {
                    flex: 0 0 auto;
                }
            }
            .field-date {
                padding-left: 0px;
                width: 100%;
            }
            &.--modify {
                align-items: flex-start;
            }
        }
        .col-tax-info {
            min-height: 34px;
            &.is-edit {
                .elm-save-tax,
                .btn-tax {
                    display: none;
                }
                .wrap-btn {
                    @include flexcenter;
                    .cost-input::before {
                        border-color: $grey-soft;
                    }
                }
            }
            &.is-saved {
                .wrap-btn,
                .btn-tax {
                    display: none;
                }
                .elm-save-tax {
                    @include flexcenter;
                }
            }
            .elm-save-tax {
                display: none;
            }
        }
    }
    .plan-list {
        @include font-color(20px, 13px, $grey-extradark);
        .rows {
            padding: 16px 20px;
            background-color: $white;
            border-bottom: 1px solid $border-color-grey;
            @include flextop;
            &.selected {
                background-color: $yellow-extralight;
            }
        }
        .col {
            &.--icon {
                width: 32px;
                line-height: 31px;
            }
            &.--action {
                width: 90px;
                @include flexend;
            }
            &.--price {
                color: $grey-dark;
                width: 140px;
            }
        }
    }
    .create-plan {
        .rows {
            padding-left: 24px;
            & + .rows {
                margin-top: 16px;
            }
            &.--price {
                .field-amount {
                    padding: 0px 10px;
                    right: 1px;
                    border-radius: 0 4px 4px 0;
                    border: 1px solid $grey-greenish-light;
                    &:hover {
                        border-color: $grey-soft;
                    }
                }
                .v2-dropdown {
                    &.active .dropbtn {
                        box-shadow: none;
                        font-weight: 500;
                    }
                    &__menu {
                        max-width: 150px;
                        right: 0px;
                        top: calc(100% + 3px);
                    }
                }
            }
            &__label {
                flex: 0 0 auto;
                width: 200px;
                @include font-color(24px, 14px, $txt-black);
            }
            &.--custom-interval {
                .v2-dropdown {
                    width: 180px;
                    flex: 0 0 auto;
                }
            }
            .field-input,
            .v2-dropdown {
                flex: 1;
            }
            .v2-dropdown {
                &.is-disable .dropbtn {
                    background-color: $grey-extralight;
                }
            }
        }
    }
    .billing-content {
        .title-x-sm {
            margin-bottom: 8px;
        }
        .subscription-total {
            padding: 13px 0px;
        }
        .rows {
            padding: 16px;
            @include border($grey-soft, 4px, 'null');
            cursor: default;
            & + .rows {
                margin-top: 16px;
            }
            .check-radio {
                @include font-color(20px, 14px, $grey-dark);
                &__label {
                    line-height: 20px;
                    @extend .fw-600;
                    margin-right: 0px;
                }
                .tag {
                    height: 20px;
                    line-height: 20px;
                    font-size: 11px;
                    font-weight: 400;
                    padding: 0 5px;
                    margin-left: 7px;
                }
            }
            .desc {
                margin-top: 8px;
                margin-left: 24px;
                @include font-color(20px, 13px, $grey-dark);
                overflow: visible;
            }
            &.active {
                border-color: $purple-medium;
                &.pay-manually {
                    padding-right: 10px;
                    padding-bottom: 10px;
                }
            }
            &:not(.active) {
                .desc {
                    display: none;
                }
            }
            .branch {
                width: auto;
                max-width: 200px;
            }
            .pay-day {
                margin-top: 8px;
                .field-input {
                    width: 44px;
                    padding: 0 6px;
                    margin: 0px 4px;
                    &::placeholder {
                        color: $grey-soft-txt;
                    }
                }
            }
        }
    }
}
.dashboard-job-detail {
    &:has(.messaging-preferences.--wrap) {
        .details-wrap {
            > .details-job {
                padding: 0px;
            }
            .details-job__elm,
            .details-note,
            .detail-plan {
                display: none;
            }
        }

        .--wrap {
            display: block !important;
        }
    }
    &:not(.contents) {
        .schedule-wrapper:not(.new-service):not(.detail-plan) {
            display: none;
        }
        &.show-form-schedule {
            .schedule-wrapper:not(.detail-plan) {
                display: block;
            }
            .details-wrap {
                > .details-job {
                    padding: 0px;
                }
                .details-note,
                .details-job__elm {
                    display: none;
                }
            }
        }
    }
    .messaging-preferences.--wrap {
        display: none;
        height: 100%;
    }
    .schedule-wrapper {
        .details-job {
            .rows {
                justify-content: flex-start;
                .txt {
                    width: 165px;
                }
                .no-icon {
                    padding-left: 30px;
                }
                .details {
                    max-width: calc(100% - 165px);
                    width: calc(100% - 165px);
                    .tab-items {
                        white-space: nowrap;
                    }
                }
                & + .rows {
                    margin-top: 16px;
                }
            }

            .row-has-line {
                padding-top: 16px;
            }

            .lines {
                border-top: solid 1px $border-color-grey;
                margin: 22px -22px 16px;
            }

            .nav-tabs {
                border: 0px;
            }

            .tab-content {
                padding-bottom: 16px;
                .rows {
                    margin-top: 16px;
                }
            }

            .row-except-month {
                .col-haft {
                    width: 40%;
                    color: $black-3;
                    font-size: 13px;
                }
                .check-items {
                    & + .check-items {
                        margin-top: 6px;
                    }
                }
            }

            .select-jobs {
                width: 80%;
                .dropbtn {
                    padding-right: 2px;
                    .arrow {
                        display: flex;
                        @include size(auto);
                        @include transform(none);
                    }
                }
            }

            .dropdown-assign {
                .v2-dropdown__menu {
                    width: 320px;
                    max-height: 350px;
                    height: auto;
                    right: 0px;
                    font-weight: 500;
                    .user-name {
                        > div:last-child {
                            font-size: 13px;
                        }
                    }
                }
            }
            .detail-toggle {
                min-height: 32px;
                @include flexcenter;
            }
        }
        &.detail-plan,
        .details-job {
            .rows {
                justify-content: flex-start;
                .txt {
                    width: 173px;
                }
                .no-icon {
                    padding-left: 30px;
                }
                .details {
                    max-width: calc(100% - 173px);
                    width: calc(100% - 173px);
                    .tab-items {
                        white-space: nowrap;
                    }
                }
                & + .rows {
                    margin-top: 16px;
                }
            }
        }
        .detail-select-date {
            width: 190px !important;
        }
        .detail-time {
            .wrapper-choosetime + .field-length {
                margin-left: 8px;
            }
            .field-length {
                @include flexcenter;
                .wrapper-choosetime {
                    margin-left: 3px;
                }
            }
            .tab-items {
                padding: 0px 5px;
            }
        }
        .detail-toggle {
            height: 20px;
            @include flexcenter;
            .txt {
                height: 20px;
            }
            .switch {
                display: inline-block;
            }
            .txt-ellipsis {
                width: calc(100% - 45px);
                display: inline-block;
                vertical-align: top;
                padding-left: 8px;
                padding-top: 3px;
                font-size: 13px;
                color: $grey-extrlight;
            }
        }
        .content-history {
            border-top: 1px solid $border-color-grey;
        }
        .alert.--warning {
            .alert__description {
                padding-right: 0;
                @media screen and (max-width: 840px) {
                    font-size: 12px;
                }
            }
        }
    }
    // For new service
    .new-service {
        > .details-job {
            padding: 24px;
        }
        .detail-time {
            .field-length .wrapper-choosetime {
                margin-left: 0px;
            }
        }
        .color-code {
            @include size(24px);
            padding: 3px;
            cursor: pointer;
            margin-right: 6px;
            .color {
                @include size(18px);
                border-radius: 3px;
                background-color: $blue-default;
            }
        }
    }
    .details-job {
        .rows .details {
            width: calc(100% - 120px);
        }
    }
}
.form-tabpane-details {
    &.show-form-edit {
        .wrapper-dashboard,
        .content-elm:not(.header-modal),
        .content-elm-items:has(.rows.--title) {
            display: none;
        }
        .form-has-content > .header-modal {
            padding: 0px;
        }
        .paying-text {
            padding-left: 33px;
        }
        .tabs-items-body.is-subcharge {
            .items-service {
                padding-left: 8px;
            }
        }
    }
    &.show-form-edit {
        .form-edit-template:not(.form-add-material):not(.form-sentricon) {
            display: flex;
        }
    }
    &.show-form-sentricon {
        .form-sentricon {
            display: flex;
        }
        .rows {
            justify-content: flex-start !important;
            .txt {
                width: 200px;
            }
            .details {
                width: calc(100% - 200px);
            }
        }
        .detail-select-date {
            max-width: 220px;
        }
    }
    &:not(.show-repeat-inv) {
        .form-invoice-repeat {
            display: none;
        }
    }
    &.show-repeat-inv {
        .content-elm:not(.--header-info),
        .content-elm-edit:not(.--header-info) {
            display: none;
        }
        .container-column .rows.--header-info:not(.--inmodal) {
            padding: 0px;
            border: none;
        }
        &.tab-add-new,
        &.show-form-edit {
            .form-has-content > .header-modal {
                display: block;
            }
            .content-elm-edit {
                display: none;
            }
        }
        .content-elm-edit.--header-info:has(.alert) {
            padding: 0 24px !important;
            & + .rows.--header-info {
                .form-invoice-repeat {
                    height: calc(100% - 115px) !important;
                    box-shadow: none;
                }
            }
        }
    }
    &.show-form-add-material {
        .wrapper-dashboard {
            display: none;
        }
        .form-add-material {
            display: block !important;
        }
    }
    &.show-addpayment {
        .content-elm:not(.header-modal) {
            display: none;
        }
        .form-add-payment {
            display: block;
        }
        .form-has-content > .header-modal {
            padding: 0px;
        }
        > .container-column {
            overflow: auto;
        }
        &.tab-add-new,
        &.show-form-edit,
        &.show-form-sentricon {
            .content-elm-edit:not(.items-payment) {
                display: none;
            }
            .items-payment.content-elm-edit {
                padding: 0px !important;
                border: none;
            }
            .form-has-content > .header-modal {
                > .form-add-payment {
                    display: none;
                }
            }
            .--header-info:not(.--inmodal) {
                border-bottom: none;
                padding-top: 0px;
                padding-bottom: 0px;
            }
        }
    }
    &:not(.content-services):not(.body-modal) {
        .form-edit-template {
            display: none;
        }
        &.tab-add-new,
        &.show-form-edit {
            .form-edit-template:not(.form-add-material):not(.form-sentricon) {
                display: flex;
            }
        }
        &.show-form-sentricon {
            .form-sentricon {
                display: flex;
            }
        }
    }
    &.form-workorder {
        .rows-header-table,
        .--header-info {
            border-bottom: 0px !important;
        }
        .tb-title {
            background-color: $background-input;
        }
        .tb-content {
            .col-qty {
                color: $black-2;
                font-weight: 500;
            }
            &.items-service {
                padding-top: 5px;
                align-items: flex-start;
                .col-item {
                    line-height: 20px;
                    padding-top: 8px;
                    height: auto;
                }
            }
        }
        .rows {
            .col {
                white-space: normal;
            }
            .col-item {
                width: 35%;
                min-width: 135px;
            }
            .col-desc {
                flex: 1;
            }
            .col-qty {
                width: 15%;
            }
            .boxs {
                padding-left: 0px;
            }
        }
        .customer-info-detail .name {
            color: $purple-default;
        }
    }
    &.form-devices {
        &.has-manages-area,
        &.has-manages-types,
        &.has-manages-status,
        &.has-manages-level {
            .wrapper-devices {
                display: none;
            }
        }
        &.has-manages-area {
            #form_edit_arealist {
                display: block;
            }
        }
        &.has-manages-types {
            #form_edit_types {
                display: block;
            }
        }
        &.has-manages-status {
            #form_edit_status {
                display: block;
            }
        }
        &.has-manages-level {
            #form_edit_level {
                display: block;
            }
        }
        .wrapper-dashboard {
            height: 100%;
        }
        .wrapper-device-manage {
            display: none;
        }
        .box-area-devices {
            @include font-color(20px, 13px, $black-3);
            & + .box-area-devices {
                padding-top: 40px;
            }
        }
        .rows-titles {
            @include betweenitems;
            max-width: calc(100% - 50px);
            .txt-title {
                @include text-ellipsis;
            }
            .col-progress {
                width: 155px;
            }
            .progress-bar {
                height: 4px;
                border-radius: 3px;
                margin-top: 2px;
            }
        }
        .tb-title {
            width: 100%;
            background-color: $background-grey;
        }
        .tb-rows {
            @include flexcenter;
            .col {
                padding-left: 10px;
            }
            .col-device {
                width: calc(50% - 130px);
                flex: 1;
            }
            .col-type {
                width: 25%;
            }
            .col-status {
                width: 25%;
            }
            .col-checked {
                width: 110px;
                &.has-checked {
                    .check-icon {
                        display: block;
                    }
                    .check-circle {
                        display: none;
                    }
                }
                .check-circle {
                    @include size(16px);
                    border: 1px solid $grey-soft;
                    border-radius: 50%;
                }
                .check-icon {
                    display: none;
                }
            }
            .col-icon {
                width: 30px;
                text-align: center;
                opacity: 0.5;
                cursor: pointer;
                padding-left: 0px !important;
                margin-left: 10px;

                &:hover,
                &.active {
                    opacity: 1;
                }
            }
        }
        .tb-content {
            padding: 10px 16px 10px 25px;
            min-height: 50px;
            @include flexwrap;
            border-bottom: 1px solid $border-color-grey;
            .tb-rows {
                width: calc(100% - 30px);
                .txt-ellipsis {
                    display: block;
                }
            }
            .col-device {
                color: $violet-name;
                @extend .fw-600;
            }
            .description {
                padding-left: 10px;
                padding-right: 50px;
                padding-top: 5px;
                color: $black-6;
                @include text-line-clamp(2);
            }
            .txt-level {
                color: $black-3;
            }
        }
        .form-edit-template {
            .details-job {
                .rows:not(.--inmodal) {
                    border-bottom: none;
                    padding: 0px;
                    & + .rows {
                        margin-top: 20px;
                    }
                }
            }
        }
        .details-job {
            .rows {
                .details {
                    width: calc(100% - 175px);
                    > .field-input,
                    > .v2-dropdown {
                        width: 220px;
                    }
                    &.--check-time {
                        .v2-dropdown {
                            .time-checked {
                                width: fit-content;
                            }
                        }
                        .save-time-active {
                            .time-checked {
                                padding: 0px 8px;
                            }
                        }
                        .from-time {
                            .v2-dropdown__menu {
                                min-width: 90px;
                            }
                        }
                    }
                }
                .txt {
                    width: 175px;
                }
            }
        }

        .row-activity {
            .details {
                @include betweenitems;
                line-height: 20px;
            }
        }

        .boxs-checktime {
            .btn-check {
                width: 105px;
                white-space: nowrap;
            }
        }

        .--header-info {
            border-bottom: 0px !important;
            .customer-info-detail {
                width: 100%;
                @include flexwrap;

                .name {
                    width: 100%;
                }
                .detail {
                    width: 50%;
                }
            }
        }
        .--sentricon {
            .icon-sentricon {
                width: 40px;
            }
            .col-status {
                width: 12%;
            }
            .col-feet {
                width: max-content;
                &,
                & + .col {
                    border-left: 1px solid $border-color-grey;
                    max-width: calc(50% - 40px);
                }
            }
            .col-install {
                max-width: calc(50% - 40px);
            }
            &:not(:has(.col-cancel)) {
                .col-install {
                    max-width: calc(100% - 80px);
                }
            }
            .col-content {
                width: calc(88% - 40px);
            }
        }
    }
    &.form-documents {
        position: relative;
        .rows {
            &:not(.container-content-email) {
                @include flexcenter;
                padding: 12px 16px 12px 24px !important;
            }
            &__name {
                flex: 1;
                width: calc(100% - 180px);
                color: $purple-default;
                font-weight: 500;
                @include flexcenter;
                .name {
                    @include text-ellipsis;
                    margin-left: 6px;
                    max-width: calc(100% - 35px);
                    cursor: pointer;
                    line-height: 24px;
                }
                &:has(.budget) {
                    .name {
                        max-width: calc(100% - 75px);
                    }
                }
            }
            &__status {
                padding: 0px 10px;
            }
        }
        .modal {
            position: absolute;
            top: 50px !important;
            max-height: calc(100% - 55px);
            height: unset !important;
        }
    }
    &:not(.show-addpayment):not(.body-modal) {
        .customer-info-number {
            .sm-row {
                .react-datepicker-popper {
                    @include transform(none!important);
                    inset: auto 24px auto auto !important;
                }
            }
        }
    }
    &:not(.tab-add-new):not(.show-form-edit):not(.c-job-details) {
        .--header-info {
            .customer-info-number {
                .detail:not(.repeat-action) {
                    min-width: 115px;
                    width: fit-content;
                    height: 32px;
                    &:not(.btn-item) {
                        flex: 1;
                    }
                }
            }
            .customer-info-detail {
                padding-right: 10px;
            }
        }
    }
    .wrapper-dashboard {
        height: 100%;
        &.form-new {
            .box-auto,
            .box-area-devices,
            .wrap-material {
                display: none;
            }
        }
        .rows-menu {
            .v2-dropdown {
                &__menu {
                    width: max-content;
                }
            }
        }
    }
    .form-edit-template {
        &__content {
            flex: 1;
            .modal {
                cursor: auto;
            }
        }
        .--header-info {
            .field-input {
                color: $black;
                padding: 0px 5px;
            }
        }

        .tabs-items-body {
            background-color: $white;
            cursor: auto;
            .boxs {
                padding: 0 0 10px 9px;
                font-size: 13px;
            }
            .item-note {
                .field-input {
                    color: inherit;
                    line-height: 20px;
                    min-height: 32px;
                    height: auto;
                    overflow: auto;
                }
                .wrapbox-editor__form {
                    padding: 8px 12px;
                }
            }
            .col-item {
                &.select-service-name {
                    .dropbtn {
                        height: 32px;
                        border-color: $grey-soft;
                    }
                }
            }
            .col-tax {
                &.first-field {
                    padding-right: 0px;
                }
                &.second-field {
                    padding-left: 0px !important;
                }
                .v2-dropdown {
                    &.active {
                        .dropbtn {
                            position: relative;
                            z-index: 1;
                        }
                    }
                    .dropbtn {
                        font-weight: normal;
                        font-size: 13px;
                        padding: 0px 1px 0px 5px;
                        .txt-ellipsis {
                            text-overflow: unset;
                            margin: 0px;
                        }
                    }
                    .content-full {
                        max-width: 230px;
                    }
                }
            }
            .content-full {
                width: max-content;
                left: 0px;
            }
            .dropbtn {
                background-color: $white;
            }
            .items-footer {
                padding: 4px 0 10px 18px;
                .status-btn {
                    background-color: var(--bg-estimate);
                    &.--sm {
                        border-radius: 4px;
                    }
                }
                .v2-dropdown {
                    .dropbtn {
                        height: 24px;
                        border: 0;
                        svg path {
                            stroke: $white-default;
                        }
                    }
                }
            }
            &:has(.boxs + .items-footer) {
                .boxs {
                    padding-bottom: 2px;
                }
            }
        }

        .items-service {
            &.tb-content {
                padding-top: 8px;
                padding-bottom: 2px;
            }
            .col {
                padding: 2px;
                .field-input {
                    padding: 0px 6px;
                }
            }
            .col-qty,
            .col-tax {
                width: 72px;
            }
            .col-cost {
                width: 68px;
            }
        }
        .rows {
            position: relative;
        }
        .items-action-btn {
            .button {
                height: 24px;
                position: absolute;
                top: 14px;
            }
            .drag-items {
                left: 10px;
                cursor: grab;
            }
            .delete-items {
                right: 13px;
                cursor: pointer;
                z-index: 1;
                .delete-option {
                    right: 4px;
                    top: 3px;
                }
            }
        }

        .select-service-name {
            overflow: visible !important;
            margin-left: -5px;
            & > .dropbtn {
                @include flexinline;
                width: 100%;
                height: 100%;
                box-shadow: none;
                padding-left: 3px;
                border-radius: 4px;
                border: solid 1px $transparent;
                .txt-ellipsis {
                    margin-right: 0px;
                    width: auto;
                    flex: inherit;
                }
            }
            &.active {
                .search-input {
                    > svg {
                        @include transform(rotateZ(180deg));
                    }
                }
            }
            &.field-error {
                .search-input > input {
                    background-color: $transparent;
                }
            }
        }

        .item-note {
            padding-bottom: 10px;
        }

        .edit-discount {
            height: auto !important;
            .txt {
                margin-left: -9px;
            }
            .first-field {
                .v2-dropdown {
                    .text {
                        margin: 0 5px 0 10px;
                        width: 12px;
                    }
                    .svg-selectbox svg {
                        margin: 0px;
                    }
                    .items {
                        font-size: 14px;
                    }
                    &.active {
                        .dropbtn {
                            border-radius: 4px;
                        }
                    }
                    .dropbtn {
                        width: 130px;
                    }
                }
                .v2-dropdown__menu {
                    width: 50px;
                    right: 0px;
                }
            }
            .second-field {
                width: 60px;
                .field-input {
                    padding: 0px 6px;
                }
            }
            &:has(.tooltip) {
                .first-field {
                    .text {
                        margin-left: 18px;
                    }
                }
            }
        }
        .items-package {
            &__header {
                @include betweenitems;
                padding: 8px 23px 8px 10px;
                position: relative;
                z-index: 1;
                .field-input {
                    width: 270px;
                    margin: 0 7px;
                }
                .flexcenter {
                    width: calc(100% - 150px);
                    & > svg {
                        path {
                            fill: var(--bg-estimate);
                        }
                    }
                }
            }
            .tabs-items-body {
                background-color: unset;
            }
            .dropbtn,
            .field-input {
                background-color: $white;
            }
            &:has(.dropdown-select-color.active) {
                z-index: 2;
            }
        }
        .btn-add-package {
            padding: 8px 23px 8px 42px;
            border-bottom: 1px solid $border-color-grey;
        }
        .btn-package {
            min-width: 130px;
            justify-content: flex-start;
        }
        .items-action-btn .delete-items,
        .delete-package {
            &:not(.active) {
                .delete-option {
                    display: none;
                }
            }
            .delete-option {
                position: absolute;
                .tabs-daily {
                    margin: 0px;
                    height: 40px;
                    padding: 4px;
                    border: 0px;
                    box-shadow: $boxshadow-dropdown;
                }
                .tab-items {
                    height: 100%;
                    margin: 0px;
                    white-space: nowrap;
                    &::after {
                        display: none;
                    }
                }
            }
        }
        .delete-package {
            position: relative;
            .delete-option {
                right: 0;
            }
        }
        .boxs-file-upload {
            .v2-dropdown.--more {
                .v2-dropdown__menu {
                    bottom: calc(100% + 4px);
                    top: unset;
                }
            }
        }
        .list-note-templates {
            & > .v2-dropdown__menu {
                top: unset;
                bottom: calc(100% + 4px);
                &:not(:has(.items-template:nth-of-type(4))) {
                    .list-filter {
                        .v2-dropdown__menu {
                            top: unset;
                            bottom: calc(100% + 4px);
                        }
                    }
                }
            }
        }
        .items-payment-number {
            .txt {
                min-width: 180px;
                max-width: calc(100% - 150px);
            }
            &:has(.btn-repeat) {
                .txt {
                    min-width: 218px;
                }
            }
        }
    }
    .delete-items,
    .delete-package {
        &:not(.active) {
            .delete-option {
                display: none;
            }
        }
        .delete-option {
            position: absolute;
            .tabs-daily {
                margin: 0px;
                height: 40px;
                padding: 4px;
                border: 0px;
                box-shadow: $boxshadow-dropdown;
            }
            .tab-items {
                height: 100%;
                margin: 0px;
                white-space: nowrap;
                &::after {
                    display: none;
                }
            }
        }
    }
    .form-add-payment {
        display: none;
        > .alert {
            max-width: calc(100% - 80px);
        }
        .--header-info {
            border-bottom: none !important;
            .customer-info-number {
                width: 100%;
            }
            .txt {
                width: 145px;
                @include font-color(20px, 13px, $grey-extrlight);
                &.--lg {
                    width: 180px;
                }
            }
            .detail {
                @include flexcenter;
                width: calc(100% - 145px);
                flex: 1;

                .field-input {
                    width: 175px;
                    color: $black;
                }
                .check-items {
                    display: inline-block;
                    margin-left: 8px;
                    font-size: 13px;
                    width: calc(100% - 165px);
                    text-align: left;
                    label span {
                        color: $black-3;
                        display: block;
                    }
                }
                .field-method {
                    width: 125px;
                    & + .field-method {
                        margin-left: 8px;
                    }
                }
            }
        }
        .v2-dropup,
        .v2-dropdown {
            display: inline-block;
            margin-right: 8px;

            &:last-child {
                margin-right: 0;
            }
            &.select-method,
            &.select-invoice-stt {
                .dropbtn {
                    width: 175px;
                    justify-content: flex-start;
                    text-align: left;
                    .txt-ellipsis {
                        width: auto;
                    }
                    &.items {
                        color: $black;
                    }
                }
            }
            &.select-credit-card {
                flex: 1;
                width: calc(100% - 185px);
                .dropbtn,
                .v2-dropdown__menu {
                    width: 90%;
                }
            }
        }

        .select-invoice-stt {
            .dropbtn {
                padding-left: 4px;
                .dots {
                    margin-right: 2px;
                }
            }
            .dots {
                @include size(24px);
                display: flex;
                margin-right: 8px;
                position: relative;
                &::before {
                    margin: 0px;
                    @include size(8px);
                    border-radius: 3px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    @include transform(translate(-50%, -50%));
                }
                &.--draft::before {
                    background-color: $grey-dark;
                }
                &.--sent::before {
                    background-color: $btn-hover-black;
                }
            }
            .select-all {
                .dots::before {
                    display: none;
                }
            }
        }

        .customer-info {
            padding-bottom: 8px;
        }

        .table-grid {
            font-size: 13px;
            .rows {
                padding-left: 35px !important;
                @include flexcenter;
            }
            .grid-header {
                color: $grey-extrlight;
                height: 32px;
                border-bottom: none !important;
                background-color: $white-anti-flash;
                & > * {
                    @include text-ellipsis;
                }
            }
            .grid-content {
                height: 50px;
                color: $black-3;
                .col-invoice {
                    color: $violet-name;
                }
                .col-payment {
                    color: $black-2;
                    font-weight: 500;
                }
                &.--message {
                    background-color: $grey-light;
                    border-bottom: none;
                    margin-top: 10px;
                }
            }

            .col-checkbox {
                @include size(24px);
                @include centeritem;
                .check-items {
                    min-width: auto;
                    @include size(16px);
                    margin-top: -11px;
                }
            }
            .col-invoice {
                width: calc(22% - 24px);
                padding-left: 8px;
            }
            .col-date {
                width: 20%;
                min-width: 125px;
            }
            .col-status {
                width: 18%;
            }
            .col-charge {
                width: 14%;
            }
            .txt-ellipsis {
                display: block;
            }
        }

        .items-payment {
            justify-content: flex-end;
            padding-top: 12px !important;
            padding-bottom: 20px !important;
            &-number {
                width: 45%;
            }
            .detail {
                text-align: right;
            }
        }
        .rows {
            &.btn-action:not(.--inmodal) {
                border-bottom: 0;
                padding: 15px 23px 30px !important;
                @include centeritem;
                > .v2-btn-default {
                    margin-left: 8px;
                }
            }
        }
    }
    .form-invoice-repeat {
        height: calc(100dvh - 121px) !important;
        background: $white;
        overflow-y: auto;
        .header-modal {
            justify-content: flex-start;
            padding: 16px 24px;
            height: auto;
            .title-h1 {
                margin-left: 8px;
            }
        }
        .content-form {
            flex: 1;
        }
        .footer-form {
            @include flexcenter;
            padding: 16px;
            bottom: -64px;
            position: absolute;
            width: calc(100% - 1px);
            z-index: 2;
        }
        .repeat-action {
            @include flexcenter;
            flex-wrap: wrap;
            & > .v2-dropdown {
                width: 220px;
                @media (max-height: 800px) {
                    .v2-dropdown__menu {
                        bottom: calc(100% + 4px) !important;
                        top: unset !important;
                        transform: none !important;
                    }
                }
            }
            .dropdown-frequency {
                .dropbtn {
                    svg {
                        @include size(20px);
                    }
                }
                .tooltiptext {
                    max-width: 220px;
                    text-align: left;
                    display: inline-block;
                    font-weight: 400;
                }
                &.active {
                    .tooltiptext {
                        visibility: visible;
                    }
                }
            }
            .v2-dropdown__menu {
                max-height: 240px;
            }
        }
        .detail-email {
            width: calc(100% - 230px);
        }
        .details-job {
            .rows {
                justify-content: flex-start;
                .txt {
                    width: 120px !important;
                }
                &:not(.--inmodal) {
                    margin-left: 0px;
                    margin-right: 0px;
                    border-bottom: none;
                }
                &.tabs-daily {
                    border: none;
                    border-radius: 0px;
                    justify-content: flex-start;
                    .detail {
                        width: auto;
                    }
                }
                &.row-has-line {
                    padding: 16px 0px !important;
                }
                &.--summary {
                    .txt {
                        height: auto;
                    }
                    .detail {
                        line-height: 24px;
                    }
                }
                &.--inv-date {
                    .details {
                        width: 30%;
                    }
                }
            }
        }
    }
    .content-has-tabs {
        .rows {
            margin: 0px 12px 0px 23px;
            padding: 0px !important;
        }
        .customer-info-number {
            width: 100%;
            .txt {
                width: 160px !important;
                svg {
                    margin-right: 6px;
                }
            }
            .detail {
                width: calc(100% - 160px);
            }
            .sm-row + .sm-row {
                margin-top: 16px;
            }
            .field-input {
                @include placeholder {
                    color: $grey-soft-txt;
                    font-weight: normal;
                    font-size: 13px;
                }
            }
        }
    }
    .container-column {
        @include size(100%);
        .rows {
            &:not(.--inmodal) {
                border-bottom: 1px solid $border-color-grey;
            }
            &:not(.container-content-email):not(.--inmodal) {
                padding: 0px 23px;
            }
            &.--header-info:not(.--inmodal) {
                padding-left: 32px;
            }
        }
        .content-elm {
            &:not(.content-elm-edit) {
                .items-service {
                    &.tb-content {
                        align-items: flex-start;
                        .col-item {
                            height: auto;
                        }
                        .col {
                            white-space: normal;
                        }
                    }
                }
            }
            &-edit {
                &:not(.tabs-items-body) .tb-title {
                    padding-left: 9px;
                }
            }
            &-items {
                &:first-child {
                    border-top: 1px solid $border-color-grey;
                }
                .rows.--title {
                    padding: 8px 23px 8px 44px;
                    @include flexcenter;
                    gap: 4px;
                    &:has(.check-items) {
                        padding-left: 13px;
                        .check-items {
                            margin-top: -10px;
                        }
                        .fs-15 {
                            margin-left: -2px;
                        }
                        .icon {
                            margin-left: -7px;
                        }
                    }
                }
            }
        }
    }
    .repeats-invoice {
        margin-bottom: 12px;
        padding: 8px;
        border-radius: 8px;
        background: $white-cultured;
        color: $grey-very-dark;
        &__link {
            @include flexinline;
            cursor: pointer;
        }
        .v2-btn-default {
            margin-right: 8px;
            background: $white;
            .tooltiptext {
                left: 0;
                transform: none;
            }
        }
    }
    .customer-info,
    .items-payment {
        @include betweentop;
        padding-bottom: 15px;
        &-number {
            .txt {
                width: 115px;
                font-size: 13px;
                color: $date-color;
                font-weight: normal;
                @include flexcenter;
                margin-bottom: 0;
                svg {
                    margin-right: 6px;
                }
                &:not(:has(.first-field)) {
                    word-break: break-word;
                }
            }
            .detail {
                width: 150px;
                color: $black;
                > span {
                    width: 100%;
                    line-height: 32px;
                    padding: 0px 5px;
                    display: inline-block;
                }
            }
            &.has-customer-name {
                max-width: calc(100% - 255px);
                flex: 1;
                padding-right: 10px;
                > .name {
                    margin-bottom: 4px;
                    @include font-color(32px, 14px, $purple-default);
                }
            }
        }

        .sm-row {
            height: 32px;
            @include flexcenter;
            & + .sm-row {
                margin-top: 4px;
            }
            &.--bill-address {
                height: auto;
                align-items: flex-start;
                .txt {
                    line-height: 32px;
                    flex: 0 0 auto;
                    width: 125px;
                }
                .detail {
                    width: auto;
                    &__info-address {
                        max-width: 100%;
                        margin-top: 8px;
                        margin-right: 8px;
                        line-height: 19px;
                        word-break: break-word;
                    }
                }
            }
            .select-info {
                width: 180px;
            }
            &:has(.detail-checkbox) {
                &,
                .detail {
                    height: auto !important;
                    align-items: flex-start;
                }
                .txt {
                    margin-top: 4px;
                }
                .detail-checkbox {
                    width: calc(100% - 175px);
                    padding-bottom: 8px;
                }
                .check-items {
                    width: 100%;
                    margin-top: 8px;
                }
            }
        }
        .label-paid {
            @include font-color(20px, 12px, $purple-default);
        }
    }
    .customer-info-detail {
        color: $violet-name;
        line-height: 1.43;
        max-width: 50%;
        .name {
            height: 32px;
            line-height: 32px;
            color: $grey-extrlight;
        }
    }
    .items-service {
        @include flexcenter;
        padding-left: 12px;
        font-size: 13px;
        &.tb-title {
            @extend .fw-600;
            height: 40px;
        }
        &.tb-content {
            padding-top: 15px;
            padding-bottom: 15px;
            color: $black-3;
            .col-price,
            .col-item {
                color: $black-2;
            }
            .col-onetime {
                overflow: visible;
            }
            .col-item {
                height: auto;
            }
        }

        .col {
            @include text-ellipsis;
            &.first-field,
            &.second-field {
                overflow: visible !important;
            }
            & + .col {
                padding-left: 5px;
            }
            &.col-price {
                width: 75px;
                padding-left: 6px;
            }
        }

        .col-item {
            flex: 1;
            width: calc(100% - 505px);
            height: 32px;
            @include flexcenter;
        }

        .col-cost {
            width: 80px;
        }

        .col-icon {
            width: 30px;
            height: 20px;
            opacity: 0.6;
            margin-top: -1px;
            svg {
                @include size(20px);
            }
        }

        .col-onetime {
            width: 68px;
            &.tooltip {
                .tooltiptext.top {
                    right: 0;
                    left: unset;
                    transform: none;
                }
            }
            &.svg-note-orange {
                svg {
                    margin-top: -3px;
                }
            }
            &:not(.active) {
                svg {
                    cursor: pointer;
                }
            }
        }

        .col-qty,
        .col-10 {
            width: 70px;
        }
    }
    .items-payment {
        padding: 10px 23px 15px 35px !important;
        &-number {
            font-size: 13px;
            max-width: 62%;
            .sm-row {
                justify-content: space-between;
                min-height: 22px;
                height: unset;
                gap: 5px;
                &.is-tax-value {
                    line-height: 20px;
                    align-items: flex-start;
                    .txt {
                        height: auto;
                    }
                }
                &.--deposit {
                    align-items: flex-start;
                    .txt {
                        flex-wrap: wrap;
                    }
                    .detail {
                        font-weight: 500;
                        color: $green-default;
                    }
                }
            }
            .txt {
                color: $black-3;
                width: auto;
                min-width: 100px;
                &.field-error {
                    .second-field {
                        .field-input {
                            border-color: $red-default !important;
                            background-color: $red-extralight;
                        }
                    }
                    .v2-dropdown:not(.active) {
                        .dropbtn {
                            &:not(:hover) {
                                border-right-color: $transparent !important;
                            }
                            box-shadow: none !important;
                        }
                    }
                }
            }
        }
        .detail {
            padding-left: 8px;
            &.disable {
                opacity: 0.3;
            }
            &.--large {
                font-size: 15px;
                font-weight: 500;
            }
        }

        .line {
            border-top: solid 1px $grey-light-2;
            margin: 5px 0px !important;
            height: auto;
        }
        .items-checktime {
            @include font-color(16px, 12px, $black-eerie);
            @include flexcolumns;
            justify-content: flex-end;
            padding: 0px 20px 3px;
            max-width: 35%;
            height: 100%;
            &__title {
                color: $grey-generic;
                margin-bottom: 3px;
                font-weight: 500;
            }
        }
    }
    .note-details {
        background: light-dark(#f8f4ff, #1b1d1e);
        border-bottom: none !important;
        padding: 24px 22px 24px 0px !important;
        .content-top-notes {
            padding: 0;
        }
        .boxs {
            margin-left: 35px;
        }
        .left-icons {
            left: -35px;
        }
    }
    .rows.box-signature {
        padding: 15px 23px 15px 35px !important;
        @include betweentop;
        &.is-signed {
            .is-single-signed {
                .btn-add-signature {
                    display: none;
                }
            }
            .signature-info .info {
                display: block;
            }
        }

        .col-signature {
            width: 45%;
        }
        .signature-add {
            height: 100px;
            position: relative;
            @include centeritem;
            border-bottom: 1px solid $border-color-grey;
            img {
                max-height: 100%;
            }
            .btn-add-signature {
                position: absolute;
                width: 100%;
                bottom: 8px;
                .v2-btn-default {
                    width: 100%;
                }
            }
            &:not(:has(.btn-add-signature)) {
                background-color: $bg-signature;
            }
        }
        .signature-info {
            padding-top: 10px;
            .title {
                font-size: 12px;
                font-weight: 500;
                text-transform: uppercase;
            }
            .info {
                display: none;
                @include font-color(20px, 13px, $grey-extrlight);
                word-break: break-word;
            }
        }
    }
    .content-history {
        background-color: $white;
        padding-bottom: 50px;
    }
    .tabs-items-body {
        &.is-subcharge {
            .items-service * {
                color: $blue-default;
            }
        }
        .boxs {
            border: 0px;
            padding: 0 0 15px 12px;
            overflow: auto;
            box-shadow: none;
            background-color: $transparent;
        }
        .wrap-content-box {
            .description {
                @include font-color(1.43, 13px, $grey-extrlight);
                & + .description {
                    margin-top: 20px;
                }
            }
        }
    }
    .tb-title {
        height: 32px !important;
        font-weight: 500 !important;
        color: $black-3;
        font-size: 12px;
        text-transform: uppercase;
    }
    .paying-text {
        padding: 15px 20px 15px 32px;
        color: $blue-default;
        word-break: break-word;
        border-bottom: 1px solid $border-color-grey;
    }
    .form-mdu {
        @include size(100%);
        &.--assigned {
            @include betweentop;
            > div {
                min-height: 100%;
            }

            .content {
                flex: 1;
                padding: 5px 8px;
                &__items {
                    color: $black-3;
                    font-weight: 500;
                    height: 32px;
                }
            }
        }
        &.form-new {
            .content,
            .form-mdu-conts {
                display: none;
            }
        }
        &__menu {
            width: 170px;
            height: 100%;
            @include flexcolumns;
            border-right: solid 1px $border-color-grey;
        }
        &__content {
            width: calc(100% - 170px);
            &.has-editing {
                .wrap-mdu {
                    display: block;
                }
                .content-elm {
                    display: none;
                }
                .details-job {
                    padding: 0px;
                }
                .list-note-templates {
                    .container-column {
                        max-height: 300px;
                    }
                }
            }
            .wrap-mdu {
                display: none;
            }
        }
        .mdu-label {
            @include font-color(23px, 12px, $txt-black);
            font-weight: 500;
            text-transform: uppercase;
        }
        .menu {
            border-bottom: 1px solid $border-color-grey;
            &--building {
                padding: 8px 14px;
                .v2-dropdown {
                    margin-top: 3px;
                    &.active {
                        z-index: 2;
                    }
                    .items {
                        padding-left: 10px;
                    }
                }
            }
            &--unit {
                padding: 8px 14px;
                @include flexwrap;
                align-items: center;
                justify-content: space-between;
                .search-input {
                    margin-top: 3px;
                    .field-input {
                        padding: 2px 6px 3px 26px;
                        z-index: 0;
                    }
                }
            }
        }
        .details-job {
            padding: 12px;
            .rows {
                .txt {
                    color: $grey-extrlight;
                }
                .details {
                    .customer-name {
                        gap: 4px;
                    }
                }
            }
            .detail-schedule {
                color: $black-2;
            }
            .info-time {
                width: 100%;
            }
            .mdu-label {
                font-size: 15px;
                color: $black-2;
                @extend .fw-600;
                @include text-ellipsis;
                text-transform: capitalize;
            }
            &__header {
                @include betweenitems;
                > .v2-dropdown {
                    margin-left: 8px;
                    .v2-dropdown__menu {
                        width: max-content;
                    }
                }
            }
            &__checktime {
                @include flexcenter;
                margin: 10px 0px 5px;
            }
        }
        .content-top-notes {
            padding: 12px;
            border-bottom: 1px solid $border-color-grey;
            .content {
                padding: 0px;
            }
            .boxs {
                margin-left: 0px;
            }
            .editor-controls {
                &__btn {
                    &.--break {
                        margin-right: 8px;
                    }
                    &:not(.--break) + .editor-controls__btn {
                        margin-left: 2px;
                    }
                }
                &.--action {
                    .v2-btn-main {
                        margin-left: 4px;
                    }
                }
            }
        }
        .form-mdu-conts {
            width: 100%;
            .boxs {
                & + .rows-load-more {
                    margin-left: 0px;
                }
                .wrap-content-notify {
                    .tooltip:first-child {
                        .tooltiptext {
                            margin-left: 15px;
                        }
                    }
                }
            }
            .time-checkout {
                margin-left: 8px;
            }
            .schedule-time .info-time:not(.--repeat) .info-time__label {
                max-width: initial;
            }
            .boxs-materials {
                border-bottom: 1px solid $border-color-grey;
            }
            .details-job {
                .rows:last-child {
                    box-shadow: none;
                    margin-bottom: 0px;
                    padding: 0px;
                }
            }
        }
        .boxs-materials {
            .details-job,
            .header-modal,
            .wrap-material {
                padding: 8px 12px;
            }
            .details {
                max-width: calc(100% - 165px) !important;
                .content-full {
                    right: 0px;
                }
            }
        }
    }
    .wrap-material {
        padding: 12px 16px 12px 24px;
        border-bottom: 1px solid $border-color-grey;
        .material {
            @include betweenitems;
            color: $black-2;
            font-weight: 500;
            &__svg {
                width: 30px;
            }
            &__name {
                flex: 1;
                min-width: 25%;
            }
            &__qty {
                width: 35%;
                padding: 0px 10px;
            }
            &__items {
                width: 120px;
                text-align: right;
                margin-right: 8px;
            }
            &__custom {
                width: fit-content;
                max-width: 120px;
            }
            .v2-dropdown {
                &__menu {
                    width: 170px;
                }
            }
        }
        .list-material {
            @include flexwrap;
            .tag-label {
                margin-top: 8px;
                min-width: unset;
            }
        }
    }
    .form-edit-material,
    .form-add-material {
        .details-job {
            padding-top: 12px;
            .rows {
                padding: 0px !important;
                border-bottom: 0px;
                justify-content: flex-start;
                & + .rows {
                    margin-top: 24px;
                }
                &.--sm {
                    .details {
                        max-width: 320px;
                    }
                }
                .txt {
                    width: 175px;
                    flex: 0 0 auto;
                }
            }
        }
        .details {
            flex: 1;
            .dropbtn:not(:has(.txt-ellipsis)):not(.group-tags) {
                justify-content: end;
            }
            &:not(.has-input) > .v2-dropdown {
                width: 100% !important;
            }
        }
        .has-input {
            .v2-dropdown {
                margin-left: 8px;
                width: calc(100% - 115px);
            }
            &:has(.v2-dropdown + .v2-dropdown),
            &:has(.field-number) {
                .v2-dropdown:last-child {
                    .v2-dropdown__menu {
                        min-width: 250px;
                        width: max-content;
                        max-width: 320px;
                        right: 0;
                    }
                }
            }
            .field-input:not(.field-number) {
                padding: 0 10px;
            }
        }
        .field-number {
            max-width: 105px;
        }
    }
    &.form-add-estimate {
        .container-column {
            .rows {
                .items-service {
                    &.tb-title {
                        .col-item {
                            padding-left: 0;
                        }
                    }
                }
                .item-note {
                    padding-left: 18px !important;
                }
                &.items-payment {
                    padding-left: 41px !important;
                }
                .customer-info-number {
                    .detail {
                        width: calc(100% - 125px);
                        &.cursor-pointer {
                            color: $purple-default;
                        }
                    }
                    & > *:not(.sm-row) {
                        padding-left: 30px;
                        &.detail {
                            width: 100%;
                            margin-bottom: 8px;
                            & + .sm-row {
                                padding-left: 25px;
                            }
                        }
                    }
                    &.has-customer-name {
                        width: calc(100% - 270px);
                        .name {
                            color: $grey-extrlight;
                        }
                    }
                    & + .customer-info-number {
                        .sm-row {
                            width: 270px;
                            .detail {
                                flex: 1;
                            }
                        }
                    }
                    .v2-dropdown__menu:not(:has(.growth-plan)) {
                        .tooltiptext {
                            left: -40px;
                        }
                    }
                }
                .col-price {
                    width: 110px;
                }
                .items-payment-number {
                    .txt {
                        max-width: calc(100% - 117px);
                    }
                    .detail {
                        width: 112px;
                    }
                }
            }
            .note-details {
                padding-right: 23px !important;
                .content-top-notes {
                    .boxs {
                        .left-icons {
                            left: -35px;
                        }
                    }
                }
            }
            .v2-dropdown__menu:has(.growth-plan) {
                min-width: 255px;
                .growth-plan {
                    svg {
                        margin-top: -4px;
                        @include size(18px);
                    }
                }
            }
            .header-modal:has(.header-modal__option > .sm-row) {
                padding: 16px 23px 4px;
                height: auto;
                .header-modal__option {
                    width: 270px;
                    .detail {
                        flex: 1;
                    }
                }
            }
            &:has(.form-edit-template) {
                .rows.--header-info {
                    padding-left: 10px;
                }
            }
        }
        .content-elm-items,
        .tabs-items-body:has(.items-footer),
        .items-package {
            &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                @include size(100%);
                opacity: 0.08;
                background-color: var(--bg-estimate);
            }
            & > *:not(.items-action-btn),
            & {
                position: relative;
            }
            .rows.--title {
                color: var(--bg-estimate);
                .icon {
                    & > svg {
                        path {
                            fill: var(--bg-estimate);
                        }
                    }
                }
            }
        }
        .items-service {
            position: relative;
            .col-check {
                position: absolute;
                top: 10px;
                left: -10px;
            }
        }
        .col-check {
            input[disabled] {
                & + .item-checkbox label,
                & + label {
                    &::after,
                    &::before {
                        cursor: not-allowed;
                    }
                }
            }
        }
        .rows-has-table {
            border-top: 0;
        }
        .form-edit-template {
            .rows {
                .items-service,
                .items-service + .boxs {
                    padding-left: 21px;
                }
            }
            .content-top-notes {
                .boxs {
                    margin-left: 42px;
                }
            }
        }
        &:not(:has(.form-edit-template)) {
            .rows {
                .items-service,
                .items-service + .boxs {
                    padding-left: 16px;
                }
                &.--title {
                    padding-left: 38px;
                    .icon {
                        margin-left: -4px;
                    }
                }
            }
            .content-top-notes {
                .boxs {
                    margin-left: 39px;
                }
            }
        }
        @media (max-width: 767px) {
            .modal-confirm-delete {
                position: fixed;
            }
        }
    }
    &:has(.form-mdu__content:not(.has-editing)),
    &.form-workorder {
        .list-note-templates {
            .v2-dropdown__menu {
                top: calc(100% + 4px);
                bottom: unset;
                .container-column {
                    max-height: 350px;
                }
            }
        }
    }
    &:has(.col-onetime) {
        .items-payment-number {
            .detail {
                width: 145px;
            }
        }
    }
    // Button Repeat
    .btn-repeat {
        svg {
            margin-right: 0 !important;
        }
        &.active {
            border-color: $blue-default !important;
            background-color: rgba($color: $blue-default, $alpha: 0.06) !important;
            svg {
                transform: rotateY(180deg);
                path {
                    stroke: $blue-default;
                }
            }
        }
    }
}
// List Users Assigned - Sold by
.c-job-details .details-job,
.form-tabpane-details {
    .detail-schedule {
        position: relative;
        &.is-sold-by {
            @include flextop;
            justify-content: flex-end;
            .schedule-user {
                flex: 1;
                width: calc(100% - 40px);
            }
        }
        &.--assign-to {
            &:has(> .schedule-user:empty) {
                .dropdown-listname {
                    margin-left: -8px;
                }
                .v2-dropdown__menu {
                    right: 50%;
                    @include transform(translateX(50%));
                }
            }
        }
    }
}
.dashboard-job-detail,
.form-tabpane-details {
    .schedule-wrapper {
        .detail-schedule {
            @include flextop;
            margin-bottom: 16px;
            .schedule-user {
                max-width: calc(100% - 40px);
                margin-right: 8px;
            }
        }
        &.--sold-by {
            @include flextop;
            margin-bottom: 8px;
            padding-left: 8px;
            .detail-schedule {
                margin-bottom: 0;
                width: calc(100% - 59px);
                .v2-dropdown:first-child {
                    .v2-dropdown__menu {
                        left: 0;
                        right: auto;
                    }
                }
            }
        }
        &.--no-job {
            padding-left: 9px;
        }
        .label {
            margin-right: 16px;
            line-height: 32px;
            color: $black;
            font-size: 12px;
        }
        .workpool-notify {
            line-height: 32px;
            margin: 0 0 0 8px;
        }
    }
}
// Assigned Units
.modal--asign-mdu {
    .modal__container {
        width: 640px;
        max-height: 80%;
        height: 100%;
    }
    .body-modal {
        max-height: calc(100% - 64px);
        height: 100%;
        padding: 0px;
    }
    .footer-modal {
        justify-content: space-between;
    }
    .wrap-mdu {
        &__header {
            @include betweenitems;
            height: 48px;
            padding: 0px 15px 0px 12px;
            border-bottom: 1px solid $border-color-grey;
            .label {
                margin-bottom: 0px;
                @extend .fw-600;
                font-size: 14px;
                color: $grey-extradark;
                padding-right: 24px;
            }
        }
        &__detail {
            height: calc(100% - 48px);
            @include betweentop;
            .flex-column {
                width: 50%;
                height: 100%;
                &__head {
                    padding: 12px 10px;
                    border-bottom: 1px solid $border-color-grey;
                    .item-checkbox label {
                        color: $purple-default;
                    }
                }
            }
        }
    }
}
// Modal Confirm Delete
// Modal Confirm Disconect
.modal-confirm-delete,
.modal-confirm-disconnect {
    .modal__container {
        width: 386px;
        &.--lg {
            width: 500px;
        }
        .body-modal {
            padding: 11px 37px 28px 24px;
            border-top-color: transparent;
        }
        .text {
            @include font-color(20px, 14px, $black-2);
            font-weight: 500;
        }
    }
}
.modal-confirm-delete.open {
    z-index: 100002;
}
.modal-confirm-active {
    .modal__container {
        .body-modal {
            padding-top: 0px;
            border-top-color: transparent;
        }
        .text {
            @include font-color(20px, 14px, $grey-dark);
        }
    }
}
// Add sign
.modal-add-signature {
    &:not(.--single) {
        .modal__container {
            width: calc(100% - 30px);
        }
    }
    &.--single {
        .modal__container {
            width: 650px;
            @media screen and (max-width: 768px) {
                width: calc(100% - 20px);
            }
        }
        .box-signature {
            width: 100%;
        }
    }
    .modal__container {
        max-width: 1400px;
        height: auto;
    }
    .header-modal {
        height: 64px;
    }
    .body-modal {
        border-top: 0px;
        .content {
            width: 100%;
            @include betweentop;
        }
    }
    .box-signature {
        width: calc(50% - 25px);
        .rows {
            border-bottom: 0 !important;
            padding: 0px !important;
        }
    }
    .info-top {
        margin-bottom: 15px;
        font-size: 14px;
        .field-input {
            max-width: 265px;
        }
    }
    .info-bottom {
        @include betweenitems;
        .left {
            max-width: calc(100% - 90px);
        }
        .name {
            color: $black-2 !important;
            font-size: 15px;
            margin-bottom: 2px;
            @include text-ellipsis;
        }
        .btn-clear-sign {
            padding: 0px 4px 0px 10px;
            svg {
                margin-left: 4px;
            }
        }
    }
    .place-signbox {
        height: 325px;
        background-color: $bg-signature;
        border-bottom: 1px solid $grey-middle;
        canvas {
            @include size(100%);
        }
        .field-input {
            margin: 10px;
            width: calc(100% - 20px);
        }
        .box-type {
            height: calc(100% - 52px);
            img {
                height: 100%;
            }
        }
    }
}
// Modal Devices - Edit area list
.modal-manage-devices {
    &.--editarea {
        .modal__container {
            top: 250px;
        }
    }
    .modal__container {
        width: 65%;
        max-width: 550px;
        &:not(.container-new-erea) {
            @include transform(none);
            margin-right: 0px;
            right: 240px;
            top: 205px;
        }
    }
    .header-modal {
        height: 64px;
    }
    .body-modal {
        border-top: 0px;
        border-bottom: solid 1px $grey-boder-line;
        padding: 0px;
        padding-top: 10px;
        .rows {
            padding-left: 24px;
            padding-right: 24px;
        }
        .dashboard-wrapper {
            background-color: $bg-body;
            padding: 16px 24px;
            .boxs {
                margin-left: 40px;
            }
        }
    }
    .footer-modal {
        justify-content: flex-end;
    }
}
.modal-reasign-devices {
    .header-modal {
        height: 64px;
    }
    .footer-modal {
        justify-content: flex-end;
    }
    .body-modal {
        border-bottom: solid 1px $border-color-grey;
        min-height: 300px;
        .rows {
            padding: 10px;
            @include flexcenter;
            line-height: 30px;
            min-height: 50px;
            &__check {
                @include size(16px);
                border: 1px solid $grey-soft;
                border-radius: 50%;
                cursor: pointer;
            }
            &__label {
                @include text-ellipsis;
                padding-left: 10px;
                cursor: pointer;
            }
            &:hover {
                .rows__check {
                    border-color: $grey-middle;
                }
            }
            & + .rows {
                border-top: solid 1px $border-color-grey;
            }
        }
    }
}
// Modal Manage Unit
.modal--mdu {
    &.open {
        z-index: 100002;
    }
    > .modal__container {
        width: 85%;
        max-width: 980px;
        border-radius: 8px;
        height: 100%;
        .body-modal {
            max-height: calc(100% - 128px);
            height: 100%;
        }
    }
    .body-modal {
        @include betweentop;
        padding: 0px;
        overflow-x: auto;
        .modal-confirm-delete {
            .bg-fixed {
                background-color: $transparent;
            }
        }
        .wrap-mdu__detail {
            .alert {
                margin: 0px 24px 16px 16px !important;
                max-width: calc(100% - 40px) !important;
            }
        }
    }
    .wrap-mdu {
        &__boxs {
            width: 220px;
            min-height: 100%;
            height: 100%;
            border-right: solid 1px $border-color-grey;
            .header {
                padding: 15px 12px;
                border-bottom: solid 1px $border-color-grey;
                .mdu-label {
                    margin-bottom: 8px;
                }
            }
        }
        &__detail {
            .--has-phone {
                .v2-dropdown__menu {
                    .scrolls {
                        max-height: 250px;
                    }
                    @media (max-height: 780px) {
                        bottom: calc(100% + 4px);
                        top: unset;
                        .scrolls {
                            max-height: 170px;
                        }
                    }
                }
            }
            .dashboard-wrapper {
                box-shadow: 0 0 1px 1px $border-color-grey;
                flex: 1;
                .content-top-notes {
                    padding: 16px;
                }
                .v2-dropdown__menu:not(.content-checked) {
                    bottom: calc(100% + 4px);
                    top: unset;
                    .container-column {
                        max-height: 320px;
                    }
                }
            }
        }
        .header {
            &__form {
                @include flexcenter;
                flex-wrap: wrap;
                .v2-btn-main {
                    margin-left: 4px;
                }
                .field-input {
                    flex: 1;
                }
            }
            &.disable {
                background-color: $bg-body;
                .header__form {
                    opacity: 0.5;
                }
            }
        }
        .content {
            &__items {
                &-number {
                    margin-right: 8px;
                }
                &-icon {
                    opacity: 0.5;
                    cursor: pointer;
                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 1024px) {
        .modal__container {
            width: 98%;
        }
    }
    @media screen and (max-width: 900px) {
        .wrap-mdu {
            &__detail {
                min-width: 350px;
                .rows {
                    padding: 0 10px 0 16px;
                    flex-direction: column;
                    align-items: flex-start !important;
                    &__form,
                    &__field {
                        margin-top: 5px;
                        width: 100% !important;
                    }
                }
                .dashboard-wrapper {
                    padding: 0px;
                    .boxs {
                        margin-left: 0px;
                        .left-icons {
                            display: none;
                        }
                    }
                }
            }
            &__boxs {
                min-width: 180px;
            }
        }
    }
}
// Customer Portal Settings
.modal-customer-portal-settings {
    max-width: 299px;

    .body-modal {
        padding: 24px 16px 31px 24px;
        .portal-settings {
            &__description {
                @include font-color(20px, 14px, $black-2);
            }

            &__list {
                margin-top: 14px;
                margin-left: 6px;

                .check-items {
                    @include flexcenter();
                    margin-bottom: 4px;
                    height: 24px;
                }

                .item-checkbox {
                    width: 100%;
                }

                .label {
                    min-height: 17px;
                    @include text-ellipsis();
                }
            }
        }
    }

    .footer-modal {
        .btn-cancel {
            margin-left: 6px;
        }
    }
}
// modal add new items job details
.add-items-job {
    cursor: default;
    .header-modal {
        height: 64px;
    }
    .txt {
        @include text-ellipsis;
    }
    .rows {
        .col {
            overflow: visible;
        }
        .dropbtn:not(:has(.txt-ellipsis)) {
            justify-content: flex-end;
        }
        .v2-dropdown__menu {
            max-width: 100%;
        }
    }
    .field-input {
        &.description {
            height: 120px;
            resize: none;
            padding: 8px;
        }
    }
}
//modal delete job
.modal-delete-job {
    &.--form-invoice {
        .modal__container {
            width: 650px;
        }
        .body-modal {
            padding: 20px 0px 0px;
            @include flexcolumns;
        }
        .tables {
            @include flexcolumns;
            overflow: auto;
            &__list {
                padding-bottom: 10px;
                overflow: overlay;
            }
            .rows {
                padding: 0px 24px;
            }
        }
    }
    .modal__container {
        width: 580px;
        .btn-close {
            margin-left: 0;
        }
    }
    .header-modal {
        height: 64px;
    }
    .body-modal {
        padding: 20px 23px;
        font-size: 13px;
        color: $black-2;
    }
    .tables {
        margin-top: 8px;
        .rows {
            @include betweenitems;
            min-height: 42px;
            &.--header {
                flex: 0 0 auto;
                height: 32px;
                @extend .fw-600;
            }
        }
        .col {
            &:not(:first-child) {
                padding-left: 10px;
            }
            &.--md {
                width: 20%;
            }
            &.--lg {
                width: 30%;
            }
            &.--info {
                width: 25%;
            }
            &.--sm {
                width: 15%;
            }
        }
    }
}
// Modal link existing stripeID
.modal-link-stripeID {
    .modal__container {
        height: 80%;
        width: 540px;
    }
    .body-modal {
        flex: 1;
        overflow: auto;
        padding: 0px;
    }
    .tab-items {
        width: 50%;
        padding: 0 8px;
    }
    .tab-contents {
        height: calc(100% - 52px);
    }
    .tab-conts {
        &.tab-content-active {
            @include flexcolumns;
        }
        .form-search {
            flex: 0 0 auto;
            .v2-btn-default {
                font-size: 13px;
            }
        }
        .form-results {
            flex: 1;
            overflow-y: auto;
            color: $black-2;
            padding: 15px;
            margin: 0 15px 15px;
            border: solid 1px $border-color-grey;
            border-radius: 4px;
            &__title {
                font-weight: 500;
                font-size: 14px;
            }
            &__items {
                padding-top: 15px;
                & + .form-results__items {
                    margin-top: 15px;
                    border-top: solid 1px $border-color-grey;
                }
                .label-id {
                    @include flexcenter;
                    max-width: calc(100% - 130px);
                    &__btn {
                        @include text-ellipsis;
                        padding: 0px 10px;
                        height: 32px;
                        line-height: 32px;
                        margin-right: 8px;
                        border-radius: 4px;
                        background: $grey-greenish-light;
                    }
                    .v2-btn-default {
                        flex: 0 0 auto;
                    }
                }
                .item-information {
                    &__line {
                        margin-bottom: 3px;
                        word-break: break-word;
                    }
                }
                .item-list-card {
                    &__line {
                        @include flexcenter;
                        & + .item-list-card__line {
                            margin-top: 8px;
                        }
                        .card-number:not(:has(.date)) {
                            .branch {
                                width: 102px;
                            }
                        }
                    }
                    .status-btn {
                        margin-left: 8px;
                        border-radius: 8px;
                        font-size: 12px;
                        background-color: $light-blue;
                    }
                }
            }
            .alert {
                &__description {
                    padding-left: 0px;
                }
            }
        }
    }
}
.modal-mapping-tax {
    .modal__container {
        max-height: none;
    }
    .body-modal {
        padding: 15px 23px;
        min-height: 400px;
    }
    .v2-dropdown__menu {
        max-height: 250px;
    }
    .list-mapping {
        margin-top: 18px;
        padding-top: 12px;
        &__title {
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 12px;
        }
        &__content {
            display: flex;
            line-height: 20px;
            border-top: solid 1px $border-color-grey;
            padding: 6px 0px;
        }
        .col {
            & + .col {
                margin-left: 5px;
            }
            &:not(.--icon) {
                padding-top: 6px;
            }
            &.--tax-name {
                width: 130px;
            }
            &.--icon {
                @include size(32px);
            }
        }
    }
}
// Form add card in Strip/Square
.StripeElement {
    height: 40px;
    max-width: 215px;
    padding: 0px 12px;
    border-radius: 4px;
    border: 1px solid $grey-soft;
    @include flexcenter;
    .__PrivateStripeElement {
        width: 100%;
    }
    &:hover:not(.StripeElement--focus) {
        border-color: $btn-hover-black;
        border-radius: 4px;
    }
    &--focus {
        box-shadow: 0 0 1px 0 $black-extradark, 0 1px 2px 0 $black-light, 0 2px 3px 0 $black-lighter,
            0 4px 6px 0 $black-verydark, 0 8px 8px 0 $black-lighter, 0 0 0 2px $box-shadow-color-message;
        border-color: $border-color-message;
        border-radius: 4px;
    }
}
.choose-system-sms {
    .header-modal {
        padding: 0 16px;
    }
    .body-modal {
        padding: 0;
        border-top: 0;
        @include flexcolumns;
        .v2-dropdown.active {
            z-index: 2;
        }
    }
    .row {
        padding: 0 16px 16px;
        column-gap: 8px;
        .col {
            width: 80px;
            &.--sm {
                width: 105px;
            }
        }
    }
    .tables {
        border-top: 1px solid $border-color-grey;
        .rows {
            &:last-child {
                box-shadow: none !important;
            }
            .col:first-child {
                padding-left: 18px !important;
            }
        }
    }
}
// Sync to google popup
.container-sync-gg {
    .body-modal {
        @include font-color(24px, 14px, $black-2);
    }
    .boxs {
        @include betweentop;
        padding: 16px;
        border: solid 1px $border-color-grey;
        border-radius: 4px;
        & + .boxs {
            margin-top: 16px;
        }
        .title {
            line-height: 32px;
        }
        &-selection {
            @include flexcenter;
            & + .boxs-selection {
                margin-top: 8px;
            }
            &__label {
                width: 130px;
            }
            .v2-dropdown {
                max-width: 235px;
                flex: 1;
                .dropbtn__label {
                    .txt-ellipsis {
                        flex: 0 0 auto;
                        max-width: calc(100% - 20px);
                    }
                }
            }
        }
    }
}
// delete status Pipelines Status
.modal-delete-opportunity {
    .body-modal {
        @media only screen and (max-height: 740px) {
            .v2-dropdown {
                &__menu {
                    max-height: 200px !important;
                }
            }
        }
        @media only screen and (max-height: 575px) {
            .v2-dropdown {
                &__menu {
                    max-height: 150px !important;
                }
            }
        }
    }
    .footer-modal {
        gap: 5px;
    }
}
// Opportunity Pipeline / Verify ACH
.modal-delete-opportunity,
.modal-verify-account {
    .header-modal,
    .footer-modal {
        padding: 0 16px;
    }
    .body-modal {
        padding: 0;
        .rows {
            @include flexcolumns;
            padding: 16px;
            gap: 6px;
            &.--alert {
                gap: 4px;
                background-color: $red-light;
            }
        }
    }
}
.modal-verify-account {
    .body-modal {
        .rows {
            &:empty {
                padding: 0;
            }
            &:has(.alert) {
                padding-bottom: 0;
                .alert {
                    margin-bottom: 0;
                }
            }
            & + .rows {
                padding-top: 0;
            }
            > .txt {
                margin-bottom: 0;
            }
        }
    }
}
.ReactModalPortal {
    &:has(.--contains.--viewtask-details) {
        z-index: 99999 !important;
    }
}
// Responsive screen
@media only screen and (max-device-width: 1024px) {
    .modal {
        &__overlay {
            z-index: -1;
        }
        &__container {
            z-index: 999;
        }
        &--drip {
            .modal__container {
                &.modal__container--preview {
                    width: 90%;
                }
                &--dripfilter {
                    .filter-result__detail .label {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
    .show-repeat-inv {
        .is-footer-inv {
            display: none;
        }
    }
}
@media only screen and (min-width: 768px) {
    .ReactModalPortal,
    .ReactModalPortal_customer {
        position: relative;
    }
}
@media only screen and (max-width: 767px) {
    .modal {
        position: absolute;
        overflow-x: visible;
        &:not(.modal-chatbot):not(.modal-onboarding-question) {
            .modal__container {
                &.--preview-r {
                    max-width: none;
                    width: max-content;
                    overflow: hidden;
                }
            }
        }
    }
    .ReactModal__Overlay--after-open {
        overflow-x: auto;
    }
    .modal-setting {
        .ReactModal__Overlay {
            position: fixed !important;
        }
    }
    .ReactModalPortal {
        .c-job-details {
            overflow-x: auto;
            width: 100%;
            .modal__container {
                &.--preview-r {
                    margin-left: auto;
                    max-width: none;
                    width: max-content;
                }
            }
        }
    }
}
// iPad in portrait
@media only screen and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
    .--appointment {
        .header-modal {
            padding: 20px;
            height: 58px;
        }
        .setting-wrap {
            .setting-group {
                .setting-title {
                    width: 30%;
                }

                .tabs-daily {
                    .tab-items {
                        padding: 0 8.5px;
                    }
                }
            }
        }
    }
    .form-tabpane-details {
        .items-service {
            .col-10 {
                width: 8.5%;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .form-documents {
        .bg-fixed {
            background-color: $bg-overlay;
        }
    }
    .modal-sendbroadcast {
        .content-broadcast__boxs {
            &.--left {
                width: 260px;
            }
        }
    }
}

// Responsive for Modal Batch
@media only screen and (min-width: 801px) and (max-width: 1024px) {
    .modal-modify {
        .header-select {
            .col-date-range {
                width: 24%;
            }
            .v2-btn-default.selection,
            .field-input {
                padding-left: 5px;
            }
        }
    }
}
@media screen and (max-width: 1180px), screen and (min-height: 600px) and (max-height: 768px) {
    .modal-modify {
        .col-email-sms {
            padding-right: 10px;
        }
        &.modal-send-reminder {
            .col-job-status {
                max-width: 170px;
            }
        }
        &:not(.modal-send-reminder) {
            .t-content {
                min-width: fit-content;
            }
        }
        .col-customer,
        .col-assign {
            min-width: 180px;
            width: 180px;
        }
        .col-job-status {
            width: 155px;
            min-width: 155px;
        }
        .col-service {
            width: 30%;
            min-width: 150px;
            max-width: 150px;
        }
        .col-new-datetime,
        .col-datetime {
            min-width: 120px;
            max-width: 120px;
        }
        .header-select {
            .v2-btn-default.selection,
            .field-input {
                padding-left: 5px;
            }
        }
    }
    .--appointment {
        .modal__container {
            bottom: auto;
            top: 55%;
            @include transform(translateY(-50%));
        }
    }
}
// End Responsive for Modal Batch
@media only screen and (max-width: 768px) {
    .c-job-details {
        .contents__menu {
            overflow-y: auto;
        }
        .modal {
            &.--contains {
                > .bg-fixed {
                    background-color: $bg-overlay;
                }
            }
        }
        .form-tabpane-details {
            .form-edit-template {
                .edit-discount .second-field {
                    width: 40px;
                }
                .items-service {
                    .col-cost,
                    .col-onetime {
                        width: 60px;
                    }
                    .col-price {
                        width: 65px;
                        padding-right: 12px;
                    }
                    .col-tax {
                        width: 50px;
                    }
                }
            }
        }
    }
    .modal-sendbroadcast {
        .content-broadcast {
            &__boxs {
                .flex-column__content {
                    padding: 0 16px 24px;
                }
            }
            .editor-controls__btn {
                @include size(30px);
                &:last-child {
                    .tooltiptext.top {
                        margin-left: -10px;
                    }
                }
            }
        }
    }
    .dashboard-job-detail:not(.contents):not(.show-form-schedule) .schedule-wrapper .details-job,
    .dashboard-job-detail:not(.contents):not(.show-form-schedule) .schedule-wrapper.details-job {
        .rows {
            .txt {
                width: 130px !important;
                flex: 0 0 auto;
            }
            .details:not(.dashboard-wrapper),
            .detail {
                flex-wrap: wrap;
                max-width: calc(100% - 130px) !important;
                width: calc(100% - 130px);
                &.field-every {
                    flex-wrap: nowrap;
                }
            }
            .detail-time .field-length,
            .detail-repeaton,
            .details .field-every:not(.details),
            .detail .field-every:not(.detail) {
                margin-left: 0px !important;
                margin-top: 8px;
            }
        }
        .tabs .tab-items {
            padding: 0 8px;
        }
    }
    .details-repeat-end .monthly-calendar {
        top: 35px !important;
        left: 120px !important;
    }
    .form-invoice-repeat {
        .rows {
            .txt {
                max-width: 120px !important;
            }
            .detail,
            .details {
                max-width: calc(100% - 120px) !important;
            }
            .tab-items {
                padding: 0 5px;
            }
        }
    }
    .modal-manage-devices {
        &.--contains {
            .modal__container {
                right: auto;
                margin: 0px;
                top: 50%;
                left: 50%;
                @include transform(translate(-50%, -50%));
            }
        }
    }
    .modal-modify {
        .header-select {
            .col-date-range {
                width: 27%;
            }
            .col-statuses {
                width: 19%;
            }
        }
    }
}
@media screen and (max-width: 1024px) and (min-width: 768px) {
    .c-job-details {
        .dashboard-wrapper {
            .boxs-details {
                width: 270px;
                margin-right: -3px;
            }
        }
    }
}
// Modal Drip filter
.modal--drip {
    .modal__container {
        transform: none;
        top: auto;
        left: auto;
        margin-right: 0;
        max-height: 100%;
        height: 100%;
        border-radius: 0px;
        .body-modal {
            max-height: none;
            padding: 0px;
        }
        &--dripfilter {
            .header-modal {
                padding: 20px 15px 16px;
                height: auto;
                flex-wrap: wrap;
                flex: 0 0 auto;

                &__title {
                    @include flexcenter;
                    @include font-color(20px, 14px, $black);
                    @extend .fw-600;
                    svg {
                        margin-right: 5px;
                    }
                }
                &__close {
                    cursor: pointer;
                    &:hover {
                        filter: brightness(0.5);
                    }
                }

                .search-input {
                    width: 100%;
                    margin-top: 16px;
                    input {
                        @include placeholder {
                            color: $grey-soft-txt;
                        }
                    }
                }
            }

            .body-modal {
                font-size: 14px;
                .filter-list {
                    &__row {
                        height: 48px;
                        padding-left: 15px;
                        padding-right: 10px;
                        @include flexcenter;
                        border-bottom: 1px solid $border-color-grey;
                        cursor: pointer;
                        &:last-child {
                            border-bottom: 0px;
                        }
                        .label {
                            flex: 1;
                            padding-left: 10px;
                            margin-bottom: 0px;
                            @include text-ellipsis;
                            color: $grey-dark;
                            font-size: 14px;
                            font-weight: normal;
                            text-shadow: none;
                        }
                    }
                }

                .filter-result {
                    display: none;
                    padding: 0px 16px;
                    line-height: 20px;
                    &__title {
                        @include flexcenter;
                        padding-top: 22px;
                    }
                    &__detail {
                        padding-left: 35px;
                        padding-bottom: 25px;
                        font-size: 13px;
                        .label {
                            margin-top: 26px;
                            margin-bottom: 3px;
                            display: inline-block;
                        }
                    }
                    .label {
                        color: $black;
                        font-weight: 500;
                        font-size: 14px;
                        @include text-ellipsis;
                    }
                }

                .result {
                    @include flexcenter;
                    flex-wrap: wrap;
                    color: $grey-dark;
                    &.checked {
                        color: $grey-extradark;
                        .svg-email svg path {
                            fill: $grey-dark;
                        }
                    }
                    &.toggle {
                        .result__detail {
                            display: block;
                        }
                    }
                    & + .result {
                        margin-top: 15px;
                    }
                    &__label {
                        @include text-ellipsis;
                        margin-bottom: 0px;
                    }
                    &__detail {
                        display: none;
                        font-size: 14px;
                        width: 95%;
                        margin-top: 8px;
                        background-color: $white;
                        border: 1px solid $grey-softlight;
                        box-sizing: border-box;
                        box-shadow: $boxshadow-grey-softlight;
                        border-radius: 4px;
                        &-header,
                        &-footer {
                            @include flexcenter;
                            padding: 0px 16px;
                        }
                        &-header {
                            height: 40px;
                            box-shadow: inset 0px -1px 0px $grey-softlight;
                            @extend .fw-600;
                            padding-left: 12px;
                            svg {
                                margin-right: 8px;
                            }
                        }
                        &-body {
                            padding: 16px;
                            @include flexwrap;
                            .v2-dropdown {
                                margin-top: 16px;
                                width: 100%;
                                .dropbtn {
                                    font-weight: normal;
                                }
                                &__menu {
                                    .svg-search-absolute {
                                        left: 2px;
                                    }
                                    .scrolls {
                                        max-height: 250px;
                                    }
                                }
                            }
                        }
                        &-footer {
                            height: 64px;
                            box-shadow: inset 0px 1px 0px $grey-softlight;
                            justify-content: flex-end;
                        }
                        .check-items {
                            width: 100%;
                            margin-top: 8px;
                        }
                    }
                    .check-items {
                        margin-right: 3px;
                        margin-top: -4px;
                    }
                    .item-checkbox {
                        height: 22px;
                    }
                    .select-date {
                        position: relative;
                        width: 100%;
                        svg {
                            position: absolute;
                            top: 3px;
                            left: 6px;
                        }
                        .field-input {
                            padding-left: 30px;
                        }
                    }
                    .search-input {
                        width: 100%;
                        input {
                            box-shadow: none;
                        }
                    }
                    .select-list {
                        width: 100%;
                        max-height: 235px;
                    }
                    .v2-dropdown {
                        &__menu {
                            z-index: 3;
                        }
                        &--searchlist {
                            .header-search {
                                padding: 6px;
                            }
                            .field-search {
                                border: solid 1px $grey-light-2;
                            }
                            .check-items {
                                width: 100%;
                            }
                            .box-auto {
                                max-height: 250px;
                            }
                        }
                    }
                    .field {
                        width: calc(100% - 30px);
                        margin-top: 10px;
                        margin-left: 30px;
                        &--time {
                            @include flexcenter;
                            .separator {
                                width: 12px;
                                text-align: center;
                            }
                            .field-input {
                                width: calc(33% - 8px);
                            }
                        }
                    }
                }

                .detail-list {
                    width: 100%;
                    max-height: 80px;
                    margin-right: -10px;
                }

                .wrapper__filter-tags {
                    height: 24px;
                }
                .txt-ellipsis {
                    display: block;
                }
            }

            .body-modal--viewfilter {
                .filter-list {
                    display: none;
                }
                .filter-result {
                    display: block;
                }
            }

            .footer-modal {
                justify-content: flex-end;
                flex: 0 0 auto;
            }
        }

        &--preview {
            @include betweentop;
            width: 900px;
            color: $grey-dark;

            .body-modal {
                border: 0px;
                @include size(100%);
                @include betweentop;
            }
            .tab-selectors {
                width: 220px;
                padding: 24px;
            }
            .tab-items {
                height: 32px;
                width: 100%;
                border-radius: 4px;
                padding: 0px 12px;
                cursor: pointer;
                @include flexcenter;
                &.active-tab-selector {
                    background: $yellow-light;
                    color: $grey-extradark;
                }
            }
            .tab-contents {
                width: calc(100% - 220px);
                height: calc(100% - 4px);
                padding: 22px;
                padding-right: 48px;
                margin: 2px;
            }
            .tab-conts__description {
                padding-bottom: 30px;
            }
            .preview-title {
                font-size: 14px;
                font-weight: 500;
                color: $grey-extradark;
                margin-bottom: 50px;
                &--email {
                    margin-bottom: 0px;
                }
                &--sms {
                    border-top: solid 1px $grey-tinsmith;
                    margin-top: 24px;
                    padding-top: 26px;
                }
            }
            .container-content-email {
                margin-top: 26px;
            }
            .container-footer-email {
                margin: 0 auto;
            }
        }
    }
}
// Modal has preview layout
.wrapper-has-preview {
    &.open {
        overflow-x: auto;
        .bg-fixed {
            position: absolute;
        }
    }
    // Preview template
    &.is-preview-template {
        .wrap-header {
            padding: 8px 24px;
            background: $white-lotion;
        }
        .wrap-container__preview {
            min-width: 680px;
            width: 680px;
            color: $black-eerie-dark;
            .container-content-email {
                background: $white-flash;
            }
            .wrap-content-email {
                background: $white;
                word-break: break-word;
            }
            .avatar-tech img {
                border-radius: 50%;
                object-fit: cover;
                width: 160px;
            }
        }
        .is-subject {
            font-size: 16px;
            font-weight: 500;
            padding: 24px 24px 12px;
        }
        .tabs-content {
            margin-bottom: -8px;
            &__item {
                cursor: pointer;
                margin-right: 4px;
                padding: 6px 12px;
                border: solid 1px $transparent;
                border-bottom: none;
                border-radius: 4px 4px 0px 0px;
                &:hover,
                &.active {
                    border-color: $black-extradark;
                    background: $white;
                }
            }
        }
        .content-insert-variable {
            &:not(:last-child) {
                border-bottom: 1px solid $black-extradark;
            }
            .detail-repeaton {
                &:has(.tab-items:not(.active-tab-selector)) {
                    .active-tab-selector {
                        background-color: $background-title;
                    }
                }
                .active-tab-selector {
                    background-color: $green-default;
                }
            }
            .tab-items {
                padding: 0 8px;
            }
        }
        .title {
            font-weight: 500;
            color: $black;
            &-sm {
                margin-bottom: 3px;
            }
            svg {
                margin: 0 4px 0 -4px;
            }
        }
        .box-use-variable {
            padding: 12px 20px 16px;
            .box-header {
                > .v2-btn-main {
                    margin-left: 8px;
                }
                &__left {
                    min-width: fit-content;
                }
            }
            .dots {
                &::before {
                    @include size(3px);
                    margin: 2px 9px 0;
                }
                label {
                    margin-top: -2px;
                }
            }
            .sms-count {
                .tooltip {
                    margin-top: -2px;
                }
            }
            .DraftEditor-editorContainer {
                max-height: 350px;
                height: auto;
            }
            .file:has(.preloader-small) {
                .file__items {
                    max-width: calc(100% - 40px);
                }
            }
        }
        .box-reminder {
            padding: 12px 20px;
            .switch {
                display: inline-flex;
                &__label {
                    min-width: 58px;
                }
            }
            &__desc {
                margin-left: 8px;
                display: inline;
            }
            .field-input {
                width: 40px;
                padding: 5px;
            }
        }
        .filter-service {
            .v2-dropdown {
                &__menu {
                    max-width: 350px;
                    z-index: 3;
                }
                .search-input {
                    border-bottom: solid 1px $border-color-grey;
                    margin: 0 -4px 4px;
                    padding: 4px;
                }
            }
        }
        .list-services {
            @include flexwrap;
            .tag-label {
                background: $black-verydark;
                margin: 4px 4px 0 0;
                padding: 2px 0 2px 6px;
                max-width: 100%;
                min-width: auto;
                &__name {
                    cursor: default;
                    line-height: 16px;
                    word-wrap: break-word;
                    white-space: normal;
                }
            }
        }
        .wrap-dropmenu {
            border: 1px solid $border-color-grey;
            display: inline-flex;
            border-radius: 4px;
            .v2-dropdown {
                &:not(.active) .dropbtn {
                    border-left: 1px solid $border-color-grey !important;
                    border-radius: 0 4px 4px 0;
                }
                .dropbtn:hover {
                    border-color: $transparent;
                }
            }
            .field-input {
                width: 90px;
                padding: 5px 8px;
            }
        }
    }
    &.is-preview-document {
        .wrap-document-edit {
            &:not(.has-edit) {
                .wrap-footer {
                    display: none;
                }
                .wrap-body {
                    opacity: 0.5;
                }
                .edit-document-form {
                    pointer-events: none;
                }
            }
            .wrap-body {
                background-color: $grey-softlight;
                padding: 16px;
            }
            .edit-document-form {
                padding: 32px;
                background: $white;
                border-radius: 4px;
                min-height: 100%;
            }
        }
        .wrap-document-preview {
            width: 800px;
            min-width: 750px;
            max-width: calc(100% - 530px);
            .wrap-body {
                padding: 0px 25px;
            }
            .page-document {
                max-width: 100%;
                margin-bottom: 10px;
                background-color: $white;
                td {
                    box-sizing: content-box;
                }
            }
        }
        .wrap-header {
            @include flexcolumns;
            max-height: 40%;
            &__conts {
                @include betweenitems;
                padding: 16px 16px 12px;
                > svg {
                    margin-right: 10px;
                }
                > .v2-dropdown {
                    > .v2-dropdown__menu {
                        width: max-content;
                    }
                }
            }
            .rows-description {
                padding: 0 18px 16px 20px;
                &:not(.wrap-header__conts) {
                    overflow-y: auto;
                }
                .log-items {
                    margin-bottom: 10px;
                    word-break: break-word;
                    svg {
                        margin: -5px 3px 0 -3px;
                    }
                }
            }
            .check-items {
                flex: 1;
            }
        }
    }
    > .modal__container {
        width: fit-content;
        max-width: 100%;
        right: 0px;
    }
    .wrap-container {
        height: 100%;
        @include flextop;
        justify-content: flex-end;
        flex-direction: row-reverse;
        &__form {
            width: 530px;
            flex: 0 0 auto;
            border-left: 1px solid $border-color-grey;
        }
        &__preview {
            &:not(.is-open) {
                display: none;
            }
        }
    }
    .flex-column {
        height: 100%;
        background-color: $white;
    }
    .wrap-header {
        flex: 0 0 auto;
        box-shadow: inset 0px -1px 0px $border-color-grey;
    }
    .wrap-body {
        @include size(100%);
        flex: 1;
        overflow-y: auto;
    }
    .wrap-footer {
        border-top: 1px solid $border-color-grey;
        @include flexend;
        padding: 12px 16px;
        z-index: 1;
        background-color: $white;
    }
    .wrap-plans__option {
        .flex-column {
            height: auto;
        }
    }
}
// form-square-sdk
.form-square-sdk {
    .sq-card-iframe-container {
        background-color: $white !important;
        &::before {
            border-color: $border-color-grey !important;
            box-shadow: $boxshadow-yellow;
        }
    }
    .footer-modal {
        padding: 0px 20px 18px !important;
        height: auto;
    }
}
// Time Window popup
.wrapper-timewindow {
    .modal__container {
        width: 550px;
    }
    .header-modal,
    .footer-modal {
        padding-left: 18px;
    }
    .header-modal {
        border-bottom: 1px solid $grey-platinum;
        &__label {
            flex: 0 0 auto;
        }
    }
    .switch {
        flex: 1;
        margin-right: 8px;
        &__label {
            margin-right: 8px;
        }
    }
    .body-modal {
        padding: 0px;
        border: none;
        font-size: 14px;
        @include flexcolumns;
        .alert {
            max-width: calc(100% - 24px) !important;
            margin: 12px 12px 0px !important;
        }
    }
    .box-selection {
        padding: 16px;
        border-bottom: 1px solid $grey-platinum;
        &.is-selected {
            background: $yellow-extralight;
        }
        &.--template {
            max-height: 50vh;
            overflow-y: auto;
        }
        &.--custom {
            color: $black;
        }
        &__title {
            margin-bottom: 8px;
            font-weight: 500;
            font-size: 14px;
            color: $black-5;
        }
        .check-radio {
            & + .check-radio {
                margin-top: 10px;
            }
        }
        .template-name {
            margin-right: 0px;
            line-height: 20px;
            word-break: break-word;
            &__label {
                display: inline;
                color: $black-dark-charcoal;
                margin-right: 8px;
            }
            &__time {
                color: $grey-generic;
            }
        }
    }
    .wrap-jobs-time {
        position: absolute;
        top: -66px;
        height: 64px;
        width: 100%;
        padding: 8px 18px;
        background: $white;
        border-radius: 4px;
        @include flexcenter;
        &__desc {
            flex: 1;
            @include flexcenter;
            overflow: hidden;
            white-space: nowrap;
            margin-right: 12px;
            line-height: 24px;
        }
    }
    .desc-time {
        color: #808080;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
    }
}
.modal-list-template {
    .body-modal {
        @include font-color(20px, 14px, $black);
    }
    .name-templates {
        border-top: 1px solid $black-extradark;
        padding: 12px;
    }
    .wrap-editor {
        margin-top: 1px;
    }
    &.--note-template {
        .name-templates {
            border-bottom: 1px solid $black-extradark;
            .v2-dropdown {
                &__menu {
                    right: 0;
                    min-width: 180px;
                }
            }
            .field-input::placeholder {
                color: $black-darker;
            }
        }
        .wrap-content {
            .wrapbox-editor {
                border: 0;
                border-radius: 0;
                &__controls {
                    .v2-btn-main:not(.is-active) {
                        opacity: 0.6;
                        pointer-events: none;
                    }
                }
                .DraftEditor-editorContainer {
                    height: auto;
                    max-height: calc(90vh - 165px);
                }
            }
        }
    }
}
.wrapper-pane-sub {
    &__content {
        background: $white;
        border-right: solid 1px $border-color-grey;
        position: absolute;
        right: 970px;
        top: 0px;
        height: 100%;
        width: 350px;
    }
    .wrap-content {
        height: calc(100% - 56px);
        overflow-y: auto;
    }
    .border-bt-grey {
        border-bottom: 1px solid $black-extradark !important;
    }
    .wrap-search {
        .search-input {
            padding: 3px 4px 3px 5px;
            input {
                border: none;
                box-shadow: none !important;
            }
            .svg-search-absolute {
                left: 5px;
                top: 48%;
            }
        }
    }
    .boxs {
        border: none !important;
        border-radius: 0 !important;
        & + .boxs {
            margin-top: 0px;
        }
        .header-box {
            padding-top: 12px;
        }
    }
    @media screen and (max-width: 1355px) {
        @include size(100%);
        position: fixed;
        top: 0;
        left: 0;
        z-index: 10000;
        .is-overlay {
            position: fixed;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: $black-darker;
        }
        .wrapper-pane-sub__content {
            @include transform(translate(-50%, -50%));
            border: solid 1px $border-color-grey;
            border-radius: 4px;
            left: 50%;
            right: auto !important;
            top: 50%;
            height: 95%;
            width: 400px;
        }
    }
}
.wrap-existing-images {
    .wrapper-pane-sub__content {
        width: 400px;
    }
    .wrap-content {
        flex: 1;
        column-count: 2;
        column-gap: 8px;
        height: auto;
        padding: 4px;
    }
    .wrap-images {
        border: 1px solid $transparent;
        border-radius: 8px;
        display: inline-block;
        padding: 4px;
        margin-bottom: 4px;
        max-width: 190px;
        width: 100%;
        &.is-selected {
            background: rgba(141, 74, 252, 0.15);
            border: 1px solid $purple-default;
        }
        &__desc {
            color: $grey-very-dark;
            font-size: 11px;
            line-height: 16px;
            margin-top: 2px;
            word-break: break-word;
        }
        .check-items {
            position: absolute;
            top: 10px;
            left: 10px;
            line-height: normal;
            label::before {
                @include size(18px);
                left: 0px;
                top: -14px;
            }
            input[type='checkbox']:checked + .item-checkbox label {
                &::after {
                    top: -9px;
                    left: 5px;
                }
                &::before {
                    border: solid 2px $white !important;
                }
            }
        }
        img {
            border-radius: 4px;
        }
    }
    .wrap-footer {
        background: $white;
        box-shadow: inset 0px 1px 0px $border-color-grey;
        padding: 8px 16px;
    }
}
// Modal New API Key
.modal-api-connect {
    .modal__container {
        min-width: 524px;
    }
    &.--your-api-key {
        .body-modal {
            padding: 53px 0 40px 0;
        }
        .rows {
            @include centeritem;
            > svg {
                @include size(44px);
            }
            &.--title {
                margin-top: 13px;
                line-height: 20px;
            }
            &.--api-key {
                margin: 16px 0;
                .name {
                    padding: 6px;
                    width: calc(75% - 32px);
                    max-width: 324px;
                    height: 32px;
                    background-color: $black-verylight;
                    border: 1px solid $black-extradark;
                    border-radius: 4px 0 0 4px;
                }
                .v2-btn-main {
                    border-radius: 0 4px 4px 0;
                }
            }
        }
    }
}

// Modal add new recording
.modal-recording {
    .modal__container {
        max-width: 520px;
        width: 100%;
        .text-body-modal {
            padding: 20px 23px;
            border-bottom: 1px solid $border-color-grey;
            color: $black-eerie-dark;
        }
        .content-body-modal {
            padding: 20px 23px;
        }
        .description {
            max-width: 250px;
            margin: 15px auto 0;
        }
    }
    .body-modal {
        .v2-btn-default {
            height: 40px;
            padding: 8px 24px 8px 16px;
            &.--color-icon {
                svg {
                    @include size(14px);
                }
            }
            &.--gray {
                rect {
                    fill: $grey-very-dark;
                }
            }
            &.stop-btn {
                background-color: $blue-light !important;
                color: $blue-default;
                border-color: $transparent !important;
                &:hover {
                    border-color: $transparent !important;
                }
            }
        }
    }
}

// Modal Verify Number
.modal-verify-number {
    .body-modal {
        color: $black-eerie-dark;
        .v2-btn-default {
            height: 40px;
            padding: 8px 24px 8px 16px;
        }
    }
    .verify-code {
        font-size: 60px;
    }
    .line {
        border-top: 1px solid $border-color-grey;
        margin: 20px 0;
    }
    .select-caller {
        max-width: 270px;
        margin: 0px auto;
        .txt-phone {
            text-align: left;
        }
    }
    @media (max-height: 800px) {
        .v2-dropdown__menu {
            max-height: 30vh;
        }
    }
}
.column-preferences {
    .valid-phone {
        &.--just-icon {
            .valid-phone__number {
                max-width: calc(100% - 70px);
            }
            .valid-phone__name {
                margin-left: 0px;
            }
        }
        &__name {
            color: $black;
            @extend .fw-600;
            margin-left: 4px;
            @include text-ellipsis;
            width: 40%;
            flex: 1;
        }
        &__btn {
            min-width: fit-content;
            margin-right: 4px;
        }
    }
}

// Modal Task & Opportunity
.box-custom-sidebar {
    &:not(.modal__container) {
        position: fixed;
        width: 300px;
        right: 328px;
        top: 50%;
        @include transform(translateY(-50%));
        z-index: 105;
        @media (max-width: 1023px) {
            position: absolute;
        }
        @media (max-height: 840px) {
            top: 55%;
        }
        &:not(.open) {
            display: none;
        }
    }
    &:has(.react-datepicker__tab-loop) {
        z-index: 10002;
    }
    .react-datepicker__day {
        @include size(40px);
        &-name {
            width: 40px;
        }
    }
    .box__container {
        background-color: $white;
        box-shadow: 0 3px 8px 0 $black-light, 0 2px 6px 0 $black-light, 0 0 8px 2px $black-extradark;
        border-radius: 4px;
        max-height: 80dvh;
        height: auto;
        width: 100%;
        .wrap-header {
            padding: 12px 15px;
        }
        .box-form {
            overflow-y: auto;
            max-height: calc(80dvh - 120px);
            font-size: 14px;
            color: $grey-dark;
            @media (max-height: 700px) {
                max-height: calc(65dvh - 120px);
                .v2-dropdown {
                    &__menu {
                        max-height: 250px;
                    }
                }
            }
            .v2-dropdown__menu {
                z-index: 2;
                @media (max-height: 620px) {
                    max-height: 200px;
                }
            }
        }
    }
}

// Modal edit opportunity
.edit-opportunity,
.box-custom-sidebar {
    .select-status {
        .txt-ellipsis {
            white-space: nowrap !important;
        }
        .line {
            display: none;
        }
        .items + .line {
            display: block;
        }
        .selection {
            padding: 0 6px 0 4px;
        }
    }
    .cost-input::after {
        border-color: $grey-soft;
        background-color: $white-grey;
    }
    .wrap-plans {
        height: 32px;
        padding: 0 10px;
        border-radius: 4px;
        border: 1px solid $grey-soft;
        .progress-bar {
            .range-overlay {
                background: $purple-default;
            }
            .slider-main {
                width: 100%;
                &::-webkit-slider-thumb {
                    background: $purple-default;
                    @include size(14px);
                    &:hover {
                        box-shadow: 0 0 0 4px rgba($color: $purple-default, $alpha: 0.3);
                    }
                }
            }
        }
    }
}

.edit-opportunity {
    .modal__container {
        width: 300px;
        .box__container {
            max-height: 90dvh;
            .box-form {
                max-height: calc(90dvh - 120px) !important;
            }
        }
    }
}

// Select status
.select-invoice-stt,
.select-status,
.filter-result__detail .v2-dropdown:has(.dots) {
    .dots {
        @include size(24px);
        position: relative;
        &::before {
            @include size(8px);
            position: absolute;
        }
        &.--active {
            &::before {
                background-color: $orange-ripe;
            }
        }
        &.--won {
            &::before {
                background-color: $green-default;
            }
        }
        &.--lost {
            &::before {
                background-color: $grey-sonic-silver;
            }
        }
    }
}
.modal-smart-views {
    .modal__container {
        margin-right: 0;
        max-height: 100%;
        height: 100%;
        border-radius: 0px;
        width: 450px;
        .body-modal {
            max-height: none;
            padding: 0px;
        }
        .header-modal,
        .footer-modal {
            padding: 16px;
        }
        .header-modal {
            height: auto;
            flex-wrap: wrap;
            flex: 0 0 auto;

            &__title {
                @include flexcenter;
                @include font-color(20px, 14px, $black);
                @extend .fw-600;
                gap: 4px;
            }
            &__close {
                cursor: pointer;
                &:hover {
                    filter: brightness(0.5);
                }
            }
            &__search {
                width: 100%;
                margin-top: 16px;
                .search-input {
                    input {
                        @include placeholder {
                            color: $grey-soft-txt;
                        }
                    }
                }
            }
        }
        .body-modal {
            font-size: 14px;
            .filter-result {
                line-height: 20px;
                @include font-color(20px, 13px, $black-darker-txt);
                font-weight: 500;
                &__title {
                    @include flexcenter;
                    gap: 16px;
                    padding: 22px 16px 0 16px;
                    > .txt-ellipsis {
                        @include font-color(20px, 16px, $black);
                        @extend .fw-600;
                    }
                }
                .field-title {
                    text-transform: uppercase;
                    padding: 10px 0 12px 40px;
                    border-top: 1px solid $border-color-grey;
                }
                &__detail {
                    padding: 0 16px 24px 16px;
                    section {
                        > .txt-ellipsis {
                            @extend .fw-600;
                            margin-top: 12px;
                            margin-bottom: 3px;
                            display: inline-block;
                            @include font-color(20px, 14px, $grey-very-dark);
                        }
                        & + section {
                            > .txt-ellipsis {
                                margin-top: 20px;
                            }
                        }
                    }
                    .v2-dropdown {
                        font-weight: 400;
                        &:has(.dots) {
                            .items {
                                label {
                                    @include flexcenter;
                                    &::before {
                                        top: 4px;
                                    }
                                    &::after {
                                        top: 8px;
                                    }
                                    gap: 4px;
                                    .txt-ellipsis {
                                        white-space: nowrap;
                                    }
                                }
                            }
                        }
                        &:has(.tag-label) {
                            .dropbtn {
                                .tag-label {
                                    padding-right: 6px;
                                }
                                .box-tags {
                                    width: calc(100% - 24px);
                                    white-space: nowrap;
                                    flex-wrap: nowrap;
                                }
                            }
                            &.active {
                                .dropbtn {
                                    padding: 4px 6px 4px 8px;
                                    height: auto;
                                    min-height: 32px;
                                }
                                .box-tags {
                                    white-space: wrap;
                                    flex-wrap: wrap;
                                }
                            }
                            .v2-dropdown__menu {
                                .check-items label {
                                    &::before {
                                        top: 6px;
                                    }
                                    &::after {
                                        top: 10px;
                                    }
                                }
                                .tag-label {
                                    width: fit-content;
                                    margin-top: 4px;
                                }
                            }
                        }
                        &:has(.--job-status) {
                            .dropbtn {
                                position: relative;
                                justify-content: flex-start;
                                gap: 4px;
                                overflow: hidden;
                                .arrow {
                                    position: absolute;
                                    right: 0;
                                    z-index: 1;
                                    background-color: $white;
                                    height: 100%;
                                    width: 28px;
                                }
                                .--job-status {
                                    height: 20px;
                                    line-height: 20px;
                                    font-size: 11px;
                                }
                            }
                            .check-items {
                                width: 100%;
                                .item-checkbox {
                                    & > label {
                                        @include flexcenter;
                                        gap: 4px;
                                        flex-wrap: wrap;
                                        &:not(:has(.status-btn)) {
                                            line-height: 24px;
                                        }
                                        &::before {
                                            top: 4px;
                                        }
                                        &::after {
                                            top: 8px;
                                        }
                                    }
                                }
                            }
                            .--job-status {
                                width: auto;
                                white-space: nowrap;
                                background-color: var(--bg-job-status);
                                color: var(--color-job-status);
                            }
                        }
                    }
                    & + .filter-result__title {
                        padding-top: 0;
                    }
                }
                > .rows {
                    height: 48px;
                    padding: 0 16px;
                    @include flexcenter;
                    border-bottom: 1px solid $border-color-grey;
                    cursor: pointer;
                    > .txt-ellipsis {
                        flex: 1;
                        font-size: 14px;
                        font-weight: 500;
                        @include font-color(20px, 14px, $black-darker-txt);
                    }
                }
            }
            .result {
                @include flexcolumns;
                gap: 8px;
                font-size: 14px;

                & + .result {
                    margin-top: 4px;
                }
                &__label {
                    @include text-ellipsis;
                    margin-bottom: 0px;
                }
                > .flexcenter {
                    gap: 8px;
                }
                .is-between {
                    @include flexcolumns;
                    gap: 8px;
                    .tabs,
                    .field-input {
                        width: 50%;
                    }
                    .tabs {
                        .btn-item {
                            flex: 1;
                        }
                        .tab-items {
                            width: 50%;
                            @include centeritem;
                        }
                    }
                }
                > *:last-child:not(.dp-hide):not(.flexcenter):not(:first-child) {
                    margin-bottom: 6px;
                }
                > .check-items {
                    .txt-ellipsis {
                        display: inline-block;
                        max-width: 100%;
                        line-height: 22px;
                        padding-left: 0;
                        svg {
                            margin-right: 6px;
                        }
                    }
                    label {
                        padding-left: 26px;
                    }
                    .item-checkbox {
                        height: 24px;
                    }
                }
                > .check-items,
                .check-items:has(.item-checkbox .align-center .avt-img) {
                    input[type='checkbox']:checked + .item-checkbox label::after {
                        top: 8px;
                    }
                    label::before {
                        top: 4px;
                    }
                }
            }
            .wrap-time {
                @include flexcenter;
                gap: 3px;
                .field-input {
                    padding-left: 5px;
                }
            }
            .field-input {
                flex: 1;
            }
            .field-date {
                min-height: 32px;
            }
            .v2-dropdown {
                &__menu {
                    z-index: 2;
                }
            }
            .search-input {
                .react-datepicker-wrapper {
                    .react-datepicker__input-container > .relative {
                        z-index: 0;
                    }
                }
            }
        }
    }
    &:not(.--filter-tags) {
        .modal__container {
            .body-modal {
                .filter-result {
                    &__detail {
                        padding-left: 58px;
                    }
                }
                .result {
                    .field {
                        width: calc(100% - 30px);
                        margin-left: 30px;
                        .field {
                            margin-left: 0;
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
}

.ReactModal__Body--open:has(.connection-bar),
.calendar-page:has(.connection-bar):has(.modal.open) {
    .wrapper-call {
        &:has(.call-bar) {
            margin-top: -41px;
        }
        &.is-disable-calls {
            margin-top: 0;
            .btn-collapse {
                top: -41px;
            }
        }
    }
}

.ReactModal__Body--open {
    .gorilladesk {
        overflow-y: hidden !important;
    }
}
.ReactModal__Body--open,
.gorilladesk:has(.modal.open) {
    .wrapper-call {
        position: sticky !important;
        top: 0;
    }
}

.modal-edit-smartview {
    .modal__container {
        .body-modal {
            padding: 0;
            overflow: auto;
            @include flexcolumns;
        }
        .row {
            padding: 16px 23px;
            & + .row {
                margin-top: 0;
                border-top: 1px solid $border-color-grey;
            }
            .txt:not(.fs-14) {
                padding-left: 8px;
                @extend .fw-600;
            }
        }
        .is-shared {
            .check-radio input[type='radio'] {
                & + label:before,
                &:checked + label:after {
                    border-radius: 50%;
                }
            }
        }
        .is-shared-with {
            overflow: auto;
            @media only screen and (max-height: 575px) {
                overflow: visible;
            }
        }
        .items {
            & + .items {
                margin-top: 2px;
            }
            cursor: pointer;
            border-radius: 4px;
            &:hover {
                color: $grey-extradark;
                background-color: $btn-hover;
            }
            > label,
            .item-checkbox > label {
                @include flexcenter;
                width: 100%;
                min-height: 32px;
                line-height: 24px;
                margin-right: 0;
                padding-left: 33px;

                &::before {
                    top: 8px;
                    left: 8px;
                }
                &::after {
                    top: 12px !important;
                    left: 12px !important;
                }
            }
            .item-checkbox {
                height: auto;
                .txt-ellipsis {
                    word-break: break-word;
                    white-space: normal;
                }
            }
            &:has(input[type='radio']:checked),
            &:has(input[type='checkbox']:checked) {
                color: $grey-extradark;
                background-color: $btn-press;
            }
        }
        .alert {
            max-width: 90% !important;
            margin: 12px auto 0 !important;
        }
    }
}

.modal-add-bank {
    .description-bank {
        background-color: $blue-extralight;
        padding: 8px;
        border-radius: 4px;
    }
    .alert {
        &__description {
            text-align: center;
            &:not(:first-child) {
                margin-top: 8px;
            }
        }
    }
}

// Modal Add/Edit Custom Field
.modal-add-field {
    .modal__container {
        .row-items {
            margin-bottom: 5px;
            @include betweenitems;
            .field-input {
                width: calc(100% - 36px);
            }
            .txt-ellipsis {
                width: calc(100% - 37px);
                line-height: 32px;
                display: none;
            }
        }
    }
}
// Service Plan
.service-plan-container {
    .details-job__content:not(.detail-plan),
    .tab-selectors:not(.--service-plan),
    .wrap-notify {
        display: none;
    }
    .numerical-order {
        width: 24px;
    }
    .detail-plan {
        margin-top: -15px;
    }
    .detail-plan__items {
        position: relative;
        padding: 24px 0;
        border-bottom: 1px solid $border-color-grey;
        .list-job-status {
            .dropbtn {
                height: 24px;
                max-width: 137px;
                min-width: unset;
                font-size: 13px;
                .tag-label {
                    min-height: 16px;
                    line-height: 16px;
                    font-size: 10px;
                }
            }
            .v2-dropdown__menu {
                max-width: 300px;
                width: auto;
            }
        }
        &:not(.active) {
            & > .rows,
            & > .justify-end {
                display: none !important;
            }
        }
        &.active {
            .--icon-sm svg {
                @include transform(rotateX(180deg));
            }
            .border-bottom-border-color-grey {
                border-bottom: 0 !important;
                .detail-schedule {
                    margin-bottom: 0;
                }
            }
        }
        .wrap-member-group {
            user-select: none;
        }
    }
    .item-right {
        width: calc(100% - 173px);
        .date {
            width: 80px;
        }
        .time {
            width: 65px;
        }
        .wrap-member-group {
            .avt-img {
                &:nth-child(n + 2) {
                    margin-left: -10px;
                }
                &,
                .avt {
                    @include size(26px);
                    border: 2px solid $white;
                }
                & > img {
                    @include size(100%);
                }
                &.--primary {
                    border-color: $orange-default;
                }
                &.avt-more {
                    z-index: unset;
                    svg {
                        @include size(22px);
                    }
                }
            }
        }
        .list-techs {
            width: calc(100% - 261px);
        }
    }
    .next-job {
        position: absolute;
        top: -8px;
        left: 24px;
    }
}
// Modal best available time
.modal-available-time {
    .modal__container {
        max-width: 1150px;
        width: 95%;
        min-width: 650px;
    }
    .box-filter {
        width: 370px;
        padding: 24px;
        .dropbtn {
            height: auto;
            min-height: 32px;
            padding: 3px 6px 3px 8px;
            .list-schedules {
                width: calc(100% - 20px);
            }
            .schedule-user {
                max-width: 100%;
            }
            &:has(.budget) {
                .schedule-user:nth-last-child(2) {
                    max-width: calc(100% - 45px);
                }
            }
        }
        .wrapper-choosetime {
            .v2-dropdown__menu {
                max-height: 250px;
            }
            .from-time {
                .v2-dropdown,
                .dropbtn {
                    width: 100%;
                }
                .time-text,
                & {
                    flex: 1;
                }
            }
            @media (max-height: 800px) {
                .v2-dropdown__menu {
                    top: unset;
                    bottom: calc(100% + 4px);
                }
            }
        }
        .view-buffer {
            .v2-dropdown__menu {
                bottom: calc(100% + 4px);
                top: unset;
            }
        }
    }
    .body-modal {
        padding: 0;
        height: calc(100% - 64px);
        .container-print {
            width: calc(100% - 350px);
            border-left: 1px solid $border-color-grey;
        }
        @media (max-height: 700px) {
            overflow-y: auto;
            .rows:not(:has(.wrapper-choosetime)) {
                .v2-dropdown__menu {
                    max-height: 240px;
                }
                .status-dropdown {
                    max-height: 195px;
                }
            }
        }
    }
    .wrap-tables {
        height: 482px;
        .col-dot {
            max-width: 32px;
            min-width: 32px !important;
            .dots::before {
                @include size(12px);
                margin-bottom: -8px;
            }
        }
        .rows {
            position: relative;
            &.--header {
                z-index: 2 !important;
            }
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                @include size(100%);
                opacity: 0.06;
                background-color: var(--color-best-time);
            }
            .dots::before {
                background-color: var(--color-best-time);
            }
            .col {
                &.col-sm {
                    max-width: 112px !important;
                }
                &.col-md-plus {
                    min-width: 145px !important;
                }
            }
        }
        .tables-list {
            &:has(.content-empty) {
                position: sticky;
                left: 0;
            }
            .col {
                z-index: 1;
            }
        }
    }
}

@media screen and (min-device-width: 641px) {
    .modal-download-mobile {
        display: none;
    }
}
@media screen and (max-device-width: 640px) {
    .modal-download-mobile {
        .modal__container {
            width: 95%;
            max-width: 400px;
        }
    }
}

// Modal Choose color
.modal-choose-color {
    .chrome-picker {
        box-shadow: none !important;
        border-radius: 0 !important;
        background-color: $white !important;
        & > div:first-child {
            border-radius: 0 !important;
        }
        & > *:has(.flexbox-fix) {
            padding: 16px 0 0 !important;
            .flexbox-fix {
                font-family: 'Inter', sans-serif !important;
                & > div:has(svg) {
                    display: none;
                }
            }
            & > .flexbox-fix:first-child {
                align-items: center;
                & > div:first-child {
                    width: 36px !important;
                    & > div:first-child {
                        @include size(24px !important);
                        border-radius: 50% !important;
                        box-shadow: 0 0 0 1px $gray-iron;
                        &::after {
                            content: 'A';
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            color: var(--color-holiday);
                            font-size: 14px;
                            font-weight: 500;
                            z-index: 2;
                        }
                        & > div:first-child {
                            box-shadow: none !important;
                        }
                    }
                }
            }
            div:has(> input) {
                @include flexcenter;
                gap: 10px;
                input {
                    height: 32px !important;
                    & + label,
                    & {
                        font-size: 13px !important;
                        color: $black-3 !important;
                    }
                    & + label {
                        margin-top: 0 !important;
                    }
                }
            }
        }
    }
}
