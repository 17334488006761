.header-v2 {
    width: 100%;
    height: 64px;
    line-height: 1.43;
    color: $black-2;
    background: var(--background);
    flex: 0 0 auto;
    border-bottom: 1px solid $border-color-grey;
    box-shadow:
        0px 0px 3px rgba(0, 0, 0, 0.06),
        0px 0px 2px rgba(0, 0, 0, 0.04),
        0px 0px 1px rgba(0, 0, 0, 0.08);
    position: relative;
    .btn-collapse {
        position: absolute;
        left: 52px;
        z-index: 100005;
        border-top: 0;
        border-radius: 0 0 4px 4px;
        background-color: $white;
        svg {
            @include transform(rotateZ(-90deg));
        }
        .tooltiptext {
            &:last-child {
                display: none;
            }
        }
    }
    &-right {
        justify-content: flex-end;
        margin-left: 5px;
        .v2-dropdown:not(.wrap-nav):not(.title-message-chat) {
            &:not(.active) .arrow {
                margin-top: -1px;
            }
            &.active .arrow {
                margin-top: 2px;
            }
        }
        .nav-clock {
            border-radius: 82px;
            color: $white;
            padding: 4px 8px 4px 4px;
            margin-right: 16px;
            cursor: pointer;
            min-width: 102px;
            height: 32px;
            line-height: 24px;
            color: $white-default;
            &:not(.--stop) {
                background-color: $blue-default;
            }
            &.--stop {
                background-color: $red-default;
            }
        }
        .alert {
            position: fixed;
            z-index: 100000;
            right: 16px;
            bottom: 16px;
            width: fit-content;
            max-width: calc(100% - 32px);
        }
    }
    .container {
        @include size(100%);
        display: grid !important;
        grid-template-columns: 1fr auto;
        gap: 45px;
        &__header {
            @include flexcenter;
        }
        .tooltip .bottom {
            top: calc(100% + 4px);
            z-index: 10003;
        }
    }
    &-left {
        flex: 1;
        display: grid !important;
        grid-template-columns: auto auto auto 1fr;
    }
    .collapse-sidebar {
        height: 100%;
        .btn-sidebar {
            width: 64px;
            height: 100%;
            border-right: solid 1px $border-color-grey;
            cursor: pointer;
            @include centeritem;
            .active {
                display: none;
            }
        }
    }
    .v2-dropdown {
        &.active,
        &:hover {
            .v2-dropdown__menu {
                z-index: 1001;
            }
        }
        .v2-dropdown__menu {
            min-width: 200px;
            width: max-content;
            max-height: 70dvh;
        }
    }
    .logo {
        flex: 0 0 auto;
        margin: 0 0 0 16px;
    }
    .wrap-nav {
        margin: 0 16px;
        .dropbtn {
            cursor: pointer;
            font-weight: 500;
            height: 40px;
            padding: 4px 8px 4px 4px;
            @include flexcenter;
        }
        .nav__label {
            margin: 0 6px;
            line-height: 20px;
            @include text-ellipsis;
        }
    }
    .nav-menu {
        .dropbtn {
            border-radius: 12px;
            min-width: max-content;
        }
        .v2-dropdown__menu {
            top: calc(100% + 4px);
            max-height: 625px;
            min-height: 500px;
            box-shadow: $boxshadow-dropdown;
            visibility: hidden;
            opacity: 0;
            transition:
                visibility 0s,
                opacity 0.5s linear;
            .col-half {
                .line {
                    margin: 4px 0 !important;
                }
            }
            .nav-menu__item {
                .items {
                    .default {
                        display: block;
                    }
                    .active {
                        display: none;
                    }
                    &.active {
                        .default {
                            display: none;
                        }
                        .active {
                            display: block;
                        }
                    }
                    .txt-ellipsis {
                        font-weight: 500;
                    }
                    .btn-stick {
                        &.active {
                            display: block;
                        }
                        .tooltiptext.top {
                            top: unset;
                            bottom: calc(100% + 4px);
                            left: 50%;
                            @include transform(translateX(-50%));
                        }
                    }
                }
                .v2-dropdown__menu {
                    max-height: 320px;
                    overflow-x: hidden;
                    .nav-boxs {
                        max-height: 320px !important;
                    }
                    @media (max-height: 680px) {
                        max-height: 250px;
                    }
                }
            }
            &::before {
                content: '';
                width: 100%;
                position: absolute;
                height: 5px;
                margin-top: -5px;
            }
            .wrap-nav-list {
                height: 100%;
                display: flex;
                &:has(.bg-feauture) {
                    .--list-addons {
                        box-shadow: inset -1px 0px 0px $border-color-grey;
                    }
                }
            }
            .line {
                margin: 12px 0px !important;
            }
            .status-btn {
                font-size: 11px;
                font-weight: 500;
                line-height: 20px;
                height: 20px;
                @include flexcenter;
                &.--has-icon {
                    padding-left: 4px;
                    svg {
                        @include size(15px);
                        margin-right: 2px;
                    }
                }
                &:not(.--beta) {
                    background-color: $orange-default;
                }
                &.--growth {
                    background-color: $green-default;
                }
            }
            .nav-boxs {
                @include flexcolumns;
                max-height: 625px;
                min-height: 500px;
                &__title {
                    flex: 0 0 auto;
                    height: 56px;
                    padding: 0px 16px;
                    @include betweenitems;
                    box-shadow: inset 0px -1px 0px $border-color-grey;
                    &:has(.nav-tabs__items:nth-child(-n + 2)) {
                        &:not(:has(.nav-tabs__items:nth-of-type(n + 3))) {
                            padding: 0px 30px !important;
                        }
                    }
                }
                &.--list-addons {
                    .inactive-addons {
                        .items:not(:hover) {
                            opacity: 0.5;
                        }
                    }
                    .active-settings {
                        .items {
                            &:not([class*='svg']) {
                                > svg path {
                                    fill: $purple-light;
                                    &:last-child {
                                        fill: $purple-default;
                                    }
                                }
                            }
                            > svg {
                                @include size(32px);
                                background-color: $grey-extralight;
                                border-radius: 12px;
                                padding: 4px;
                            }
                        }
                        .svg-schedule.--purple {
                            svg path {
                                fill: $purple-light;
                                &:last-child {
                                    fill: $purple-default;
                                }
                            }
                        }
                    }
                    .active-addons,
                    .inactive-addons {
                        .items {
                            svg {
                                height: 24px;
                                @include transform(scale(1.1));
                            }
                        }
                    }
                }
                &.--list-menu {
                    width: 290px;
                    box-shadow: inset -1px 0px 0px $border-color-grey;
                    .nav-boxs__title {
                        @extend .fw-600;
                        font-size: 15px;
                        line-height: 23px;
                        color: $black;
                        border-radius: 4px 0 0 0;
                        &.--pro-plan,
                        &.--growth-plan {
                            justify-content: flex-start;
                            border-right: 1px solid $border-color-grey;
                            box-shadow: inset 0px -1px 0px $border-color-grey;
                            svg {
                                margin-right: 10px;
                            }
                        }
                        &.--pro-plan {
                            color: $orange-default;
                            background: light-dark(#fff2ea, #401800);
                        }
                        &.--growth-plan {
                            color: $green-default;
                            background: $green-mint-cream;
                        }
                    }
                    .nav-list {
                        > li + li {
                            margin-top: 8px;
                        }
                        .items {
                            .default {
                                svg {
                                    rect:first-child,
                                    path:first-child {
                                        fill: $grey-light;
                                    }
                                }
                            }
                        }
                        li:first-child {
                            .tooltiptext {
                                right: calc(100% + 4px);
                                top: 50%;
                                transform: translateY(-50%);
                            }
                        }
                    }
                }
            }
            .nav-tabs {
                &__items {
                    height: 100%;
                    @include flexcenter;
                    white-space: nowrap;
                    & + .nav-tabs__items {
                        margin-left: 20px;
                    }
                }
            }
            .bg-feauture {
                width: 305px;
                min-height: 625px;
                border-radius: 0 4px 4px 0;
                background: url(#{$cdnPathImages}pro-banner.jpg) #efefef center / cover no-repeat;
                & > * {
                    min-height: 500px;
                }
                .title-h1 {
                    line-height: 32px;
                    color: $black-txt;
                }
                .v2-btn-main {
                    position: absolute;
                    left: 20px;
                    bottom: 20px;
                    background-color: $orange-default;
                    font-weight: 500;
                }
                .close-tab {
                    border: 0;
                    padding: 0;
                    background: $transparent;
                    position: absolute;
                    top: 10px;
                    right: 10px;
                }
                &.--promotion {
                    background: url(#{$cdnPathImages}voip-promotion.png) #efefef right bottom no-repeat;
                    background-size: cover;
                    background-position: center;
                }
                &.--growth {
                    background: url(#{$cdnPathImages}growth.jpg) #efefef right bottom no-repeat;
                    background-size: cover;
                    background-position: center;
                    .v2-btn-main {
                        background-color: $green-default;
                    }
                }
            }
            .nav-list {
                flex: 1;
                overflow-y: auto;
                padding: 16px;
                .status-btn {
                    margin-left: 8px;
                    min-width: fit-content;
                }
                .items {
                    border-radius: 15px;
                    padding: 4px;
                    color: $grey-extrlight;
                    font-weight: 500;
                    > svg {
                        width: 32px;
                        margin-right: 8px;
                        flex: 0 0 auto;
                    }
                    &__svg {
                        @include size(32px);
                        background-color: $grey-extralight;
                        border-radius: 8px;
                        margin-right: 8px;
                        @include centeritem;
                    }
                    .txt-ellipsis {
                        white-space: nowrap;
                    }
                }
                .btn-stick {
                    left: unset;
                    right: 10px;
                    .tooltiptext {
                        left: unset;
                        transform: none;
                        right: 0;
                    }
                }
            }
            @media only screen and (max-height: 700px) {
                &,
                .nav-boxs {
                    max-height: calc(100dvh - 70px);
                }
                .bg-feauture {
                    min-height: calc(100dvh - 70px);
                }
            }
        }
        &.active {
            .v2-dropdown__menu {
                display: block;
                visibility: visible;
                opacity: 1;
                z-index: 9999;
                .v2-dropdown {
                    &__menu {
                        display: none !important;
                    }
                    &.active {
                        .dropbtn {
                            background: $btn-press;
                            box-shadow: none !important;
                        }
                        .v2-dropdown__menu {
                            display: flex !important;
                            left: calc(100% + 14px);
                            top: 0;
                            box-shadow: $boxshadow-dropdown;
                        }
                    }
                }
            }
        }
        & + .questlog-menu {
            margin-left: 5px;
        }
    }
    .nav-user {
        .dropbtn {
            border-radius: 55px;
            max-width: 150px;
        }
        .nav__label {
            margin: 0 4px;
            line-height: 1.2;
            flex: 1;
            & > * {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: pre;
            }
        }
        .avatar {
            flex: 0 0 auto;
            img {
                object-fit: cover;
                border-radius: 50%;
                border: 1px solid $border-color-grey;
                & + img,
                & + .avt {
                    margin-left: -10px;
                }
            }
            .avt {
                border-radius: 50%;
                border: 1px solid $white;
            }
        }
        .v2-dropdown__menu {
            width: 310px;
        }
        .avt,
        .avt::before {
            background-color: var(--branch-color);
        }
    }
    .nav-help {
        .v2-dropdown__menu {
            max-height: max-content;
        }
    }
    .add-menu {
        &:hover {
            z-index: 1005;
            .v2-dropdown__menu,
            &::before {
                display: block;
            }
        }
        &::before {
            content: '';
            display: none;
            position: absolute;
            width: 200px;
            left: 50%;
            transform: translate(-50%);
            background: transparent;
            height: 10px;
            top: 32px;
            z-index: 1;
        }
        .v2-dropdown__menu {
            top: calc(100% + 12px);
            .arrow {
                position: absolute;
                top: -7px;
                left: 50%;
                background: $transparent;
                z-index: -1;
                @include transform(translateX(-50%));
                .up {
                    border-width: 0 1px 1px 0;
                    display: inline-block;
                    padding: 5px;
                    transform: rotate(-135deg);
                    -webkit-transform: rotate(-135deg);
                    box-shadow: 2px 2px 5px -3px #0000004d;
                }
            }
        }
    }
    .boxs-notification {
        .notify-btn {
            margin-right: 14px;
            @include size(34px);
            &:not(.active) {
                background-color: $transparent;
            }
        }
    }
    .nav-sms {
        .notify-btn {
            &:not(.has-notify) svg path {
                fill: $grey-soft;
            }
        }
    }
    .search-form {
        margin-right: 16px;
        @media only screen and (max-width: 1331px) {
            flex: 1;
            .search-ip {
                width: 100%;
            }
            .search-dropdown {
                width: 250px;
                left: 0;
            }
        }
        @media only screen and (min-width: 1330px) {
            &.active .search-ip,
            .search-ip:focus {
                width: 280px;
                padding-right: 30px;
            }
            .search-ip {
                width: 250px;
                transition: width 0.1s ease-in-out;
            }
        }
        .search-ip {
            padding-right: 50px;
            min-width: 135px;
            &::placeholder {
                color: $grey-soft-txt;
            }
        }
        .key {
            background: $bg-body;
            border-radius: 4px;
            color: $black-darklight;
            font-size: 13px;
            @extend .fw-600;
            padding: 4px 6px;
            right: 4px;
            top: 50%;
            @include transform(translateY(-50%));
        }
    }
    .switch-action {
        margin-left: 4px;
    }
    .items-switch {
        padding: 8px !important;
        &__logo {
            margin-right: 8px;
            img {
                @include size(100%);
                object-fit: cover;
            }
            &,
            img {
                border-radius: 50%;
            }
            &,
            .avt,
            .avt-img {
                @include size(42px);
            }
            .avt {
                font-size: 12px;
            }
        }
        &.active {
            .status-btn {
                background: $white;
            }
        }
        .status-btn {
            background: $white-flash;
            height: auto;
            min-height: 20px;
            padding: 2px 8px;
            text-align: left;
            margin-bottom: 2px;
        }
        &__info {
            max-width: calc(100% - 46px);
        }
        .info-elm {
            line-height: 16px;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: pre;
        }
    }
    .horizontal-menu {
        min-width: 30%;
        width: 100%;
        transition: width 0.5s ease-in-out;
        @media (max-width: 1330px) {
            min-width: 34px;
        }
        &:empty {
            min-width: 0;
            margin-right: 0 !important;
        }
        .v2-btn-default {
            .default,
            .active {
                display: none;
            }
            .default svg,
            .active svg {
                @include size(26px);
            }
            &.active,
            &:hover {
                color: $white-default !important;
            }
            &.active .arrow svg path,
            &:hover .arrow svg path {
                fill: $white-default !important;
            }
        }
        .item-calendar {
            &.active,
            &:hover {
                background-color: $blue-default !important;
                border-color: $blue-default !important;
            }
        }
        .item-clock {
            &.active,
            &:hover {
                background-color: $pink-orchid !important;
                border-color: $pink-orchid !important;
            }
        }
        .item-customer {
            &.active,
            &:hover {
                background-color: $green-medium-dark !important;
                border-color: $green-medium-dark !important;
            }
        }
        .item-inbox {
            &.active,
            &:hover {
                background-color: $blue-navy !important;
                border-color: $blue-navy !important;
            }
        }
        .item-lead {
            &.active,
            &:hover {
                background-color: $orange-dark !important;
                border-color: $orange-dark !important;
            }
        }
        .item-report {
            &.active,
            &:hover {
                background-color: $green-pastel !important;
                border-color: $green-pastel !important;
            }
        }
        .item-review {
            &.active,
            &:hover {
                background-color: $yellow-dark !important;
                border-color: $yellow-dark !important;
            }
        }
        .item-commission {
            &.active,
            &:hover {
                background-color: $green-dark !important;
                border-color: $green-dark !important;
            }
        }
        .item-setting {
            &.active,
            &:hover {
                background-color: $purple-dark !important;
                border-color: $purple-dark !important;
            }
        }
        .item-addons {
            &.active,
            &:hover {
                background-color: $pink-dark !important;
                border-color: $pink-dark !important;
            }
        }
        .item-dashboard {
            &.active,
            &:hover {
                background-color: $violet-light !important;
                border-color: $violet-light !important;
            }
        }
        .item-onboarding {
            &.active,
            &:hover {
                background-color: $purple-lavender-indigo !important;
                border-color: $purple-lavender-indigo !important;
            }
        }
        .dropbtn {
            height: 32px;
            border-radius: 5px;
            padding: 4px 8px 4px 4px;
            @include flexcenter;
            .default,
            .active {
                display: none;
            }
        }
        .dropbtn.btn-ellipsis {
            padding: 4px;
            > svg:first-child {
                display: block;
            }
        }
        .wrap-nav {
            margin: 0;
            .v2-dropdown__menu {
                min-height: unset;
                min-width: 240px;
                .nav-boxs {
                    min-height: unset;
                }
            }
        }
        > .nav-menu {
            height: 32px;
        }
        .questlog-menu {
            border-radius: 12px;
            min-width: 32px;
            &.active {
                box-shadow: $boxshadow-btn-active !important;
            }
        }
    }
    .dropbtn:active {
        background-color: $white !important;
    }
    @media only screen and (max-width: 1440px) {
        .is-divider {
            display: none;
        }
        .search-form,
        .add-menu,
        .nav-call-module,
        .nav-inbox {
            margin-right: 16px;
        }
        .notifi {
            > .notify-btn {
                @include size(30px);
                border-radius: 12px;
                margin-right: 10px;
            }
            .budget {
                font-size: 10px;
                line-height: 13px;
                height: 13px;
                margin-left: -3px;
            }
        }
    }
    @media only screen and (max-width: 1370px) {
        font-size: 13px;
        .nav-menu {
            .v2-dropdown__menu {
                left: -60px;
                .nav-boxs {
                    &__title {
                        padding: 0 10px !important;
                    }
                    &.--list-menu {
                        width: 265px;
                    }
                    .nav-list {
                        padding: 10px !important;
                    }
                }
                .bg-feauture {
                    .relative {
                        .fs-26 {
                            font-size: 24px !important;
                        }
                    }
                }
            }
            .btn-ellipsis + .v2-dropdown__menu {
                left: 0;
            }
        }
        .nav-clock {
            min-width: 97px;
            max-width: 100px;
            & + .modal {
                position: fixed !important;
            }
        }
    }

    @media only screen and (max-width: 1180px) {
        .horizontal-menu {
            min-width: 0;
            &:has(.wrap-nav) {
                min-width: 60px;
            }
            .v2-btn-default {
                .active {
                    display: none;
                }
                .default {
                    display: block;
                }
                &:hover,
                &.active {
                    .active {
                        display: block;
                    }
                    .default {
                        display: none;
                    }
                }
            }
            .is-nav-items {
                display: none;
            }
        }
        .nav-clock,
        .search-form,
        .add-menu,
        .nav-call-module {
            margin-right: 10px;
        }
        .nav-inbox {
            margin-right: 4px;
        }
        .wrap-nav {
            margin: 0 10px;
            .dropbtn {
                .is-nav-items {
                    display: none;
                }
            }
        }
        .nav-user {
            .dropbtn {
                max-width: 140px;
            }
        }
        .container {
            gap: 30px;
        }
    }

    @media only screen and (max-height: 870px) {
        .nav-menu {
            &__item {
                &:nth-of-type(5) ~ .nav-menu__item:nth-last-child(-n + 3) {
                    .v2-dropdown__menu {
                        bottom: 0;
                        top: unset !important;
                    }
                }
            }
        }
    }
}
// Style filter header
.container-wrap {
    &.container-customer-list,
    &.container-report,
    .container-chatbot {
        .header-items {
            &.has-bg-blue:not(.list-menu) {
                background-color: $transparent !important;
                & > .dropbtn {
                    svg path {
                        stroke: $blue-default;
                    }
                    color: $blue-default !important;
                    border-color: rgba($color: $blue-default, $alpha: 0.5) !important;
                    background-color: rgba($color: $blue-default, $alpha: 0.06) !important;
                    .budget {
                        background-color: $blue-default;
                        color: $white-default;
                        svg path {
                            stroke: $white-default;
                        }
                    }
                    .txt-ellipsis {
                        font-weight: 500;
                    }
                    &:hover {
                        color: $blue-default !important;
                        border-color: rgba($color: $blue-default, $alpha: 0.5) !important;
                    }
                }
                &.field-date,
                .field-date {
                    border-color: rgba($color: $blue-default, $alpha: 0.5) !important;
                    background-color: rgba($color: $blue-default, $alpha: 0.06) !important;
                    font-weight: 500;
                    &__select,
                    &__label {
                        color: $blue-default;
                    }
                    svg path {
                        stroke: $blue-default;
                    }
                }
                &.field-date {
                    color: $blue-default;
                }
            }
        }
    }
}